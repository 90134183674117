import React from 'react';
import P from 'prop-types';
import cn from 'classnames';
import { getDataAttrs } from './utils';

export class DataField extends React.PureComponent {
  static propTypes = {
    label: P.oneOfType([P.string, P.number]).isRequired,
    className: P.string,
    value: P.oneOfType([P.string, P.number]),
    emptyCaption: P.node,
    dataAttrs: P.object,
  };

  static defaultProps = {
    className: '',
    value: '',
    emptyCaption: 'Значение не задано',
    dataAttrs: null,
  };

  render() {
    const className = cn('data-field', this.props.className, {
      'is-error': Boolean(this.props.error),
    });

    return (
      <div className={className} {...getDataAttrs(this.props.dataAttrs)}>
        <div className="data-field--label">{this.props.label}</div>
        {this.props.value ? (
          <div className="data-field--value">{this.props.value}</div>
        ) : (
          <div className="data-field--empty-value">
            {this.props.emptyCaption}
          </div>
        )}
        {typeof this.props.error === 'string' ? (
          <div className="text-field--error-message">{this.props.error}</div>
        ) : null}
      </div>
    );
  }
}

import * as R from 'ramda';
import P from 'prop-types';
import cn from 'classnames';
import React from 'react';

export function Container(props) {
  const className = cn(props.className, {
    'container-fluid': props.fluid,
    container: !props.fluid,
  });

  return <div className={className}>{props.children}</div>;
}

Container.propTypes = {
  className: P.string,
  children: P.node,
  fluid: P.bool,
};

Container.defaultProps = {
  className: '',
  children: null,
  fluid: false,
};

const gridUnit = P.oneOf(R.range(1, 13).concat(['auto']));
const order = P.oneOf(R.range(1, 13));
const offset = P.oneOf(R.range(1, 12));
const adaptiveOffset = P.oneOf(R.range(0, 12));
const sizeWithBool = P.oneOf(R.range(1, 13).concat(['auto', true, false]));

export function Col(props) {
  const className = cn(props.className, {
    'align-self-center': props.center,
    'align-self-end': props.end,
    'align-self-start': props.start,
    col: !props.size && !props.sm && !props.md && !props.lg,
    'col-lg': props.lg === true,
    'col-md': props.md === true,
    'col-sm': props.sm === true,
    'flex-first': props.first,
    'flex-last': props.last,
    'flex-unordered': props.unordered,
    [`col-${props.size}`]: !R.isNil(props.size),
    [`col-lg-${props.lg}`]: !R.isNil(props.lg),
    [`col-md-${props.md}`]: !R.isNil(props.md),
    [`col-sm-${props.sm}`]: !R.isNil(props.sm),
    [`offset-${props.offset}`]: !R.isNil(props.offset),
    [`offset-lg-${props.offsetLg}`]: !R.isNil(props.offsetLg),
    [`offset-md-${props.offsetMd}`]: !R.isNil(props.offsetMd),
    [`offset-sm-${props.offsetSm}`]: !R.isNil(props.offsetSm),
    [`order-${props.order}`]: !R.isNil(props.order),
    [`order-sm-${props.orderSm}`]: !R.isNil(props.orderSm),
    [`order-md-${props.orderMd}`]: !R.isNil(props.orderMd),
    [`order-lg-${props.orderLg}`]: !R.isNil(props.orderLg),
  });

  return <div className={className}>{props.children}</div>;
}

Col.propTypes = {
  className: P.string,
  children: P.node,
  size: gridUnit,
  sm: sizeWithBool,
  md: sizeWithBool,
  lg: sizeWithBool,
  offset,
  offsetSm: adaptiveOffset,
  offsetMd: adaptiveOffset,
  offsetLg: adaptiveOffset,
  order: order,
  orderSm: order,
  orderMd: order,
  orderLg: order,
  start: P.bool,
  center: P.bool,
  end: P.bool,
  unordered: P.bool,
  first: P.bool,
  last: P.bool,
};

Col.defaultProps = {
  className: '',
  children: null,
  size: null,
  sm: null,
  md: null,
  lg: null,
  offset: null,
  offsetSm: null,
  offsetMd: null,
  offsetLg: null,
  order: null,
  orderSm: null,
  orderMd: null,
  orderLg: null,
  start: false,
  center: false,
  end: false,
  unordered: false,
  first: false,
  last: false,
};

export function Row(props) {
  const className = cn('row', props.className, {
    'align-items-center': props.vCenter,
    'align-items-end': props.vEnd,
    'align-items-start': props.vStart,
    'justify-content-around': props.hAround,
    'justify-content-between': props.hBetween,
    'justify-content-center': props.hCenter,
    'justify-content-end': props.hEnd,
    'justify-content-start': props.hStart,
    'no-gutters': props.noGutters,
  });

  return <div className={className}>{props.children}</div>;
}

Row.propTypes = {
  className: P.string,
  children: P.node,
  noGutters: P.bool,
  vStart: P.bool,
  vCenter: P.bool,
  vEnd: P.bool,
  hStart: P.bool,
  hCenter: P.bool,
  hEnd: P.bool,
  hAround: P.bool,
  hBetween: P.bool,
};

Row.defaultProps = {
  className: '',
  children: null,
  noGutters: false,
  vStart: false,
  vCenter: false,
  vEnd: false,
  hStart: false,
  hCenter: false,
  hEnd: false,
  hAround: false,
  hBetween: false,
};

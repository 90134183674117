import * as R from 'ramda';
import React from 'react';
import P from 'prop-types';
import { Modal, Text } from '../../base';
import { getAgreement } from '../../agreements';

/* eslint-disable react/no-danger */
export function AgreementModal(props) {
  const data = props.data || {};
  let removeOptional;
  if (data.data) {
    removeOptional = data.data.removeOptional;
  }

  const agreement = R.isEmpty(data)
    ? null
    : getAgreement(data.vendor, data.agreement);
  let html =
    agreement && agreement.content(R.dissoc('removeOptional', data.data));

  function removeOptionalStr(str) {
    return str.replace(/ <!--OPTIONAL-->(.*)<!--\/OPTIONAL-->/gi, '');
  }

  if (removeOptional) {
    html = removeOptionalStr(html);
  }

  return (
    <Modal
      open={props.open}
      hasCloseButton
      onClose={props.onClose}
      position="top"
      title={agreement && agreement.title}
    >
      <Text className="u-px-6-md">
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Text>
    </Modal>
  );
}

AgreementModal.propTypes = {
  open: P.bool.isRequired,
  onClose: P.func.isRequired,
  data: P.shape({
    vendor: P.string.isRequired,
    agreement: P.string.isRequired,
    data: P.object,
  }),
};

AgreementModal.defaultProps = {
  data: null,
};

import React from 'react';
import { Modal, Row, Col, Button, Text } from '../../base';
import { Creditor } from '../../domain';

export function RedirectToCreditorModal(props) {
  const data = props.data || {};

  function onConfirm() {
    window.location = data.url;
  }

  function getContent() {
    switch (data.creditor) {
      case Creditor.SmsFinance:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для получения денег вы будете перенаправлены на сайт
                МКК&nbsp;“СМС&nbsp;Финанс”
              </Text>
              <Text center className="u-mb-2">
                После перехода на ваш телефон придет пароль от личного кабинета
                “СМС Финанс”. Укажите его в поле{' '}
                <Text inline bold>
                  “Пароль от личного кабинета”
                </Text>
                и нажмите “Войти”.
              </Text>
              <Text center>
                Затем выберите удобный способ получения денег и завершите
                оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.PayPS:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для получения денег вы будете перенаправлены на сайт
                МКК&nbsp;“Pay&nbsp;P.S.”.
              </Text>
              <Text center className="u-mb-2">
                После перехода на ваш телефон придет код для использования
                личного кабинета “Pay P.S”. Укажите его в поле{' '}
                <Text inline bold>
                  “Код из смс”
                </Text>{' '}
                и нажмите “Далее”.
              </Text>
              <Text center>
                Затем выберите удобный способ получения денег и завершите
                оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.DoZarplaty:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“ДоЗарплаты“.
              </Text>
              <Text center className="u-mb-2">
                После перехода на ваш телефон придет код от личного кабинета
                “ДоЗарплаты“. Укажите его в поле{' '}
                <Text inline bold>
                  “Код из смс”
                </Text>{' '}
                и нажмите “Далее”.
              </Text>
              <Text center>
                Затем выберите удобный способ получения денег и завершите
                оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.Kreditech:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на
                сайт&nbsp;МКК&nbsp;“Kredito24”.
              </Text>
              <Text center>
                После перехода проверьте, что все данные заполнены верно,
                придумайте и укажите пароль для использования личного кабинета
                Kredito24 и нажмите “Далее“.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.SlonFinance:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“SlonFinance”.
              </Text>
              <Text center className="u-mb-2">
                После перехода нажмите{' '}
                <Text inline bold>
                  “Вход по номеру телефона”
                </Text>
                , укажите там своей телефон и нажмите “Подтвердить”.
              </Text>
              <Text center>
                Затем выберите удобный способ получения денег и завершите
                оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.OneClickMoney:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“OneClickMoney”.
              </Text>
              <Text center className="u-mb-2">
                После перехода вы увидите окно восстановления пароля для доступа
                в личный кабинет и завершения оформления займа.
              </Text>
              <Text center className="u-mb-2">
                Нажмите{' '}
                <Text inline bold>
                  “Восстановить”
                </Text>{' '}
                и далее следуйте инструкциям на сайте.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.Kviku:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“Kviku”.
              </Text>
              <Text center className="u-mb-2">
                После перехода на ваш телефон придет специальный код
                подтверждения от Kviku.
              </Text>
              <Text center>
                Укажите его в поле для кода на сайте Kviku, нажмите
                “Подтвердить” и далее следуйте инструкциям на сайте.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.EcoFinance:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“EcoFinance”.
              </Text>
              <Text center className="u-mb-2">
                После перехода выберите удобный способ получения денег и
                завершите оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.WebBankir:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“WebBankir”.
              </Text>
              <Text center className="u-mb-2">
                После перехода на ваш телефон придет специальный код
                подтверждения от WebBankir.
              </Text>
              <Text center>
                Укажите его в поле для кода на сайте WebBankir, нажмите
                “Подтвердить” и далее следуйте инструкциям на сайте.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.MoneyMan:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“MoneyMan”.
              </Text>
              <Text center className="u-mb-2">
                После перехода выберите удобный способ получения денег и
                завершите оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.HoneyMoney:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МКК&nbsp;“HoneyMoney”.
              </Text>
              <Text center className="u-mb-2">
                После перехода нажмите{' '}
                <Text inline bold>
                  “Вход по номеру телефона”
                </Text>
                , укажите там своей телефон и нажмите “Подтвердить”.
              </Text>
              <Text center>
                Затем выберите удобный способ получения денег и завершите
                оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.MigCredit:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МФК&nbsp;“МигКредит“.
              </Text>
              <Text center className="u-mb-2">
                После перехода выберите подходящее предложение, способ получения
                и завершите оформление.
              </Text>
            </Col>
          </Row>
        );

      case Creditor.Zaymer:
        return (
          <Row hCenter>
            <Col size={10}>
              <Text center className="u-mb-2">
                Для завершения оформления вы будете перенаправлены на сайт
                МФК&nbsp;“Займер”.
              </Text>
              <Text center className="u-mb-2">
                После перехода выберите удобный способ получения денег и
                завершите оформление.
              </Text>
            </Col>
          </Row>
        );

      default:
        return <Row />;
    }
  }

  return (
    <Modal
      open={props.open}
      hasCloseButton
      title="Получение займа"
      onClose={props.onClose}
    >
      {getContent()}
      <Row hCenter className="u-mb-4 u-mt-4">
        <Col md={4}>
          <Button maxWidth color="primary" onClick={onConfirm}>
            Продолжить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

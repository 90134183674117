import P from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  ExpansionPanel,
  Icon,
  Row,
  Text,
  Title,
} from '../../base';
import { scrollUp } from '../../helpers/dom_helpers';
import { formatDayCount, formatMoney } from '../../helpers/format_helpers';
import { changePage } from '../../metrics';
import { ListLoader } from '../credit_request_list/list_loader';
import { Actions } from '../state';
import { LoanParam } from './shared';
// import { NewLoanRequestCard } from '../credit_request_list/new_loan_request_card';

function Offer({ info, className }) {
  let sumTitle;

  if (R.isNil(info.newSumFrom)) {
    sumTitle = formatMoney(info.newSumTo);
  } else if (R.isNil(info.newSumTo)) {
    sumTitle = formatMoney(info.newSumFrom);
  } else {
    sumTitle =
      `${formatMoney(info.newSumFrom, { withoutCurrency: true })} - ` +
      `${formatMoney(info.newSumTo)}`;
  }

  function goToMFI() {
    window.open(info.trackingLink, '_blank');
  }

  function isPreApproved(link) {
    const regex = /^(http|https):\/\//;
    return !regex.test(link);
  }

  return (
    <Card
      borderType={isPreApproved(info.logo) ? 'accentLeft' : 'none'}
      className={className}
      simple
    >
      <Row className="u-pl-2-md u-pr-4-md">
        <Col md={4}>
          <Row className="u-mt-2 u-mb-3">
            <Col>
              <Text inline="block" className="u-mb-1" color="secondary">
                Кредитор
              </Text>
              <div>
                {isPreApproved(info.logo) ? (
                  <Icon name={`${info.logo}-large-logo-icon pos-left`} />
                ) : (
                  <img
                    className="back-logo-icon"
                    src={info.logo}
                    alt="Логотип МФО"
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="u-hidden-lt-md">
            <Col md={11}>
              <Button
                color="primary"
                onClick={goToMFI}
                maxWidth
                className="u-mb-2"
                size="sm"
              >
                Получить решение
              </Button>
            </Col>
          </Row>
        </Col>

        <Col md={8}>
          <Row className="u-mb-0 u-mb-3-md">
            <Col>
              <Container className="u-mt-2-md">
                <Row>
                  <Col size="auto" className="u-mr-4 u-mb-2 u-p-0">
                    <LoanParam
                      caption="Сумма кредита"
                      value={<Title size={4}>{sumTitle}</Title>}
                    />
                  </Col>

                  <Col size="auto" className="u-mr-4 u-mb-2 u-p-0">
                    <LoanParam
                      caption="Срок кредита до"
                      value={
                        <Title size={4}>
                          {info.newTermTo} {formatDayCount(info.newTermTo)}
                        </Title>
                      }
                    />
                  </Col>

                  <Col size="auto" className="u-mb-2 u-p-0">
                    <LoanParam
                      caption="Ставка"
                      value={<Title size={4}>{info.newPercentFrom} %</Title>}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          {isPreApproved(info.logo) ? (
            <Row>
              <Col className="u-text-align-left u-mt-0 u-mt-2-md u-mb-3 u-mb-0-md">
                <div className="bullet-block">
                  <span className="bullet accent big u-mr-1" />
                  <Text className="u-pl-1 u-inline-block">
                    Вы прошли проверку на предварительное одобрение
                  </Text>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>

        <Col className="u-hidden-mt-md">
          <Button color="primary" onClick={goToMFI} maxWidth className="u-mb-2">
            Получить решение
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

Offer.propTypes = {
  info: P.shape({
    newTermTo: P.number.isRequired,
    newPercentFrom: P.number.isRequired,
    logo: P.string.isRequired,
    trackingLink: P.string.isRequired,
    newSumFrom: P.number,
    newSumTo: P.number,
  }).isRequired,
  className: P.string,
};

function onMoveToFinspin() {
  window.open('https://finspin.ru/loan-requests/');
}

export function AlternativeRejected({ id }) {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  useEffect(() => {
    scrollUp();
    if (!state.creditRequest.alternativeOffers) {
      dispatch(Actions.fetchAlternativeOffers(id));
    }
    changePage('rejected');
  }, []);

  function isVisibleDataLoading() {
    return !state.creditRequest || !state.creditRequest.alternativeOffers;
  }

  function renderAlternativeRejected() {
    if (isVisibleDataLoading()) {
      return null;
    }
    const offers = state.creditRequest.alternativeOffers;
    if (state.creditRequest.status === 'finished') {
      return (
        <>
          {R.keys(offers).map((title) => (
            <ExpansionPanel caption={title} key={title} className="u-mb-4">
              {offers[title].map((offer) => (
                <Offer
                  info={offer}
                  key={offer.trackingLink}
                  className="u-mb-2"
                />
              ))}
            </ExpansionPanel>
          ))}
        </>
      );
    }
  }

  return (
    <Container>
      <Row className="u-mt-3 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-warning-icon" />
        </Col>
        <Col size={2} start className="u-text-align-center" />
      </Row>

      <Row className="u-mb-4">
        <Col>
          <Title size={4} sm={3} center>
            Вам одобрены кредиты и карты без процентов
          </Title>
        </Col>
      </Row>

      {state.creditRequest.status !== 'expired' ? (
        <Row className="u-mb-4">
          <Col offset={2} size={8}>
            <Text center>
              Перейдите по ссылкам из предложений, заполните анкету до конца и получите решение
            </Text>
          </Col>
        </Row>
      ) : null}

      {renderAlternativeRejected()}
      {isVisibleDataLoading() ? <ListLoader /> : null}
      <Row className="u-mt-2 u-mt-4-sm u-mb-4">
        <Col center className="u-text-align-center">
          <Title size={3} center>
            Рекомендуем
          </Title>
        </Col>
      </Row>

      <Card simple className="u-mb-6">
        <Row>
          <Col size={2} md={1}>
            <Link onClick={onMoveToFinspin} className="account-navbar--logo">
              <div className="finspin-logo" />
            </Link>
          </Col>
          <Col size={10} md={8}>
            <Text type="subtitle">
              Если вам срочно необходима небольшая сумма на короткий срок,
              воспользуйтесь нашим сервисом&nbsp;—{' '}
              <Text bold inline type="subtitle">
                подбором моментальных займов
              </Text>
            </Text>
          </Col>
          <Col sm={12} md={3} className="u-mt-2 u-mt-0-md">
            <Link
              onClick={() =>
                window.open('https://finspin.ru/loan-requests/', '_blank')
              }
              className="u-border-bottom-none"
            >
              <Button maxWidth size="sm">
                Подобрать
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

AlternativeRejected.defaultProps = {
  offers: null,
};

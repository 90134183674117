import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

function simpleComponent(baseClassName) {
  function Component(props) {
    const className = cn(baseClassName, props.className);

    return <div className={className}>{props.children}</div>;
  }

  Component.propTypes = {
    children: P.node,
    className: P.string,
  };

  Component.defaultProps = {
    children: null,
    className: '',
  };

  return Component;
}

export const ContentWithFooter = simpleComponent('footer-container');
export const ContentBeforeFooter = simpleComponent('footer-container--content');
export const Footer = simpleComponent('footer-container--footer');

import React, { useEffect } from 'react';
import * as R from 'ramda';

import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../state';
import { clearCookies } from '../../helpers/storage_helpers';

export function parseParams(query, getCookies) {
  const queryParams = R.pick(['clickid'], query);
  const cookieParams = getCookies(['clickid']);

  return R.merge(cookieParams, queryParams);
}

export function persistParams(params, dispatch) {
  dispatch(
    Actions.addLeadProviderParams({
      filkos: params,
    }),
  );
}

export function persistCookie(params, setCookies) {
  // This cookie is persisted just to recover the value after refresh. The backend ignores it.
  // The cookie is cleared on prescoring or on logout.
  // See http://ebc.teamlead.ru/browse/PRUN-922
  setCookies(R.pick(['clickid'], params));
}

export function clearParams(dispatch) {
  dispatch(Actions.removeLeadProviderParams('filkos'));
}

export function clearCookie() {
  clearCookies(['clickid']);
}

export function clearABRejectedCookie() {
  clearCookies(['ab_rejected']);
}

export function FilkosPixel({ id }) {
  const dispatch = useDispatch();
  const leadProvider = useSelector((s) => s.leadProvider);

  const clickId = R.path(
    ['leadProviderParams', 'filkos', 'clickid'],
    leadProvider,
  );

  useEffect(() => {
    clearCookie();
    return () => clearParams(dispatch);
  }, []);

  if (R.isNil(clickId)) {
    return null;
  }

  return (
    <img
      src={`https://filkos.com/pixel/pixel.php?landing_id=zfghHh45dj&clickid=${clickId}&conversion_id=${id}`}
      alt="Filkos pixel"
      width="1"
      height="1"
    />
  );
}

import React from 'react';
import { Card, Col, Icon, Row, Text } from '../../base';

export function ListLoader() {
  return (
    <Card backgroundType="neutral">
      <Row className="u-mt-6 u-mb-2">
        <Col>
          <Text center>
            <Icon name="spinner-primary-bg-sm" className="btn--pending-icon" />
          </Text>
        </Col>
      </Row>
      <Row className="u-mb-6">
        <Col>
          <Text type="subtitle" center bold>
            Загружаю...
          </Text>
        </Col>
      </Row>
    </Card>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Container,
  ContentBeforeFooter,
  ContentWithFooter,
  Footer,
  Icon,
  Link,
  Row,
  Text,
  Title,
} from '../../base';
import { scrollUp } from '../../helpers/dom_helpers';
import { formatPhone } from '../../helpers/format_helpers';
import { changePage } from '../../metrics';
import { AccountNavbar } from '../shared/account_navbar';
import { FirstStepForm } from '../shared/first_step_form';
import { Actions } from '../state';
import { ListLoader } from './list_loader';

export function CreditRequestList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((s) => s);

  useEffect(() => {
    scrollUp();
    changePage('creditRequests');
    if (!state.newLoanOptions) {
      dispatch(Actions.fetcCreditConditions());
    }
  }, [dispatch, state.newLoanOptions]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (state.creditRequest.id) {
      dispatch(Actions.fetchProfile(state.creditRequest.id));
      dispatch(Actions.fetchCreditRequest(state.creditRequest.id)).then(
        ({ value }) => {
          if (
            (value.step && value.step !== 1) ||
            (value.status && value.status !== 'needInfo')
          ) {
            history.push(`/credit-requests/${state.creditRequest.id}`);
          } else {
            setLoading(false);
          }
        },
      );
    } else {
      dispatch(Actions.checkCreditRequestStatus()).then(() =>
        setLoading(false),
      );
    }
  }, [
    dispatch,
    history,
    state.creditRequest.id,
    state.creditRequest.step,
    state.creditRequest.status,
  ]);

  const isVisibleDataLoading = useMemo(() => {
    return (
      !state.newLoanOptions ||
      state.pendings.checkCreditRequestStatus ||
      (state.pendings.fetchCreditRequest && !state.creditRequest.id) ||
      loading
    );
  }, [state.newLoanOptions, state.pendings, state.creditRequest.id, loading]);

  function onCreateLoanRequest(values) {
    if (state.creditRequest.id) {
      return dispatch(Actions.sendApplication(values));
    }
    dispatch(Actions.setWantedCreditConditions(values.amount));
    return dispatch(
      Actions.createCreditRequest(state.leadProviderParams.common),
    ).then(() => {
      return dispatch(Actions.sendApplication(values));
    });
  }

  function renderNewCreditRequest() {
    if (isVisibleDataLoading) {
      return null;
    }

    return (
      <div className="u-px-2 u-mt-2">
        <h1 className="cabiner-form__title">
          <Title size={3}>
            Подберем кредитную карту или кредит{' '}
            <span className="no-wrap">на лучших</span> условиях
          </Title>
        </h1>
        <FirstStepForm
          onSubmit={onCreateLoanRequest}
          calcProps={state.newLoanOptions}
          calcLocation="cabinet"
          isSubmitPending={
            state.pendings.createCreditRequest ||
            state.pendings.sendApplication ||
            state.pendings.fetchCreditRequest
          }
        />
      </div>
    );
  }

  function onGetAgreements() {
    return history.push(`/agreements`);
  }

  function renderFooterContent() {
    return (
      <Container className="u-pt-2 u-pt-4-md u-pb-4">
        <Row>
          <Col>
            <Icon name="finspin-logo-icon" />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="u-pt-2">
            <Text color="secondary" type="caption">
              Подбираем микрозаймы на любые цели. До 30 000 рублей за 10 минут.
              Заявка онлайн. Выдача 24 часа в сутки.
            </Text>
          </Col>
          <Col md={8} className="u-pt-2">
            <Text type="body-1" bold>
              <Link href={`tel:8${process.env.SUPPORT_PHONE}`}>
                {formatPhone(process.env.SUPPORT_PHONE, { firstDigit: '8' })}
              </Link>
            </Text>
            <Text color="secondary" type="caption">
              С 9:00 до 18:00 по московскому времени звонок по России бесплатный
            </Text>
            <br />
            <Text color="secondary" type="caption">
              Email:{' '}
              <Link href="mailto:support@finspin.ru">support@finspin.ru</Link>
            </Text>
          </Col>
        </Row>
        <Row className="u-pt-2">
          <Col>
            <Text color="secondary" type="caption">
              Если просрочить платеж по кредиту от одного до трех дней, многие
              банки закроют на это глаза. Такие просрочки часто случаются из-за
              технических сбоев, например, задержка банковского перевода. А вот
              более долгие просрочки караются санкциями: штрафами, занесением в
              реестр должников, испорченной кредитной историей. За просрочку
              платежа по потребительскому кредиту или кредитной карте средний
              размер штрафа составляет ~0,03% в день от суммы долга. При
              длительном нарушении графика платежей по кредитам, данные заемщика
              могут попасть в реестр должников, а долг — коллекторам.
              <br />
              Рекомендуем платить за кредиты вовремя — по графику,
              установленному кредитным договором. Исправно выплачиваемый кредит
              формирует хорошую кредитную историю и делает вас образцовым
              заемщиком для кредитных организаций. Это откроет вам доступ к
              лучшим условиям кредитования — крупным суммам по минимальным
              ставкам.
            </Text>
          </Col>
        </Row>
        <Row className="u-pt-2">
          <Col>
            <Text color="secondary" type="caption">
              <strong>Пример расчета кредита:</strong> <br />
              Если вы хотите взять 400 000 ₽ и на один год под 11,9% годовых,
              итоговый платеж составит 447 600 ₽, из которых 47 600 ₽ —
              проценты. На текущий момент минимальная ставка по потребительскому
              кредиту и кредитной карте составляет 7%, максимальная — 18,9%.
              Доступный срок кредитования от 90 дней до 7 лет. При нарушении
              сроков платежей неустойка составит 0,10% от суммы просрочки в
              день, но не более 10% от суммы кредита. Длительная просрочка
              влечет за собой передачу данных в бюро кредитных историй, что
              приведет к ухудшению кредитной истории заемщика <br />
              Мы не являемся кредитной организацией и не занимаемся
              кредитованием. Мы только предоставляем информацию о рынке
              кредитования.
            </Text>
          </Col>
        </Row>
        <Row className="u-pt-2">
          <Col>
            <Text color="secondary" type="caption">
              Общество с ограниченной ответственностью «Финспин». Юридический
              адрес: 620075, г. Екатеринбург, ул. Мамина-Сибиряка, д. 101, офис
              8.10 ОГРН 1146670012444, ИНН 6670424655. ООО «Финспин»
              сотрудничает только с надлежащим образом зарегистрированными
              финансовыми организациями и банками, состоящими в государственном
              реестре ЦБ РФ.
            </Text>
            <Text color="secondary" type="caption" className="u-mt-1">
              <Link onClick={() => onGetAgreements()}>
                Соглашения и документы
              </Link>
            </Text>
            <Text color="secondary" type="caption" className="u-pt-2">
              © 2016-{new Date().getFullYear()} ООО «Финспин»
            </Text>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <ContentWithFooter className="g-workspace-bg">
        <ContentBeforeFooter>
          <AccountNavbar />
          <Container className="u-pt-4 u-pb-2 u-px-0">
            {renderNewCreditRequest()}
            {isVisibleDataLoading ? <ListLoader /> : null}
          </Container>
        </ContentBeforeFooter>
        <Footer className="u-border-top">{renderFooterContent()}</Footer>
      </ContentWithFooter>
    </div>
  );
}

import * as R from 'ramda';
import cn from 'classnames';
import P from 'prop-types';
import noUiSlider from 'nouislider';
import React from 'react';
import { FormInput } from './form';
import { RawTextField } from './text_field';
import { DataField } from './data_field';

const wNumb = require('wnumb');

function getValueOrDefault(value, min) {
  return R.isNil(value) ? min : value;
}

function removeSpaces(val) {
  return val.split(' ').join('');
}

const defaultFormat = wNumb({
  decimals: 0,
});

export class NewRawSlider extends React.PureComponent {
  state = { value: String(this.props.value) };

  componentDidMount() {
    if (!this.props.fixed) {
      noUiSlider.create(this.element, {
        connect: [true, false],
        format: this.props.format ? this.props.format : defaultFormat,
        range: {
          max: this.props.max,
          min: this.props.min,
        },
        start: [getValueOrDefault(this.props.value, this.props.min)],
        step: this.props.step,
      });

      const initialValue = this.element.noUiSlider.get();
      this.setState({ value: initialValue });

      this.element.noUiSlider.on('slide', () => {
        const value = this.element.noUiSlider.get();

        this.setState({ value });
        const trimmedNumbValue = Number(removeSpaces(value));

        if (this.props.onChange) {
          this.props.onChange(trimmedNumbValue);
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.fixed) {
      this.element.noUiSlider.updateOptions({
        range: {
          min: nextProps.min,
          max: nextProps.max,
        },
        step: nextProps.step,
        format: nextProps.format ? nextProps.format : defaultFormat,
      });
      const value = this.element.noUiSlider.get();
      this.setState({ value });
    }
  }

  componentWillUnmount() {
    if (this.element) {
      this.element.noUiSlider.destroy();
    }
  }

  onTextFieldChange = (value) => {
    const maxLength = String(this.props.max).length;
    const trimmedValue = removeSpaces(value);

    if (trimmedValue.length > maxLength) {
      return;
    }

    const regex = new RegExp('^([0-9]|$)+$');

    if (regex.test(trimmedValue)) {
      const convertedValue = trimmedValue;
      this.setState({ value: convertedValue });
    }
  };

  onTextFieldBlur = () => {
    const { value } = this.state;
    const { step, max, min } = this.props;
    const trimmedNumbValue = Number(removeSpaces(value));
    let roundedValue;
    roundedValue = Math.round(trimmedNumbValue / step) * step;
    if (trimmedNumbValue > max) {
      roundedValue = max;
    }
    if (trimmedNumbValue < min) {
      roundedValue = min;
    }

    this.props.onChange(roundedValue);
    this.element.noUiSlider.set(roundedValue);
  };

  render() {
    const className = cn('slider', this.props.className, {
      'is-disabled': this.props.disabled,
      'is-large': this.props.size === 'large',
      'is-column': this.props.titleDirection === 'column',
      'is-landing': this.props.calcLocation === 'landing',
    });
    const {
      // title,
      getValueCaption,
      minValueCaption,
      maxValueCaption,
      value,
      min,
      captionStart,
      captionEnd,
    } = this.props;

    if (this.props.fixed) {
      return (
        <DataField
          label={this.props.label}
          value={
            R.isNil(getValueCaption)
              ? ''
              : getValueCaption(getValueOrDefault(value, min))
          }
        />
      );
    }

    return (
      <div className={className}>
        <div className="slider--info-top-line">
          {/* <div className="slider--title">{title}</div> */}
          <div className="slider--captions">
            <div className="slider--caption">{captionStart}</div>
            <RawTextField
              type="tel"
              className="slider--text-field"
              onChange={this.onTextFieldChange}
              label=""
              value={this.state.value}
              onBlur={this.onTextFieldBlur}
              noBrowserAutocomplete
            />
            <div className="slider--caption">{captionEnd}</div>
          </div>
        </div>

        <div
          ref={(ref) => {
            this.element = ref;
          }}
        />
        {R.isNil(minValueCaption) && R.isNil(maxValueCaption) ? null : (
          <div className="slider--info-bottom-line">
            {R.isNil(minValueCaption) ? null : R.isNil(getValueCaption) ? (
              <div className="slider--min-value-caption">{minValueCaption}</div>
            ) : (
              <div className="slider--min-value-caption">
                {getValueCaption(minValueCaption)}
              </div>
            )}
            {R.isNil(minValueCaption) ? null : R.isNil(getValueCaption) ? (
              <div className="slider--max-value-caption">{maxValueCaption}</div>
            ) : (
              <div className="slider--max-value-caption">
                {getValueCaption(maxValueCaption)}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export function NewSlider(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue }) => (
        <NewRawSlider
          {...rest}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

NewSlider.propTypes = R.merge(NewSlider.propTypes, {
  field: P.string.isRequired,
});

NewRawSlider.propTypes = {
  max: P.number,
  min: P.number,
  onChange: P.func,
  value: P.number,
  step: P.number,
  // title: P.node,
  getValueCaption: P.func,
  minValueCaption: P.node,
  maxValueCaption: P.node,
  disabled: P.bool,
  format: P.shape({
    to: P.func.isRequired,
    from: P.func.isRequired,
  }),
  className: P.string,
  label: P.string, // Show only in fixed mode
  fixed: P.bool,
  size: P.oneOf(['default', 'large']),
  titleDirection: P.oneOf(['inline', 'column']),
  calcLocation: P.oneOf(['landing', 'cabinet']),
  captionStart: P.string,
  captionEnd: P.string,
  formatDayCount: P.func,
};

NewRawSlider.defaultProps = {
  min: 0,
  max: 100,
  onChange: null,
  value: null,
  step: 1,
  // title: null,
  getValueCaption: null,
  minValueCaption: null,
  maxValueCaption: null,
  disabled: false,
  format: null,
  className: '',
  label: null,
  fixed: false,
  size: 'default',
  titleDirection: 'inline',
  calcLocation: 'cabinet',
  short: false,
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Container,
  ContentBeforeFooter,
  ContentWithFooter,
  Footer,
  Icon,
  Row, Text,
  Title,
} from '../../base';
import { scrollUp } from '../../helpers/dom_helpers';
import { changePage } from '../../metrics';
import { ListLoader } from '../credit_request_list/list_loader';
import { AccountNavbar } from '../shared/account_navbar';
import { Support } from '../shared/support';
import { Actions } from '../state';
import { AlternativeRejected } from './alternative_rejected';
import { Application } from './application/application';
import { ApprovedBigCard } from './approved_big_card';
import {LoadingDots} from "../credit_request_list/loadingDots";

export function FinishedList({ id: creditRequestId, offers, onGetDetails }) {
  useEffect(() => {
    changePage('creditRequestActive');
    scrollUp();
  }, []);

  function renderOffers() {
    return offers.map((offer) => (
      <ApprovedBigCard
        key={offer.id}
        offer={offer}
        onGetDetails={onGetDetails}
        creditRequestId={creditRequestId}
        offerId={offer.id}
      />
    ));
  }

  return (
    <Container className="u-pt-4 u-pb-2">
      <Row className="u-mt-3 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-glad-icon" />
        </Col>
        <Col size={2} start className="u-text-align-center" />
      </Row>

      <Row className="u-mb-2">
        <Col>
          <Title size={3} sm={2} center>
            Вам одобрено несколько предложений!
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-4 u-mb-6-sm">
        <Col center>
          <Title size={4} regular center>
            Для выбора предложения необходимо перейти к нашему партнеру
          </Title>
        </Col>
      </Row>

      {renderOffers()}
    </Container>
  );
}

export function WaitingList({
  offers,
  onGetDetails,
  id: creditRequestId,
  onCheckCreditRequestStatus,
}) {
  useEffect(() => {
    scrollUp();
    changePage('loanLoading');
    const intervalId = window.setInterval(onCheckCreditRequestStatus, 1000);
    return () => window.clearInterval(intervalId);
  }, [onCheckCreditRequestStatus]);

  return (
    <Container className="u-pt-6 u-pb-2">
      <Row className="u-mb-5">
        <Col>
          <Container>
            <Row className="u-mb-2 u-mb-3-md">
              <Col md={8} offsetMd={2}>
                <div className="u-my-2">
                  <LoadingDots />
                  <Text center>Пожалуйста, подождите</Text>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {offers.map((offer) => (
        <ApprovedBigCard
          key={offer.id}
          offer={offer}
          onGetDetails={onGetDetails}
          creditRequestId={creditRequestId}
          offerId={offer.id}
        />
      ))}
    </Container>
  );
}

export function ProcessingList({
  offers,
  isLongProcess,
  onGetDetails,
  id: creditRequestId,
  onCheckCreditRequestStatus,
}) {
  useEffect(() => {
    scrollUp();
    changePage('loanLoading');
    const intervalId = window.setInterval(onCheckCreditRequestStatus, 5000);
    return () => window.clearInterval(intervalId);
  }, [onCheckCreditRequestStatus]);

  function renderNotification() {
    if (isLongProcess && offers.length === 0) {
      return (
        <>
          <Title size={4} regular center className="u-mb-2">
            Мы продолжаем подбирать подходящий кредит. Это может занять еще
            20-30 минут. Найденные кредиты будут появляться прямо на этой
            странице.
          </Title>
          <Title size={4} regular center>
            Не волнуйтесь, как только все будет готово, мы отправим вам
            уведомление по SMS.
          </Title>
        </>
      );
    }
    if (offers.length === 0) {
      return (
        <Title size={4} regular center>
          Пожалуйста, подождите. Подбираем для вас подходящий кредит. Найденные
          кредиты будут появляться прямо на этой странице
        </Title>
      );
    }

    return (
      <Title size={4} regular center>
        Мы продолжаем подбирать подходящий кредит. Это может занять еще 20-30
        минут.
      </Title>
    );
  }

  return (
    <Container className="u-pt-6 u-pb-2">
      <Row className="u-mb-5">
        <Col>
          <Container>
            <Row className="u-mb-2 u-mb-3-md">
              <Col md={8} offsetMd={2}>
                <Title
                  center
                  size={3}
                  md={2}
                  className="u-text-align-center u-text-align-left-md"
                >
                  Подбор кредитных карт
                </Title>
              </Col>
            </Row>
            <Row className="u-mb-6">
              <Col md={6} offsetMd={3}>
                {renderNotification()}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {offers.map((offer) => (
        <ApprovedBigCard
          key={offer.id}
          offer={offer}
          onGetDetails={onGetDetails}
          creditRequestId={creditRequestId}
          offerId={offer.id}
        />
      ))}
    </Container>
  );
}

function ExpiredCase({ onRedirectToFirstStep }) {
  useEffect(() => {
    onRedirectToFirstStep();
  }, [onRedirectToFirstStep]);
  return null;
}

export function CreditRequest({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((s) => s);
  const {
    id,
    isLongProcess,
    status,
    onApplication,
    step,
    offers,
  } = state.creditRequest;

  useEffect(() => {
    scrollUp();
    dispatch(Actions.fetchCreditRequest(match.id));
    return () => dispatch(Actions.creditRequestUnmount());
  }, [dispatch, history, match.id]);

  function isVisibleDataLoading() {
    return !state.creditRequest.id;
  }

  function getRequestStatus() {
    if (onApplication && (status === 'needInfo' || status === 'finished')) {
      return 'needInfo';
    }
    if (
      (status === 'finished' || status === 'expired') &&
      offers.length === 0
    ) {
      return 'alternateOffers';
    }

    return status;
  }

  function onCheckCreditRequestStatus() {
    return dispatch(Actions.fetchCreditRequest(match.id));
  }

  function onRedirectToFirstStep() {
    return history.push('/credit-request');
  }

  function onGetDetails(creditorUrl) {
    window.location = creditorUrl;
    // dispatch(
    //   Actions.openModal(ModalType.RedirectToCreditor, {
    //     creditor,
    //     url: creditorUrl,
    //   }),
    // );
  }

  function renderLoanRequest() {
    if (isVisibleDataLoading()) {
      return null;
    }
    switch (getRequestStatus()) {
      case 'waiting':
        return (
          <>
            <WaitingList
              {...state.creditRequest}
              onGetDetails={onGetDetails}
              onCheckCreditRequestStatus={onCheckCreditRequestStatus}
            />
          </>
        );
      case 'needInfo':
        return (
          <Application
            onCheckCreditRequestStatus={onCheckCreditRequestStatus}
          />
        );

      case 'inProgress':
        return (
          <>
            <ProcessingList
              isLongProcess={isLongProcess}
              {...state.creditRequest}
              onGetDetails={onGetDetails}
              onCheckCreditRequestStatus={onCheckCreditRequestStatus}
            />
          </>
        );
      case 'finished':
        return (
          <FinishedList {...state.creditRequest} onGetDetails={onGetDetails} />
        );
      case 'alternateOffers':
        return <AlternativeRejected {...state.creditRequest} />;
      case 'expired':
        return <ExpiredCase onRedirectToFirstStep={onRedirectToFirstStep} />;
      default:
        return null;
    }
  }

  return (
    <ContentWithFooter>
      <ContentBeforeFooter>
        <AccountNavbar />
        {renderLoanRequest()}
        {isVisibleDataLoading() ? <ListLoader /> : null}
      </ContentBeforeFooter>
      <Footer>
        <Container>
          <Row className="u-mb-4 u-pt-4">
            <Col offsetMd={3} md={6}>
              <Support phone={process.env.SUPPORT_PHONE} />
            </Col>
          </Row>
        </Container>
      </Footer>
    </ContentWithFooter>
  );
}

import React from 'react';
import P from 'prop-types';
import VendorFormInput from './react_form/src/formInput';

export function FormInput({ field, children }) {
  return (
    <VendorFormInput field={field} showErrors={false}>
      {children}
    </VendorFormInput>
  );
}

FormInput.propTypes = {
  field: P.string.isRequired,
  children: P.func.isRequired,
};

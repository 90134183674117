import React, { useMemo, useRef } from 'react';
import * as R from 'ramda';
import P from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row, NewSlider, Icon } from '../../base';
import { formatBigMoney } from '../../helpers/format_helpers';
import { WidgetTypes } from '../credit_request/application/application_form';
import {
  AgreementName,
  AgreementVendor,
  getLoanAmountOrDefault,
} from '../../domain';
import { publickStep } from '../credit_request/application/steps';
import { noError, isEmpty } from '../../validation';
import { Actions } from '../state';
import { ModalType } from '../modals/modal_type';

const initialValue = {
  lastName: undefined,
  firstName: undefined,
  middleName: undefined,
  birthDate: undefined,
  email: undefined,
  gender: 'male',
  creditAddress: {
    street: null,
    unrestrictedValue: null,
    value: null,
    withoutFlat: null,
  },
};
export function FirstStepForm({
  isSubmitPending,
  calcLocation,
  onFieldChange,
  // onSubmit,
  calcProps,
  isPublick,
}) {
  const dispatch = useDispatch();
  const { minAmount, maxAmount, amountStep, amount } = calcProps;
  const state = useSelector((s) => s);
  const handleSubmit = (values) => {
    // Заморозка финспина
    // return onSubmit(values);
    return dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor: AgreementVendor.Finspin,
        agreement: AgreementName.FinspinFreeze,
      }),
    );
  };

  const handlePreSubmit = (values) => {
    const preSubmitValues = R.reduce(
      (acc, field) =>
        R.assoc(field.name, field.parseValue(values[field.name]), acc),
      {},
      publickStep[1](isPublick).fields(values),
    );
    return {
      ...preSubmitValues,
      amount: values.amount,
    };
  };
  const formRef = useRef(null);
  const setValue = (name, val) => {
    formRef.current.form.setValue(name, val);
    if (onFieldChange) {
      onFieldChange(val, name);
    }
  };

  const getValue = (name, fallback) =>
    formRef.current.form.getValue(name, fallback);

  const handleFieldChange = (field) => (rawValue, name) => {
    const parsed = field.parseValue(rawValue);

    if (field.onChange) {
      field.onChange(parsed, name, rawValue, getValue, setValue);
    }

    if (onFieldChange) {
      onFieldChange(parsed, field);
    }
  };

  const getFieldValidator = (field) => {
    return field.validate || (field.optional ? () => noError : isEmpty);
  };

  const handleValidate = (values) => {
    const validate = R.reduce(
      (errors, field) =>
        R.assoc(
          field.name,
          getFieldValidator(field)(values[field.name], {
            required: !field.optional,
          }),
          errors,
        ),
      {},
      publickStep[1](isPublick).fields(values),
    );
    return validate;
  };
  const renderField = (field, i) => {
    const isHidden = field.widgetOptions.hidden;
    const widgetComponent = WidgetTypes[field.type];

    const widget = React.createElement(
      widgetComponent,
      R.mergeDeepRight(field.widgetOptions, {
        field: field.name,
        optional: field.optional,
        fixed: field.fixed,
        onChange: handleFieldChange(field),
        dataAttrs: { id: field.name, stat: '1', scroll: `${field.name}-${i}` },
      }),
    );
    if (isHidden) {
      return null;
    }

    return (
      <Row className="u-mb-2">
        <Col>
          {field.beforeDescription}
          {widget}
        </Col>
      </Row>
    );
  };

  const isCabinet = useMemo(() => calcLocation === 'cabinet', [calcLocation]);

  const formClassName = cn('public-form', 'u-mt-3', 'u-mt-4-lg', {
    'is-cabinet': isCabinet,
  });

  const getDefaultValues = () => {
    if (isPublick) {
      return { ...initialValue, processDataCheckboxAgreement: true };
    }
    return initialValue;
  };

  return (
    <Form
      preSubmit={handlePreSubmit}
      onSubmit={handleSubmit}
      validate={handleValidate}
      defaultValues={{
        amount:
          state.creditRequest && state.creditRequest.amount
            ? state.creditRequest.amount
            : getLoanAmountOrDefault(minAmount, maxAmount, amount),
        ...getDefaultValues(state.profile),
      }}
      ref={formRef}
    >
      {() => {
        return (
          <div className={formClassName}>
            <div className="public-form__slider">
              <Row className="u-mb-2-md">
                <Col>
                  <NewSlider
                    size="large"
                    titleDirection="column"
                    calcLocation={calcLocation}
                    captionStart="₽"
                    field="amount"
                    step={amountStep}
                    min={minAmount}
                    max={maxAmount}
                    getValueCaption={(val) =>
                      formatBigMoney(val, { withoutCurrency: false })
                    }
                    minValueCaption={minAmount}
                    maxValueCaption={maxAmount}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Row>

              <Row className="u-mb-2-md landing-form__info landing-form--mobile-hide">
                <Col>
                  <div className="landing-form--description">
                    <div className="landing-form--points">
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Заполните заявку онлайн, это займет не более 5 минут
                        </div>
                      </div>
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Finspin сравнит предложения банков и подберет для вас
                          лучшее предложение
                        </div>
                      </div>
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Никаких дополнительных сборов и комиссий
                        </div>
                      </div>
                    </div>

                    <div className="landing-form--safety">
                      <div className="landing-form--safety-icon">
                        <Icon name="lock-icon" />
                      </div>
                      <div className="landing-form--safety-text">
                        Мы работаем в строгом соответствии с законодательством
                        РФ. Здесь и далее ваши данные надежно защищены.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="public-form__form">
              {React.createElement(
                'div',
                { className: 'calc__form' },
                ...publickStep[1](isPublick).fields().map(renderField),
              )}
              <Row hStart vCenter className="u-mb-2 u-mt-3 u-mt-4-lg">
                <Col className="u-mb-0-md u-mb-2">
                  <Button
                    type="submit"
                    maxWidth
                    size={isCabinet ? 'md' : 'lg'}
                    isPending={isSubmitPending}
                  >
                    Подобрать кредит
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="u-mb-2-md landing-form__info landing-form--desktop-hide">
              <Col>
                <div className="landing-form--description">
                  <div className="landing-form--points">
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Заполните заявку онлайн, это займет не более 5 минут
                      </div>
                    </div>
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Finspin сравнит предложения банков и подберет для вас
                        лучшее предложение
                      </div>
                    </div>
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Никаких дополнительных сборов и комиссий
                      </div>
                    </div>
                  </div>

                  <div className="landing-form--safety">
                    <div className="landing-form--safety-icon">
                      <Icon name="lock-icon" />
                    </div>
                    <div className="landing-form--safety-text">
                      Мы работаем в строгом соответствии с законодательством РФ.
                      Здесь и далее ваши данные надежно защищены.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      }}
    </Form>
  );
}

FirstStepForm.propTypes = {
  calcLocation: P.oneOf(['cabinet', 'landing']),
  // onSubmit: P.func.isRequired,
  isSubmitPending: P.bool,
  onFieldChange: P.func,
};

FirstStepForm.defaultProps = {
  isSubmitPending: false,
  onFieldChange: null,
};

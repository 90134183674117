import * as R from 'ramda';
import cookie from 'cookie-parse';

export function getCookies(keys) {
  if (keys) {
    return R.pick(keys, cookie.parse(document.cookie));
  }
  return cookie.parse(document.cookie);
}

export function setCookies(cookies) {
  R.forEachObjIndexed((value, key) => {
    document.cookie = `${key}=${encodeURIComponent(value)};path=/`;
  }, cookies);
}

export function clearCookies(keys) {
  keys.forEach((key) => {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  });
}

import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Actions } from '../state';
import { ModalType } from './modal_type';
import { AgreementModal } from './agreement_modal';
import { AutoLogoutModal } from './auto_logout_modal';
import { CloseApplicationModal } from './close_application_modal';
import { LoanCalculatorModal } from './loan_calculator_modal';
import { RedirectToCreditorModal } from './redirect_to_creditor_modal';
import { ServerErrorModal } from './server_error_modal';
import { PassportChangeHelpModal } from './passport_change_help_modal';

function renderModal(
  modal,
  type,
  { opened, data, onClose, onLogout, history },
) {
  return React.createElement(modal, {
    history,
    onLogout,
    onClose: () => onClose(type),
    data: R.prop(type, data),
    open: R.contains(type, opened),
  });
}

export function Modals() {
  const dispatch = useDispatch();
  const history = useHistory();
  const modals = useSelector((s) => s.modals);

  function onClose(modalId) {
    dispatch(Actions.closeModal(modalId));
  }

  const props = {
    opened: R.keys(modals).filter((x) => modals[x].open),
    data: R.keys(modals).reduce(
      (acc, x) => R.assoc(x, modals[x].data, acc),
      {},
    ),
    onClose,
    history,
  };

  return (
    <div>
      {renderModal(AgreementModal, ModalType.Agreement, props)}
      {renderModal(AutoLogoutModal, ModalType.AutoLogout, props)}
      {renderModal(CloseApplicationModal, ModalType.CloseApplication, props)}
      {renderModal(ServerErrorModal, ModalType.ServerError, props)}
      {renderModal(LoanCalculatorModal, ModalType.LoanCalculator, props)}
      {renderModal(
        RedirectToCreditorModal,
        ModalType.RedirectToCreditor,
        props,
      )}
      {renderModal(
        PassportChangeHelpModal,
        ModalType.PassportChangeHelp,
        props,
      )}
    </div>
  );
}

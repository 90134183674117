import React from 'react';
import P from 'prop-types';
import { Modal, Text, Title, Link, Row, Col } from '../../base';

/* eslint-disable react/no-danger */
export function PassportChangeHelpModal(props) {
  return (
    <Modal
      open={props.open}
      hasCloseButton
      onClose={props.onClose}
      position="top"
      title="Инструкция по смене паспортных данных"
    >
      <div className="u-px-4-md u-pb-2">
        <div className="passport-help--points-container">
          <div className="passport-help--line" />

          <div className="u-flex">
            <Title size={4} className="passport-help--point u-mr-1">
              1
            </Title>
            <Title size={4} className="passport-help--section-title">
              Сделайте 3 фотографии (согласно требованиям*):
            </Title>
          </div>

          <Row className="passport-help--point-block u-pt-2">
            <Col className="u-pb-2">
              <Text className="u-mb-1">1. Первый разворот паспорта</Text>
              <div className="passport-help--photo-1" />
            </Col>
            <Col className="u-pb-2">
              <Text className="u-mb-1">
                2. Разворот с данными о регистрации
              </Text>
              <div className="passport-help--photo-2" />
            </Col>
            <Col className="u-pb-2">
              <Text className="u-mb-1">
                3. Владелец паспорта с документом в руках
              </Text>
              <div className="passport-help--photo-3" />
            </Col>
          </Row>
        </div>

        <div className="u-flex">
          <Title size={4} className="passport-help--point u-mr-1">
            2
          </Title>
          <Title size={4} className="passport-help--section-title">
            Фотографии, свой номер телефона и пометку о том, что именно
            изменилось в ваших данных, отправьте на{' '}
            <Link href={'emailto:support@finspin.ru'}>support@finspin.ru</Link>
          </Title>
        </div>

        <div className="passport-help--additional u-mt-4 u-mb-4">
          <Title size={4} className="u-mb-2">
            * Требования к фотографиям:
          </Title>
          <div className="u-flex u-mb-2">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              На фотографии должен быть изображен оригинал документа, все детали
              которого хорошо видны (вместо фотографии можно использовать
              скан-копию).
            </Text>
          </div>

          <div className="u-flex u-mb-2">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              Фотография должна быть цветной, а фон на ней однотонным.
            </Text>
          </div>

          <div className="u-flex u-mb-2">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              Документ на фотографии должен быть отображен целиком, без
              обрезания углов и краев.
            </Text>
          </div>

          <div className="u-flex u-mb-2">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              Фотография документа не должна содержать посторонние предметы
              (скрепки, карандаши, пальцы и т.д.) или дефекты
              фотографирования/сканирования (следы от вспышки, размытие и т.д.).
            </Text>
          </div>

          <div className="u-flex u-mb-2">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              Нельзя отправлять фотографии, отредактированные в графическом
              редакторе.
            </Text>
          </div>

          <div className="u-flex">
            <div className="passport-help--additional-point" />
            <Text color="secondary" type="body-2">
              Файлы с фотографиями должны быть в формате jpg, png или pdf.
              Размер одного файла не должен превышать 5 мегабайт.
            </Text>
          </div>
        </div>

        <Text>
          Остались вопросы? Вы всегда можете позвонить нам по бесплатному номеру{' '}
          <Text bold inline noWrap>
            8 (800) 555-87-16
          </Text>
        </Text>
      </div>
    </Modal>
  );
}

PassportChangeHelpModal.propTypes = {
  open: P.bool.isRequired,
  onClose: P.func.isRequired,
  data: P.shape({
    vendor: P.string.isRequired,
    agreement: P.string.isRequired,
    data: P.object,
  }),
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Text } from '../../../base';
import { AgreementName, AgreementVendor } from '../../../domain';
import { ModalType } from '../../modals/modal_type';
import { Actions } from '../../state';

export function ProcessDataCheckboxLabel() {
  const dispatch = useDispatch();
  function onShowPersonalSignAgreement() {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor: AgreementVendor.Finspin,
        agreement: AgreementName.ProcessingPersonalData,
      }),
    );
  }
  return (
    <Text className="u-mb-2" type="body-2" inline>
      Даю согласие на{' '}
      <Link
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onShowPersonalSignAgreement();
        }}
      >
        обработку персональных данных, передачу их третьим лицам и соглашаюсь с
        публичной офертой
      </Link>
    </Text>
  );
}

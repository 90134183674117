import React from 'react';
import P from 'prop-types';
import cn from 'classnames';
import { Icon } from './icon';
import { getDataAttrs } from './utils';
import { OnClickOutsideContainer } from './on_click_outside_container';

export class Menu extends React.Component {
  static propTypes = {
    items: P.arrayOf(
      P.shape({
        id: P.oneOfType([P.string, P.number]).isRequired,
        caption: P.node.isRequired,
        iconName: P.string,
        dataAttrs: P.object,
      }),
    ).isRequired,
    onSelect: P.func.isRequired,
    defaultOpen: P.bool,
    caption: P.string,
    captionIconName: P.string,
    dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    defaultOpen: false,
    caption: '',
    captionIconName: '',
    dataAttrs: null,
  };

  state = {
    isOpen: this.props.defaultOpen || false,
  };

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  handleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSelect = (id) => {
    this.handleOpen();
    this.props.onSelect(id);
  };

  render() {
    return (
      <OnClickOutsideContainer onClick={this.handleClickOutside}>
        <div
          className={cn('menu', {
            'menu__is-open': this.state.isOpen,
          })}
        >
          <div
            className="menu--caption"
            onClick={this.handleOpen}
            role="presentation"
            {...getDataAttrs(this.props.dataAttrs)}
          >
            {this.props.captionIconName ? (
              <div className="menu--caption-icon">
                <Icon name={this.props.captionIconName} />
              </div>
            ) : null}
            {this.props.caption ? (
              <div className="menu--caption-text">{this.props.caption}</div>
            ) : null}
            <div className="menu--caret-icon">
              <Icon name="caret-down-icon" />
            </div>
          </div>
          {this.state.isOpen ? (
            <div className="menu--dropdown">
              {this.props.items.map((item) => (
                <div
                  className="menu--dropdown-item"
                  key={item.id}
                  onClick={() => this.handleSelect(item.id)}
                  role="presentation"
                  {...getDataAttrs(item.dataAttrs)}
                >
                  {item.iconName ? (
                    <div className="menu--dropdown-item-icon">
                      <Icon name={item.iconName} />
                    </div>
                  ) : null}
                  <div className="menu--dropdown-item-caption">
                    {item.caption}
                  </div>
                </div>
              ))}
              <div
                className="menu--dropdown-close"
                onClick={this.handleOpen}
                role="presentation"
              >
                <Icon name="close-icon-sm" />
              </div>
            </div>
          ) : null}
        </div>
      </OnClickOutsideContainer>
    );
  }
}

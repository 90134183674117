import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

export function Icon(props) {
  const className = cn('icon', props.className, {
    [`${props.name}`]: true,
    [`pos-${props.pos}`]: props.pos,
  });

  return <i className={className} />;
}

Icon.propTypes = {
  // В атрибуте name указывается имя css класса из src/base/styles/themes/{theme}/icons.scss
  name: P.string.isRequired,
  className: P.string,
};

Icon.defaultProps = {
  className: '',
};

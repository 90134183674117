import React from 'react';
import P from 'prop-types';
import moment from 'moment';
import { Button, Col, Form, Row, Slider, Text, Title } from '../../base';
import { formatDayCount, formatMoney } from '../../helpers/format_helpers';
import {
  calcOverpayment,
  getLoanAmountOrDefault,
  getLoanTermOrDefault,
} from '../../domain';

export class LoanCalculator extends React.Component {
  static propTypes = {
    minTerm: P.number.isRequired,
    maxTerm: P.number.isRequired,
    termStep: P.number.isRequired,
    minAmount: P.number.isRequired,
    maxAmount: P.number.isRequired,
    amountStep: P.number.isRequired,
    rate: P.number.isRequired,
    buttonCaption: P.string,
    onSubmit: P.func.isRequired,
    isSubmitPending: P.bool,
    defaultValues: P.shape({
      term: P.number,
      amount: P.number,
    }),
    onFieldChange: P.func,
  };

  state = {
    eng: process.env.THEME === 'eng',
  };

  static defaultProps = {
    // buttonCaption: this.state.eng ? 'Apply now' : 'Подать заявку',
    isSubmitPending: false,
    defaultValues: null,
    onFieldChange: null,
  };

  handleSubmit = (values) => {
    this.props.onSubmit(values);
  };

  handleFieldChange = (value, field) => {
    if (this.props.onFieldChange) {
      this.props.onFieldChange(value, field);
    }
  };

  render() {
    const { minTerm, maxTerm, minAmount, maxAmount } = this.props;
    const defaultValues = this.props.defaultValues || {};

    return (
      <Form
        onSubmit={this.handleSubmit}
        defaultValues={{
          term: getLoanTermOrDefault(minTerm, maxTerm, defaultValues.term),
          amount: getLoanAmountOrDefault(
            minAmount,
            maxAmount,
            defaultValues.amount,
          ),
        }}
      >
        {({ values }) => {
          const overpayment = calcOverpayment(
            values.amount,
            values.term,
            this.props.rate,
          );

          return (
            <div>
              <Row className="u-mb-2">
                <Col>
                  <Slider
                    field="amount"
                    step={this.props.amountStep}
                    min={this.props.minAmount}
                    max={this.props.maxAmount}
                    title={this.state.eng ? 'I need' : 'Хочу получить'}
                    getValueCaption={(val) => formatMoney(val)}
                    minValueCaption={`${
                      this.state.eng ? 'from' : 'от'
                    } ${formatMoney(this.props.minAmount)}`}
                    maxValueCaption={`${
                      this.state.eng ? 'to' : 'до'
                    } ${formatMoney(this.props.maxAmount)}`}
                    onChange={this.handleFieldChange}
                  />
                </Col>
              </Row>

              <Row className="u-mb-2">
                <Col>
                  <Slider
                    field="term"
                    min={minTerm}
                    max={maxTerm}
                    step={this.props.termStep}
                    title={this.state.eng ? 'Payback' : 'Верну через'}
                    getValueCaption={(val) => `${val} ${formatDayCount(val)}`}
                    minValueCaption={
                      this.state.eng
                        ? `in ${minTerm} ${minTerm > 1 ? 'days' : 'day'}`
                        : `от ${minTerm} ${minTerm > 1 ? 'дней' : 'дня'}`
                    }
                    maxValueCaption={`${
                      this.state.eng ? 'to' : 'до'
                    } ${maxTerm} ${formatDayCount(maxTerm)}`}
                    onChange={this.handleFieldChange}
                  />
                </Col>
              </Row>

              {this.state.eng ? null : (
                <Row hCenter className="u-mb-3">
                  <Col size="auto" className="u-mr-1 u-pl-1 u-p-0">
                    <Text type="body-2">Займ</Text>
                    <Title size={4}>{formatMoney(values.amount)}</Title>
                  </Col>
                  <Col
                    size="auto"
                    end
                    className="u-mr-1 loan-calculator--sign u-p-0"
                  >
                    <Title size={4}>+</Title>
                  </Col>
                  <Col size="auto" className="u-mr-1 u-p-0">
                    <Text type="body-2">Переплата</Text>
                    <Title size={4}>{formatMoney(overpayment)}</Title>
                  </Col>
                  <Col
                    size="auto"
                    end
                    className="u-mr-1 loan-calculator--sign u-p-0"
                  >
                    <Title size={4}>=</Title>
                  </Col>
                  <Col size="auto" className="u-pr-1 u-pl-0">
                    <Text type="body-2">Итого</Text>
                    <Title size={4}>
                      {formatMoney(values.amount + overpayment)}
                    </Title>
                  </Col>
                  <Col
                    size={12}
                    md="auto"
                    className={
                      'u-ml-1-md u-text-align-center ' +
                      'u-text-align-left-md u-mt-1 u-mt-0-md ' +
                      'u-p-0'
                    }
                  >
                    <Text type="body-2" className="u-inline-block u-block-md">
                      Вернуть до
                    </Text>{' '}
                    <Title size={4} className="u-inline-block u-block-md">
                      {moment()
                        .add(values.term, 'days')
                        .format('D MMMM YYYY, dd')}
                    </Title>
                  </Col>
                </Row>
              )}
              <Row hCenter className="u-mb-2">
                <Col>
                  <Button
                    type="submit"
                    maxWidth
                    isPending={this.props.isSubmitPending}
                  >
                    {this.state.eng ? 'Apply now' : 'Подать заявку'}
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Text
                    type="body-2"
                    color="secondary"
                    center
                    className="loan-calculator--rate-warning"
                  >
                    {this.state.eng
                      ? 'The service does not charge you any fee'
                      : 'Внимание! Процент и сумма переплаты могут измениться после заполнения анкеты. Сервис не берет комиссию за подбор займа.'}
                  </Text>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form>
    );
  }
}

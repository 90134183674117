import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import { getDataAttrs } from './utils';

export function IconButton(props) {
  const className = cn('icon-button', props.className, {
    [`is-${props.size}`]: true,
  });

  return (
    <button
      className={className}
      onClick={props.onClick}
      disabled={props.disabled}
      {...getDataAttrs(props.dataAttrs)}
    >
      <span className={`${props.name}-icon-${props.size}`} />
    </button>
  );
}

IconButton.propTypes = {
  name: P.string.isRequired,
  onClick: P.func,
  disabled: P.bool,
  size: P.oneOf(['sm', 'md', 'lg']),
  /* eslint-disable react/forbid-prop-types */
  dataAttrs: P.object,
  /* eslint-enable */
  className: P.string,
};

IconButton.defaultProps = {
  onClick: null,
  disabled: false,
  size: 'md',
  dataAttrs: null,
  className: '',
};

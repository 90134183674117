/* eslint-disable arrow-body-style */
import React from 'react';
import * as R from 'ramda';
import {
  Address,
  Autocomplete,
  Button,
  Checkbox,
  Col,
  Form,
  RadioButtonGroup,
  Row,
  Select,
  NewSelect,
  TextField,
  Title,
  Text,
  Link,
} from '../../../base';
import { noError, isEmpty } from '../../../validation';
import { FullFieldsBlockWarning } from './shared';
import { scrollTo } from '../../../helpers/dom_helpers';
import { AgreementName, AgreementVendor } from '../../../domain';

export const WidgetTypes = {
  textField: TextField,
  address: Address,
  radioButtonGroup: RadioButtonGroup,
  checkbox: Checkbox,
  select: Select,
  newSelect: NewSelect,
  autocomplete: Autocomplete,
};

export class ApplicationForm extends React.Component {
  // static propTypes = {
  //   onFieldChange: P.func.isRequired,
  //   profile: P.object.isRequired,
  //   isSubmitPending: P.bool,
  //   onSubmit: P.func,
  //   step: P.object,
  //   stepNumber: P.number,
  //   onShowPassportChangeHelp: P.func,
  //   onShowAgreement: P.func,
  // };

  // static defaultProps = {
  //   onSubmit: R.identity,
  // };

  state = {
    errors: {},
    shouldScroll: false,
    values: null,
  };

  componentDidUpdate(props, state) {
    if (this.state.errors !== state.errors && this.state.shouldScroll) {
      this.scrollToError();
    }
  }

  setValue = (name, val) => {
    this.formRef.form.setValue(name, val);
    this.props.onFieldChange(val, name);
  };

  getValue = (name, fallback) => this.formRef.form.getValue(name, fallback);

  getDefaultValues = (profile) => {
    const initial = R.reduce(
      (acc, field) => {
        return R.assoc(
          field.name,
          field.formatValue(
            R.isNil(profile[field.name])
              ? field.defaultValue
              : profile[field.name],
          ),
          acc,
        );
      },
      {},
      this.props.step.fields(profile),
    );
    return initial;
  };

  getFieldValidator = (field) => {
    return field.validate || (field.optional ? () => noError : isEmpty);
  };

  handleValidate = (values) => {
    return R.reduce(
      (errors, field) =>
        R.assoc(
          field.name,
          this.getFieldValidator(field)(values[field.name], {
            required: !field.optional,
          }),
          errors,
        ),
      {},
      this.props.step.fields(values),
    );
  };

  handleFieldChange = (field) => (rawValue, name) => {
    this.setState({ shouldScroll: false });
    const parsed = field.parseValue(rawValue);

    if (field.onChange) {
      field.onChange(parsed, name, rawValue, this.getValue, this.setValue);
    }

    this.props.onFieldChange(parsed, name);
  };

  handlePreSubmit = (values) => {
    const preSubmitValues = R.reduce(
      (acc, field) =>
        R.assoc(field.name, field.parseValue(values[field.name]), acc),
      {},
      this.props.step.fields(values),
    );
    this.setState({ values: preSubmitValues });
    return preSubmitValues;
  };

  setErrors = (errors) => {
    if (errors) {
      this.setState({ errors });
    }
  };

  scrollToError = () => {
    const arrayErrors = this.state.values
      ? this.props.step.fields(this.state.values)
      : [];
    const sortedErrors = arrayErrors
      .filter((f) => this.state.errors[f.name])
      .map((field, i) => {
        if (this.state.errors[field.name]) {
          return {
            field: field.name,
            i,
          };
        }
        return null;
      })
      .sort((a, b) => a.i - b.i);
    if (sortedErrors.length) {
      let field;
      switch (sortedErrors[0].field) {
        case 'registrationAddress':
          field = 'registrationAddress:region';
          break;
        case 'jobAddress':
          field = 'jobAddress:region';
          break;
        case 'factAddress':
          field = 'factAddress:region';
          break;
        default:
          field = sortedErrors[0].field;
          break;
      }
      const element = `[data-id="${field}"]`;
      scrollTo(element);
    }
  };

  handleSubmit = (values) => {
    const formValues = { ...values };
    if (this.props.stepNumber === 1) {
      formValues.agreements = {
        creditHistoryRequestAgreement: true,
        processDataAgreement: true,
        receiveInfoAgreement: true,
        usePersonalSignAgreement: true,
      };
    }

    this.setState({ shouldScroll: true });
    this.scrollToError();
    const fixedNames = this.props.step
      .fields(formValues)
      .filter((f) => f.fixed)
      .map((f) => f.name);

    const withoutFixed = R.omit(fixedNames, formValues);
    return this.props.onSubmit(withoutFixed);
  };

  isAnyFixed = (values) =>
    R.any((field) => field.fixed, this.props.step.fields(values));

  renderField = (field, i) => {
    const isHidden = field.widgetOptions.hidden;
    const widgetComponent = WidgetTypes[field.type];
    const widget = React.createElement(
      widgetComponent,
      R.mergeDeepRight(field.widgetOptions, {
        items: field.widgetOptions.items,
        field: field.name,
        optional: field.optional,
        fixed: field.fixed,
        onChange: this.handleFieldChange(field),
        dataAttrs: { id: field.name, stat: '1', scroll: `${field.name}-${i}` },
      }),
    );
    if (isHidden) {
      return null;
    }

    if (field.section) {
      return (
        <Row className="u-mt-4 u-mb-2">
          <Col md={4} className="u-mb-2 u-mb-0-md">
            <Title size={4} rightMd>
              {field.section}
            </Title>
          </Col>
          <Col md={8}>
            {field.bonus && (
              <div className="u-mb-2">
                <Text inline>Укажите {field.bonus.caption} и получите</Text>{' '}
                <Text bold inline style={{ color: '#817AFF' }}>
                  +{field.bonus.value}%
                </Text>{' '}
                <Text bold inline>
                  к вероятности получения кредита
                </Text>
              </div>
            )}
            {widget}
          </Col>
        </Row>
      );
    }

    return (
      <Row className="u-mb-2">
        <Col offsetMd={4} {...(field.isNarrow ? { lg: 4 } : { md: 8 })}>
          {field.beforeDescription}
          {widget}
        </Col>
      </Row>
    );
  };

  render() {
    const { step, profile, stepNumber } = this.props;
    const { agreements } = profile;
    const hasAgreements = R.keys(agreements).every(
      (agreementKey) => agreements[agreementKey],
    );

    return (
      <Form
        preSubmit={this.handlePreSubmit}
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        defaultValues={this.getDefaultValues(profile)}
        setErrors={this.setErrors}
        ref={(ref) => {
          this.formRef = ref;
        }}
      >
        {({ values, ...rest }) => {
          return (
            <div>
              <Row>
                <Col md={3} orderMd={9}>
                  {this.isAnyFixed(values) ? (
                    <FullFieldsBlockWarning
                      onShowPassportChangeHelp={
                        this.props.onShowPassportChangeHelp
                      }
                    />
                  ) : null}
                </Col>

                {/* <pre>{JSON.stringify(step.fields(values), undefined, 2)}</pre> */}

                {
                  // Creating this Col manually to pass fields as individual children
                  // Passing an array requires unique `key` attr for each element.
                  React.createElement(
                    Col,
                    { md: 9 },
                    ...step.fields(values).map(this.renderField),
                  )
                }
              </Row>

              <Row className="u-mb-4 u-mt-2">
                <Col offsetMd={3} md={6}>
                  <Button
                    maxWidth
                    type="submit"
                    isPending={this.props.isSubmitPending}
                    dataAttrs={{
                      state: `1`,
                      stat: `Шаг анкеты ${stepNumber}`,
                    }}
                  >
                    Продолжить
                  </Button>
                </Col>
              </Row>
              {stepNumber === 1 && !hasAgreements ? (
                <Row className="u-mb-4 u-mt-2">
                  <Col offsetMd={3} md={6}>
                    <Text className="u-mb-2" type="body-2">
                      Нажимая на кнопку «Продолжить», вы соглашаетесь с{' '}
                      <Link
                        onClick={() =>
                          this.props.onShowAgreement(
                            AgreementVendor.Finspin,
                            AgreementName.PersonalSignUse,
                          )
                        }
                        dataAttrs={{ stat: '1' }}
                      >
                        Условием использования аналога собственноручной подписи
                      </Link>
                      ,{' '}
                      <Link
                        onClick={() =>
                          this.props.onShowAgreement(
                            AgreementVendor.Finspin,
                            AgreementName.ProcessingPersonalData,
                          )
                        }
                        dataAttrs={{ stat: '1' }}
                      >
                        Соглашением на обработку персональных данных
                      </Link>
                      ,{' '}
                      <Link
                        onClick={() =>
                          this.props.onShowAgreement(
                            AgreementVendor.Finspin,
                            AgreementName.InfoReceiving,
                          )
                        }
                        dataAttrs={{ stat: '1' }}
                      >
                        Соглашением на получение информации
                      </Link>
                      ,{' '}
                      <Link
                        onClick={() =>
                          this.props.onShowAgreement(
                            AgreementVendor.Finspin,
                            AgreementName.CreditInfoDisclosure,
                          )
                        }
                        dataAttrs={{ stat: '1' }}
                      >
                        Соглашением на получение доступа к кредитной истории
                      </Link>
                      .
                    </Text>
                  </Col>
                </Row>
              ) : null}
            </div>
          );
        }}
      </Form>
    );
  }
}

import React, { useEffect } from 'react';
import P from 'prop-types';
import {
  Col,
  Container,
  Icon,
  IconButton,
  Panel,
  Row,
  Title,
} from '../../base';
import {
  formatMoney,
  formatISODateToTextField,
} from '../../helpers/format_helpers';
import { LoanConditions } from './shared';
import { LoanProps } from '../shared/loan_props';
import { changePage } from '../../metrics';

export function RepaidLoan(props) {
  const { loan } = props;

  useEffect(() => {
    changePage(`${loan.mfiAlias}Received`);
  }, []);

  return (
    <Container>
      <Row className="u-mt-2 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-neutral-icon" />
        </Col>
        <Col size={2} start>
          <IconButton
            name="close"
            onClick={props.onClose}
            className="u-align-right"
            dataAttrs={{
              state: 'ev:click;ctx:Детали repaid займа, кнопка "Закрыть форму"',
            }}
          />
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col>
          <Title size={3} sm={2} center>
            Займ использован
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-2">
        <Col md={6} offsetMd={3}>
          <Panel color="neutral">
            <Row className="u-mb-1">
              <Col>
                <Title size={1} color="inverse" center>
                  {formatMoney(loan.amount)}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <Title size={3} color="inverse" center>
                  Вернуть до &nbsp;
                  {formatISODateToTextField(loan.expirationDate)}
                </Title>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>

      <Row className="u-mb-8">
        <Col md={6} offsetMd={3}>
          <LoanConditions
            loan={loan}
            // !!!! ПЕРЕДЕЛАТЬ
            visibleParams={['moneyIssuedDate', 'closeDate', 'deadline']}
          />
        </Col>
      </Row>
    </Container>
  );
}

RepaidLoan.propTypes = {
  loan: LoanProps.isRequired,
  onClose: P.func.isRequired,
  // onCreateLoan: P.func.isRequired,
  // canCreateLoan: P.bool,
  // isCreateLoanPending: P.bool
};

RepaidLoan.defaultProps = {
  canCreateLoan: false,
  isCreateLoanPending: false,
};

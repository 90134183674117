import React, { useEffect } from 'react';

// import * as Format from '../../helpers/format_helpers'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  ContentWithFooter,
  ContentBeforeFooter,
  Footer,
} from '../../base';
import { ApprovedLoan } from './approved_loan';
import { RepaidLoan } from './repaid_loan';
import { ExpiredLoan } from './expired_loan';
import { Actions } from '../state';
import { ModalType } from '../modals/modal_type';
import { AccountNavbar } from '../shared/account_navbar';
import { Support } from '../shared/support';

export function Loan({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((s) => s);
  useEffect(() => {
    dispatch(Actions.fetchCreditRequest(match.id));
    dispatch(Actions.fetchLoanRequestSuggestion(match.id));
  }, []);

  let { suggestions } = state.creditRequest;
  suggestions = suggestions || [];

  const currentSuggestion =
    suggestions.filter(
      (suggestion) => String(suggestion.id) === match.itemId,
    )[0] || {};

  function onShowAgreement(vendor, agreement) {
    return dispatch(
      Actions.openModal(ModalType.Agreement, { vendor, agreement }),
    );
  }

  function renderLoan() {
    if (!currentSuggestion.id) {
      return null;
    }

    const defaultProps = {
      loan: currentSuggestion,
      onClose: onClose,
    };

    switch (currentSuggestion.status) {
      case 'available':
        return (
          <ApprovedLoan
            {...defaultProps}
            onGetMoney={onGetMoney}
            onShowAgreement={onShowAgreement}
          />
        );

      case 'used':
        return <RepaidLoan {...defaultProps} />;

      case 'expired':
        return <ExpiredLoan {...defaultProps} />;

      default:
        throw new Error('Unknown loan status: ');
    }
  }

  function onClose() {
    return history.goBack();
  }

  function onGetMoney(creditor, creditorUrl) {
    dispatch(
      Actions.openModal(ModalType.RedirectToCreditor, {
        creditor,
        url: creditorUrl,
      }),
    );
  }

  return (
    <ContentWithFooter>
      <ContentBeforeFooter>
        <AccountNavbar />
        {renderLoan()}
      </ContentBeforeFooter>
      <Footer>
        <Container>
          <Row className="u-mb-4 u-pt-4">
            <Col offsetMd={3} md={6}>
              <Support phone={process.env.SUPPORT_PHONE} />
            </Col>
          </Row>
        </Container>
      </Footer>
    </ContentWithFooter>
  );
}

import React from 'react';
import { Icon } from './icon';
import { Title } from './typography';

export function NewCalcPlaceholder() {
  return (
    <div className="calc-placeholder">
      <div className="calc-placeholder__title" />
      <div className="calc-placeholder__value-1" />
      <div className="calc-placeholder__slider-1" />
      {/* <div className='calc-placeholder__captions-1'>
        <div className='calc-placeholder__caption' />
        <div className='calc-placeholder__caption' />
      </div>
      <div className='calc-placeholder__value-2' />
      <div className='calc-placeholder__slider-2' />
      <div className='calc-placeholder__captions-2'>
        <div className='calc-placeholder__caption' />
        <div className='calc-placeholder__caption' />
      </div> */}

      <div className="calc-placeholder__button" />

      <div className="calc-placeholder__loader">
        <div className="calc-placeholder__loader-icon">
          <Icon name="spinner-primary-bg-sm" />
        </div>
        <Title size={3} inline>
          Загрузка...
        </Title>
      </div>
    </div>
  );
}

/* eslint-disable import/no-dynamic-require, global-require */
import * as R from 'ramda';

function renderTemplate(template, data) {
  return R.keys(data).reduce((acc, name) => {
    const regexp = new RegExp(`{{${name}}}`, 'ig');
    return acc.replace(regexp, data[name].toString());
  }, template);
}

export function createAgreements(config) {
  function updateMeta(meta) {
    return {
      title: meta.title,
      content(data) {
        const template = require(`html-loader!./html/${meta.path}.html`);
        // const template = require(`html-loader?{removeComments:false}!./html/${meta.path}.html`);
        return renderTemplate(template, data);
      },
    };
  }

  function updateAgrs(agrs) {
    return R.keys(agrs).reduce(
      (a, v) => R.assoc(v, updateMeta(agrs[v]), a),
      {},
    );
  }

  return R.keys(config).reduce(
    (a, v) => R.assoc(v, updateAgrs(config[v]), a),
    {},
  );
}

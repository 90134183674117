import React from 'react';
import { Row, Col, Text, Button, Modal } from '../../base';
// import * as idleMonitor from '../../idle_monitor';

export function AutoLogoutModal(props) {
  function onContinue() {
    props.onClose();
    if (props.data && props.data.unauthorizedError) {
      window.location.replace(window.location.origin + '/auth');
    }
    // Restart the idle timer.
    // TODO: allow to subscribe to modal close event, move this out of here.
    if (props.data && !props.data.unauthorizedError) {
      // idleMonitor.reset();
    }
  }

  return (
    <Modal
      open={props.open}
      hasCloseButton
      title="Вы ещё здесь?"
      onClose={onContinue}
    >
      <Row hCenter>
        <Col size={10}>
          <Text center>
            Вы не пользовались сайтом уже очень давно и будете автоматически
            перенаправлены на страницу авторизации. Вы хотите продолжить работу?
          </Text>
        </Col>
      </Row>
      <Row hCenter className="u-mb-4 u-mt-4">
        <Col md={4}>
          <Button maxWidth color="primary" onClick={onContinue}>
            Продолжить{' '}
            {props.data && props.data.secondsLeft
              ? `(${props.data.secondsLeft})`
              : ''}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

import React from 'react';

import * as R from 'ramda';
import * as Validation from '../../../validation';
import {
  searchRegions,
  searchCities,
  searchStreets,
  searchHouses,
  searchFirstNames,
  searchLastNames,
  searchMiddleNames,
  searchOrganizationsInn,
} from '../../../dadata';

import { ProcessDataCheckboxLabel } from './fieldLables';
import * as Domain from '../../../domain';
import * as Format from '../../../helpers/format_helpers';

function getNameValue(obj) {
  return R.is(Object, obj) ? obj.value : obj;
}
function getOrganizationAddress(obj) {
  return R.is(Object, obj) ? obj.adress : obj;
}

function autoselectGender(value, field, rawValue, getValue, setValue) {
  console.log('call', value, field, rawValue);
  if (rawValue && rawValue.gender) {
    console.log(getValue('gender'));
    if (getValue('gender') !== rawValue.gender) {
      setValue('gender', rawValue.gender);
    }
  }
}
function autosetINN(value, field, rawValue, getValue, setValue) {
  if (rawValue && rawValue.inn) {
    if (getValue('jobOrgInn') !== rawValue.inn) {
      setValue('jobOrgInn', rawValue.inn);
      return;
    }
  } else {
    if (getValue('jobOrgInn') === null) {
      return;
    }
    setValue('jobOrgInn', null);
  }

  if (getValue('jobOrgInn') === undefined) {
    setValue('jobOrgInn', null);
  }
}

function parseToIntvalue(value) {
  if (value) {
    const newVal = String(value).replace(/[^0-9]/g, '');
    return parseInt(newVal, 10);
  }
  return null;
}

function formatInputValie(value, field, rawValue, getValue, setValue) {
  const newVal = rawValue.replace(/[^0-9]/g, '');
  return setValue(field, newVal);
}

const textDefaults = {
  type: 'textField',
  parseValue: R.identity,
  formatValue: R.identity,
  widgetOptions: {
    type: 'text',
  },
};

const dateDefaults = {
  type: 'textField',
  parseValue: Format.parseTextFieldDate,
  formatValue: Format.formatISODateToTextField,
  validate: Validation.isTextFieldDate,
  widgetOptions: {
    type: 'text',
    mask: '99.99.9999',
  },
};

const phoneDefaults = {
  type: 'textField',
  parseValue: Format.parsePhone,
  formatValue: Format.formatPhone,
  validate: Validation.isPhone,
  widgetOptions: {
    type: 'tel',
    mask: '+7 (999) 999-99-99',
  },
};

const selectDefaults = {
  type: 'select',
  parseValue: (value) => (R.isNil(value) ? value : value.value),
  widgetOptions: {
    onGetOptionCaption: (o) => o.caption,
    onGetValueCaption: (o) => o.caption,
    onGetOptionId: (o) => o.value,
  },
};
const newSelectDefaults = {
  type: 'newSelect',
  parseValue: (value) => (R.isNil(value) ? value : value.value),
  widgetOptions: {
    onGetOptionCaption: (o) => o.caption,
    onGetValueCaption: (o) => o.caption,
    onGetOptionId: (o) => o.value,
  },
};

const nameDefaults = {
  type: 'autocomplete',
  formatValue: (str) => ({ value: str }),
  parseValue: getNameValue,
  widgetOptions: {
    onGetSuggestionCaption: R.prop('value'),
    onGetValueCaption: getNameValue,
    onGetSuggestionId: R.prop('value'),
    allowCustomValue: true,
    delay: 300,
  },
};

const organizationDefaults = {
  type: 'autocomplete',
  formatValue: (str) => {
    return { value: str };
  },
  parseValue: getNameValue,
  widgetOptions: {
    onGetSuggestionCaption: R.prop('value'),
    onGetValueCaption: getNameValue,
    onGetValueExtraText: getOrganizationAddress,
    onGetSuggestionId: R.prop('inn'),
    allowCustomValue: true,
    delay: 300,
  },
};

const addressDefaults = {
  type: 'address',
  parseValue: (value = {}) => ({
    ...value,
    withoutFlat: value.withoutFlat || false,
  }),
  formatValue: R.identity,
  validate: (value = {}, options) => ({
    region: Validation.isEmpty(value.region),
    city:
      Validation.isEmpty(value.city) && Validation.isEmpty(value.settlement),
    street: Validation.isEmpty(value.street),
    house: Validation.isEmpty(value.house),
    flat: value.withoutFlat
      ? Validation.noError
      : Validation.isFlat(value.flat, options),
  }),
  widgetOptions: {
    searchRegion: searchRegions,
    searchCity: searchCities,
    searchStreet: searchStreets,
    searchHouse: searchHouses,
    renderFields: {
      region: true,
      city: true,
      street: true,
      house: true,
      flat: true,
      withoutFlat: true,
    },
  },
};

export const Fields = {
  lastName: R.mergeDeepRight(nameDefaults, {
    validate: (value, options) =>
      Validation.isLastName(getNameValue(value), options),
    fixable: true,
    fackTest: false,
    fixed: true,
    onChange: autoselectGender,
    widgetOptions: {
      label: 'Фамилия *',
      analyticsLabel: 'Фамилия',
      onGetSuggestions: searchLastNames,
    },
  }),
  // lastName: R.mergeDeepRight(textDefaults, {
  //   fixable: true,
  //   validate: Validation.isLastName,
  //   widgetOptions: {
  //     label: 'Фамилия *',
  //     analyticsLabel: 'Фамилия',
  //   },
  // }),

  firstName: R.mergeDeepRight(nameDefaults, {
    validate: (value, options) =>
      Validation.isFirstName(getNameValue(value), options),
    fixable: true,
    onChange: autoselectGender,
    widgetOptions: {
      label: 'Имя *',
      analyticsLabel: 'Имя',
      onGetSuggestions: searchFirstNames,
    },
  }),
  // firstName: R.mergeDeepRight(textDefaults, {
  //   fixable: true,
  //   validate: Validation.isFirstName,
  //   widgetOptions: {
  //     label: 'Имя *',
  //     analyticsLabel: 'Имя',
  //   },
  // }),

  middleName: R.mergeDeepRight(nameDefaults, {
    validate: (value, options) =>
      Validation.isMiddleName(getNameValue(value), options),
    fixable: true,
    onChange: autoselectGender,
    widgetOptions: {
      label: 'Отчество',
      analyticsLabel: 'Отчество',
      onGetSuggestions: searchMiddleNames,
    },
  }),
  // middleName: R.mergeDeepRight(textDefaults, {
  //   fixable: true,
  //   validate: Validation.isMiddleName,
  //   optional: true,
  //   widgetOptions: {
  //     label: 'Отчество',
  //     analyticsLabel: 'Отчество',
  //   },
  // }),

  creditRegion: R.mergeDeepRight(newSelectDefaults, {
    formatValue: (value) => value,
    widgetOptions: {
      label: 'Регион *',
      analyticsLabel: 'creditRegion',
      hint: 'Начните вводить и выберите регион из выпадающего списка',
      onGetOptionCaption: (o) => o,
      onGetValueCaption: (o) => o,
      onGetOptionId: (o) => o,
    },
  }),

  birthDate: R.mergeDeepRight(dateDefaults, {
    fixable: true,
    widgetOptions: {
      label: 'Дата рождения *',
      analyticsLabel: 'Дата рождения',
      isNarrow: true,
    },
  }),

  passportSN: R.mergeDeepRight(textDefaults, {
    validate: Validation.isPassportSN,
    fixable: true,
    widgetOptions: {
      label: 'Серия и номер',
      analyticsLabel: 'Серия и номер паспорта',
      mask: '9999 999999',
      isNarrow: true,
    },
  }),

  passportIssuedDate: R.mergeDeepRight(dateDefaults, {
    fixable: true,
    widgetOptions: {
      label: 'Дата выдачи',
      analyticsLabel: 'Дата выдачи паспорта',
      isNarrow: true,
    },
  }),

  email: R.mergeDeepRight(textDefaults, {
    validate: Validation.isEmail,
    optional: true,
    widgetOptions: {
      type: 'email',
      label: 'Электронная почта',
      analyticsLabel: 'Email',
      placeholder: 'ivanov@mail.com',
      hint: '(желательно)',
    },
    bonus: {
      value: 4,
      caption: 'адрес электронной почты',
    },
  }),

  gender: {
    type: 'radioButtonGroup',
    defaultValue: 'male',
    parseValue: R.identity,
    formatValue: R.identity,
    fixable: true,
    widgetOptions: {
      label: 'Пол',
      inline: true,
      items: [
        { value: 'male', caption: 'Мужчина' },
        { value: 'female', caption: 'Женщина' },
      ],
    },
  },

  birthPlace: R.mergeDeepRight(textDefaults, {
    fixable: true,
    widgetOptions: {
      isMultiline: true,
      label: 'Место рождения',
      analyticsLabel: 'Место рождения',
    },
  }),

  passportIssuedBy: R.mergeDeepRight(textDefaults, {
    fixable: true,
    widgetOptions: {
      isMultiline: true,
      label: 'Кем выдан',
      analyticsLabel: 'Кем выдан',
      hint: 'Напишите так же, как в паспорте',
      hintAlways: true,
    },
  }),

  departmentCode: R.mergeDeepRight(textDefaults, {
    validate: Validation.isDepartmentCode,
    fixable: true,
    widgetOptions: {
      label: 'Код подразделения',
      analyticsLabel: 'Код подразд.',
      mask: '999-999',
      isNarrow: true,
    },
  }),

  registrationAddress: R.mergeDeepRight(addressDefaults, {
    widgetOptions: {
      analyticsLabel: 'Адрес регистрации',
    },
  }),

  creditAddress: R.mergeDeepRight(addressDefaults, {
    parseValue: (value = {}) => value,
    validate: (value = {}, options) => ({
      region: Validation.isEmpty(value.region),
      city:
        Validation.isEmpty(value.city) && Validation.isEmpty(value.settlement),
      street: Validation.noError,
      house: Validation.noError,
      flat: Validation.noError,
    }),
    widgetOptions: {
      analyticsLabel: 'Адрес регистрации',
      labels: {
        region: 'Регион *',
        city: 'Город или населенный пункт *',
      },
      searchRegion: (query) => searchRegions(query, true),
      searchCity: (query, context) => searchCities(query, context, true),
      searchStreet: undefined,
      searchHouse: undefined,
      renderFields: {
        street: false,
        house: false,
        flat: false,
        withoutFlat: false,
      },
    },
  }),

  registrationDate: R.mergeDeepRight(dateDefaults, {
    fixable: true,
    widgetOptions: {
      label: 'Дата регистрации',
      analyticsLabel: 'Дата регистрации',
      isNarrow: true,
    },
  }),

  registrationCoincidesWithTheFact: {
    type: 'checkbox',
    defaultValue: true,
    parseValue: R.identity,
    formatValue: R.identity,
    widgetOptions: {
      caption: 'Совпадает с адресом регистрации',
      analyticsLabel: 'Адр. рег. = факт. адр.',
    },
  },
  processDataCheckboxAgreement: {
    type: 'checkbox',
    defaultValue: true,
    parseValue: R.identity,
    formatValue: R.identity,
    widgetOptions: {
      caption: <ProcessDataCheckboxLabel />,
      analyticsLabel: 'processDataCheckboxAgreement ',
    },
  },

  factAddress: R.mergeDeepRight(addressDefaults, {
    widgetOptions: {
      analyticsLabel: 'Факт. адрес',
    },
  }),

  jobOrgName: R.mergeDeepRight(organizationDefaults, {
    validate: (value, options) => {
      return Validation.isOnganizationName(getNameValue(value), options);
    },
    onChange: autosetINN,
    widgetOptions: {
      label: 'Название организации',
      analyticsLabel: 'Название организации',
      onGetSuggestions: searchOrganizationsInn,
    },
  }),

  jobOrgInn: R.mergeDeepRight(textDefaults, {
    validate: () => undefined,
    widgetOptions: {
      hidden: true,
      label: 'Инн',
      analyticsLabel: 'Инн',
    },
  }),

  jobType: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) => R.find(R.whereEq({ value }), Domain.JobTypes),
    widgetOptions: {
      label: 'Тип занятости',
      items: Domain.JobTypes,
    },
  }),
  unemploymentReason: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.UnemploymentReasons),
    widgetOptions: {
      label: 'Причина отсутствия занятости',
      analyticsLabel: 'Причина отсутствия занятости',
      items: Domain.UnemploymentReasons,
    },
  }),
  jobPositionType: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.JobPositionTypes),
    widgetOptions: {
      label: 'Тип должности',
      analyticsLabel: 'Тип должности',
      items: Domain.JobPositionTypes,
    },
  }),
  jobActivityArea: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.JobActivityAreas),
    widgetOptions: {
      label: 'Сфера деятельности предприятия',
      analyticsLabel: 'Сфера деятельности предприятия',
      items: Domain.JobActivityAreas,
    },
  }),
  jobIncomeProofType: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.JobIncomeProofTypes),
    widgetOptions: {
      label: 'Способ подтверждения дохода',
      analyticsLabel: 'Способ подтверждения дохода',
      items: Domain.JobIncomeProofTypes,
    },
  }),

  jobPosition: R.mergeDeepRight(textDefaults, {
    widgetOptions: {
      label: 'Ваша должность',
      analyticsLabel: 'Ваша должность',
    },
  }),
  jobStartDate: R.mergeDeepRight(dateDefaults, {
    widgetOptions: {
      label: 'Дата начала работы на последнем месте',
      analyticsLabel: 'Дата начала работы на последнем месте',
    },
  }),

  jobPhone: R.mergeDeepRight(phoneDefaults, {
    widgetOptions: {
      label: 'Рабочий телефон',
      analyticsLabel: 'Рабочий тел.',
    },
  }),
  regestrationPhone: R.mergeDeepRight(phoneDefaults, {
    widgetOptions: {
      label: 'Мобильный телефон',
    },
  }),

  registrationSms: R.mergeDeepRight(textDefaults, {
    validate: Validation.isConfirmationCode,
    widgetOptions: {
      label: 'Код подтверждения',
      mask: '9999',
    },
  }),

  monthlyIncome: R.mergeDeepRight(textDefaults, {
    parseValue: parseToIntvalue,
    onChange: formatInputValie,
    widgetOptions: {
      label: 'Ежемесячный доход, в руб.',
      analyticsLabel: 'Уровень зарплаты в рублях',
    },
  }),

  jobAddress: R.mergeDeepRight(addressDefaults, {
    validate: (value = {}, options) => ({
      region: Validation.isEmpty(value.region),
      city:
        Validation.isEmpty(value.city) && Validation.isEmpty(value.settlement),
      street: Validation.isEmpty(value.street),
      house: Validation.isEmpty(value.house),
      flat: value.withoutFlat
        ? Validation.noError
        : Validation.isOffice(value.flat, options),
    }),
    widgetOptions: {
      analyticsLabel: 'Адрес работы',
      labels: {
        flat: 'Офис',
        withoutFlat: 'Номер офиса отсутсвует',
      },
    },
  }),

  marriageStatus: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.MarriageStatuses),
    widgetOptions: {
      label: 'Семейное положение',
      analyticsLabel: 'Семейное положение',
      items: Domain.MarriageStatuses,
    },
  }),

  amountOfChildren: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.ChildrenAmounts),
    widgetOptions: {
      label: 'Количество детей',
      analyticsLabel: 'Количество детей',
      items: Domain.ChildrenAmounts,
    },
  }),

  contactName: R.mergeDeepRight(textDefaults, {
    validate: Validation.isFirstNameLastName,
    widgetOptions: {
      label: 'Фамилия и имя',
      analyticsLabel: 'Фамилия и имя довер. лица',
    },
  }),

  additionalPhone: R.mergeDeepRight(phoneDefaults, {
    widgetOptions: {
      label: 'Номер дополнительного телефона',
      analyticsLabel: 'Номер дополнительного телефона',
    },
  }),
  additionalPhoneOwnerType: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) =>
      R.find(R.whereEq({ value }), Domain.AdditionalPhoneOwnerTypes),
    widgetOptions: {
      label: 'Владелец дополнительного телефона',
      analyticsLabel: 'Владелец дополнительного телефона',
      items: Domain.AdditionalPhoneOwnerTypes,
    },
  }),

  spouseMonthlyIncomeAmount: R.mergeDeepRight(textDefaults, {
    // optional: true,
    parseValue: parseToIntvalue,
    onChange: formatInputValie,
    widgetOptions: {
      label: 'Зарплата супруга, руб/месяц',
      analyticsLabel: 'Доход супруга',
    },
  }),

  education: R.mergeDeepRight(selectDefaults, {
    formatValue: (value) => R.find(R.whereEq({ value }), Domain.Education),
    widgetOptions: {
      label: 'Образование',
      analyticsLabel: 'Образование',
      items: Domain.Education,
    },
  }),

  inn: R.mergeDeepRight(textDefaults, {
    validate: Validation.isINN,
    widgetOptions: {
      label: 'ИНН',
      analyticsLabel: 'ИНН',
      mask: '999999999999',
    },
  }),
};

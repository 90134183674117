import React from 'react';
import { Icon } from './icon';
import { Title } from './typography';

export function CalcPlaceholder() {
  return (
    <div className="calc-placeholder">
      <div className="calc-placeholder--rect0">
        <div className="calc-placeholder--rect1" />
        <div className="calc-placeholder--rect2" />
      </div>
      <div className="calc-placeholder--rect3" />
      <div className="calc-placeholder--rect4">
        <div className="calc-placeholder--rect1" />
        <div className="calc-placeholder--rect2" />
      </div>
      <div className="calc-placeholder--rect3" />
      <div className="calc-placeholder--rect5" />
      <div className="calc-placeholder--rect6" />
      <div className="calc-placeholder--rect7" />
      <div className="calc-placeholder--loader">
        <div className="calc-placeholder--loader-icon">
          <Icon name="spinner-primary-bg-sm" />
        </div>
        <Title size={3} inline>
          {process.env.THEME === 'eng' ? 'Loading...' : 'Загрузка...'}
        </Title>
      </div>
    </div>
  );
}

// import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AboutPage } from './landing/about/defaultAbout';
import { Actions } from './state';
import { Agreements } from './agreements/agreements';
import { Auth } from './auth/auth';
import { IdleMonitor } from '../idle_monitor';
import { Landing } from './landing/default';
import { Loan } from './loan/loan';
import { ModalType } from './modals/modal_type';
import { Modals } from './modals/modals';
import { CreditRequestList } from './credit_request_list/credit_request_list';
import { CreditRequest } from './credit_request/credit_request';
import { ABTestCreditRequest } from './credit_request/credit_request-ab-test';
import { ABTestLanding } from './landing/default-ab-test';

// import { setServerErrorHandler } from '../api'

function Account({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  // setServerErrorHandler((error) => {
  //   if (error.status >= 400 && error.status < 500) {
  //     history.push('/auth')
  //   }
  // })

  // useEffect(() => {
  //   IdleMonitor.start({
  //     maxIdleSeconds: 30 * 24 * 60 * 60, // 30 days
  //     finalCountdownSeconds: 60,

  //     onFinalCountdownTick(secondsLeft) {
  //       dispatch(Actions.openModal(ModalType.AutoLogout, { secondsLeft }));
  //     },

  //     onExpire() {
  //       dispatch(Actions.closeModal(ModalType.AutoLogout));
  //       dispatch(Actions.logout()).then(() => history.push('/'));
  //     },
  //   });

  //   return IdleMonitor.stop;
  // }, []);

  return children;
}

function PublicRoute({ path, component, exact, computedMatch: { params } }) {
  const state = useSelector((s) => s);
  return state.authorized && !state.authorizeProcessing ? (
    <Redirect to="/credit-requests" />
  ) : (
    <Route
      exact={exact}
      path={path}
      render={() => React.createElement(component, { match: params })}
    />
  );
}

function PrivateRoute({
  path,
  component,
  exact,
  location,
  computedMatch: { params },
}) {
  const state = useSelector((s) => s);
  return state.authorized ? (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <Account>{React.createElement(component, { match: params })}</Account>
      )}
    />
  ) : (
    <Redirect to={{ pathname: '/auth', state: { from: location } }} />
  );
}

function Routes() {
  const state = useSelector((s) => s);
  return (
    <Switch>
      <PublicRoute path="/auth" component={Auth} />
      {/* <PrivateRoute path="/credit-requests/:id/:itemId" component={Loan} /> */}
      <PrivateRoute path="/credit-requests/:id" component={CreditRequest} />
      <PrivateRoute
        exact
        path="/credit-requests"
        component={CreditRequestList}
      />
      <PrivateRoute path="/agreements" component={Agreements} />
      <PublicRoute exact path="/full-form" component={ABTestLanding} />
      <PublicRoute
        exact
        path="/full-form/credit-request"
        component={ABTestCreditRequest}
      />
      <Route
        exact
        path="/"
        render={() => {
          if (state.authorized) {
            return <CreditRequestList />;
          }

          return <Landing />;
        }}
      />
      {/* <PublicRoute
        exact
        path="/servis-podbora-kreditov/"
        component={AboutPage}
      /> */}
      <Redirect to="/credit-requests" />
    </Switch>
  );
}

function App() {
  const dispatch = useDispatch();
  const didCheckAuthStatus = useSelector((s) => s.didCheckAuthStatus);
  useEffect(() => {
    if (!didCheckAuthStatus) {
      dispatch(Actions.checkAuthStatus());
    }
  }, [dispatch, didCheckAuthStatus]);

  if (!didCheckAuthStatus) {
    return null;
  }
  return (
    <>
      <Routes />
      <Modals />
    </>
  );
}

export default App;

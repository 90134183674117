import React from 'react';

export function LoadingDots() {
  return (
    <div className="loading">
      <div className="item-1" />
      <div className="item-2" />
      <div className="item-3" />
      <div className="item-4" />
    </div>
  );
}

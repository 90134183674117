import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

export class OnClickOutsideContainer extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (ev) => {
    const el = this.container;

    if (el && !el.contains(ev.target)) {
      this.props.onClick();
    }
  };

  render() {
    const className = cn('on-click-outside-container', this.props.className, {
      'is-max-width': this.props.maxWidth,
    });

    return (
      <div
        ref={(ref) => {
          this.container = ref;
        }}
        className={className}
      >
        {this.props.children}
      </div>
    );
  }
}

OnClickOutsideContainer.propTypes = {
  onClick: P.func.isRequired,
  children: P.node.isRequired,
  maxWidth: P.bool,
  className: P.string,
};

OnClickOutsideContainer.defaultProps = {
  maxWidth: false,
  className: '',
};

import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

export function Card(props) {
  const className = cn('card', props.className, {
    [`is-border-${props.borderType}`]: props.borderType !== 'none',
    [`is-bg-${props.backgroundType}`]: props.backgroundType,
    'is-simple': props.simple,
  });

  return <div className={className}>{props.children}</div>;
}

Card.propTypes = {
  className: P.string,
  children: P.node,
  borderType: P.oneOf([
    'none',
    'accent',
    'success',
    'danger',
    'neutral',
    'accentLeft',
  ]),
  backgroundType: P.oneOf(['neutral']),
  simple: P.bool,
};

Card.defaultProps = {
  className: '',
  children: null,
  borderType: 'none',
  backgroundType: null,
  simple: false,
};

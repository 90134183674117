import cn from 'classnames';
import * as R from 'ramda';
import React from 'react';
import P from 'prop-types';

function calcElementPosition(ancestor, el) {
  const elRect = el.getBoundingClientRect();
  const ancestorRect = ancestor.getBoundingClientRect();

  /* eslint-disable no-mixed-operators */
  return elRect.top - ancestorRect.top - ancestorRect.height + elRect.height;
  /* eslint-enable */
}

function outOfBottomBound(ancestor, el) {
  return calcElementPosition(ancestor, el) > 0;
}

function outOfUpperBound(ancestor, el) {
  return ancestor.getBoundingClientRect().top > el.getBoundingClientRect().top;
}

function scrollUp(ancestor, el) {
  /* eslint-disable no-param-reassign */
  ancestor.scrollTop -=
    ancestor.getBoundingClientRect().top - el.getBoundingClientRect().top;
  /* eslint-enable */
}

function scrollDown(ancestor, el) {
  /* eslint-disable no-param-reassign */
  ancestor.scrollTop = 0; // !important
  ancestor.scrollTop = calcElementPosition(ancestor, el);
  /* eslint-enable */
}

function scrollToActiveItem(el) {
  if (el === null || el.parentElement === null) {
    return;
  }

  const parent = el.parentElement;

  if (outOfBottomBound(parent, el)) {
    scrollDown(parent, el);
  } else if (outOfUpperBound(parent, el)) {
    scrollUp(parent, el);
  }
}

function nextDownPosition(cur, max) {
  return cur === max - 1 ? 0 : cur + 1;
}

function nextUpPosition(cur, max) {
  return cur <= 0 ? max - 1 : cur - 1;
}

export class Dropdown extends React.Component {
  static propTypes = {
    /* eslint-disable react/forbid-prop-types */
    items: P.array.isRequired,
    /* eslint-enable */
    onGetItemId: P.func.isRequired,
    onGetItemCaption: P.func.isRequired,
    onGetItemExtraText: P.func,
    onSelect: P.func.isRequired,
    className: P.string,
    emptyCaption: P.node,
    onLeave: P.func,
    open: P.bool,
  };

  static defaultProps = {
    emptyCaption: 'Ничего не найдено',
    onLeave: null,
    open: false,
    className: '',
  };

  constructor(props) {
    super(props);
    this.itemNodes = {};
  }

  state = {
    activeItem: undefined,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyboardNavigation);
  }

  componentWillReceiveProps(nextProps) {
    if (!R.equals(nextProps.items, this.props.items)) {
      this.itemNodes = {};
      this.setState({
        activeItem: undefined,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyboardNavigation);
  }

  handleScrollToActiveItem(calcNextPosFn) {
    const isActiveItem = (item) => this.state.activeItem === item;

    const nextPos = calcNextPosFn(
      R.findIndex(isActiveItem, this.props.items),
      this.props.items.length,
    );

    scrollToActiveItem(this.itemNodes[nextPos]);
    this.setState({
      activeItem: this.props.items[nextPos],
    });
  }

  handleKeyboardNavigation = (ev) => {
    if (!this.props.open) {
      return;
    }

    switch (ev.which) {
      // Arrow down
      case 40: {
        ev.preventDefault();
        this.handleScrollToActiveItem(nextDownPosition);
        break;
      }
      // Arrow up
      case 38: {
        ev.preventDefault();
        this.handleScrollToActiveItem(nextUpPosition);
        break;
      }
      // Enter
      case 13:
        ev.preventDefault();
        ev.stopPropagation();
        this.handleSelect();
        break;

      // Tab or Esc
      case 9:
      case 27:
        if (this.props.onLeave) {
          this.props.onLeave();
        }
        break;
      default:
        break;
    }
  };

  handleItemMouseOver(item) {
    this.setState({
      activeItem: item,
    });
  }

  handleSelect() {
    const { activeItem } = this.state;
    const { onGetItemId } = this.props;

    if (activeItem !== undefined && onGetItemId(activeItem) !== '') {
      this.props.onSelect(activeItem);
    }
  }

  isActive(item) {
    const { onGetItemId } = this.props;

    return (
      this.state.activeItem &&
      onGetItemId(this.state.activeItem) === onGetItemId(item)
    );
  }

  render() {
    if (this.props.open) {
      const { onGetItemId } = this.props;
      const className = cn('dropdown', this.props.className);

      return (
        <div className={className}>
          {this.props.items.length ? (
            this.props.items.map((item, i) => (
              <div
                ref={(node) => {
                  this.itemNodes[i] = node;
                }}
                className={cn('dropdown--item', {
                  'is-active': this.isActive(item),
                })}
                data-fuck={onGetItemId(item)}
                key={onGetItemId(item)}
                onClick={() => this.handleSelect()}
                onMouseOver={() => this.handleItemMouseOver(item)}
                role="presentation"
              >
                {this.props.onGetItemCaption(item)}
                {this.props.onGetItemExtraText && (
                  <div className="dropdown--extra">
                    {this.props.onGetItemExtraText(item)}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="dropdown--empty-caption">
              {this.props.emptyCaption}
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

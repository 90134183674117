import * as R from 'ramda';
import React from 'react';
import P from 'prop-types';
import { FormInput } from './form';
import { RawTextField } from './text_field';
import { Dropdown } from './dropdown';
import { OnClickOutsideContainer } from './on_click_outside_container';
import { DataField } from './data_field';

const debounce = require('lodash/debounce');

export class RawAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    if (props.delay > 0) {
      this.handleGetSuggestions = debounce(
        this.handleGetSuggestions,
        props.delay,
      );
    }
  }

  state = {
    dropdownIsOpen: false,
    items: [],
    textFieldValue: this.getValueCaption(this.props.value),
    shouldShowDropdown: false,
    touched: false,
    isSelected: false,
  };

  componentDidMount() {
    if (this.state.textFieldValue !== '') {
      this.setState({
        isSelected: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value && nextProps.value) {
      if (this.state.textFieldValue === '') {
        this.setState({
          isSelected: false,
          textFieldValue: this.getValueCaption(nextProps.value),
        });
      }
      if (nextProps.value.value !== this.props.value.value) {
        this.setState({
          textFieldValue: this.getValueCaption(nextProps.value),
          isSelected: true,
          touched: false,
        });
      }
    }
  }

  getValueCaption(value) {
    if (R.isNil(value) || R.isEmpty(value)) {
      return '';
    }

    const v = this.props.onGetValueCaption(value);
    if (R.isNil(v)) {
      return '';
    }

    return v;
  }

  handleClickOutside = () => {
    if (this.state.dropdownIsOpen) {
      this.setState(
        {
          shouldShowDropdown: false,
        },
        () => {
          this.handleBlur();
        },
      );
    }
  };

  handleGetSuggestions = (trimmedValue) => {
    const data = this.props.onGetSuggestions(trimmedValue);

    if (data instanceof Array) {
      this.setState({
        dropdownIsOpen: true,
        items: data,
      });
    } else if (typeof data.then === 'function') {
      data.then((items) => {
        if (this.state.shouldShowDropdown) {
          this.setState({
            items,
            dropdownIsOpen: true,
          });
        }
      });
    }
  };

  handleTextFieldChange = (term) => {
    const t = R.isNil(term) ? '' : term;

    if (t.trim().length > 0) {
      this.handleGetSuggestions(t);

      if (this.props.onChange && this.props.allowCustomValue) {
        this.props.onChange(t);
      }
    } else {
      this.setState({
        dropdownIsOpen: false,
      });

      if (this.props.onChange) {
        this.props.onChange(undefined);
      }
    }

    this.setState({
      textFieldValue: term,
      isSelected: false,
    });
  };

  handleSelect = (item) => {
    this.setState(
      {
        textFieldValue: this.getValueCaption(item),
        isSelected: true,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(item);
        }
        this.handleBlur();
      },
    );
  };

  handleFocus = () => {
    if (this.props.disabled) {
      return;
    }

    this.setState(
      {
        shouldShowDropdown: true,
        touched: false,
      },
      () => {
        if (this.props.onFocus) {
          this.props.onFocus();
        }
      },
    );
  };

  handleBlur = () => {
    this.setState(
      {
        dropdownIsOpen: false,
        shouldShowDropdown: false,
        touched: true,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur();
        }
      },
    );
  };

  handleDropdownLeave = () => {
    this.textField.blur();
    this.handleBlur();
  };

  switchErrorLabel = (label) => {
    switch (label) {
      case 'Регион':
      case 'Регион *':
        return 'регион';
      case 'Город или населенный пункт':
      case 'Город или населенный пункт *':
        return 'город';
      case 'Улица':
        return 'улицу';
      case 'Дом':
        return 'номер дома';
      default:
        break;
    }
  };

  getError = () => {
    if (this.state.touched && !this.state.isSelected && this.props.isAddres) {
      return `Необходимо указать ${this.switchErrorLabel(
        this.props.label,
      )} из выпадающего списка`;
    }
    return this.props.error;
  };

  handleTextFieldLeave = () => {
    if (this.state.dropdownIsOpen) return;

    this.setState(
      {
        shouldShowDropdown: false,
      },
      () => {
        this.textField.blur();
        this.handleBlur();
      },
    );
  };

  render() {
    if (this.props.fixed) {
      return (
        <DataField label={this.props.label} value={this.state.textFieldValue} />
      );
    }

    return (
      <OnClickOutsideContainer
        className={this.props.className}
        onClick={this.handleClickOutside}
        maxWidth
      >
        <RawTextField
          onChange={this.handleTextFieldChange}
          hint={this.props.hint}
          error={this.getError()}
          label={this.props.label}
          onFocus={this.handleFocus}
          value={this.state.textFieldValue}
          onBlur={this.handleTextFieldLeave}
          multiline={this.props.multiline}
          noBrowserAutocomplete
          ref={(ref) => {
            this.textField = ref;
          }}
          dropdownSlot={
            <Dropdown
              items={this.state.items}
              onGetItemExtraText={this.props.onGetValueExtraText}
              onGetItemCaption={this.props.onGetSuggestionCaption}
              onGetItemId={this.props.onGetSuggestionId}
              onSelect={this.handleSelect}
              open={this.state.dropdownIsOpen}
              onLeave={this.handleDropdownLeave}
            />
          }
          dataAttrs={this.props.dataAttrs}
        />
      </OnClickOutsideContainer>
    );
  }
}

RawAutocomplete.propTypes = {
  onGetSuggestionId: P.func,
  onGetSuggestions: P.func.isRequired,
  onGetValueCaption: P.func,
  onGetValueExtraText: P.func,
  onGetSuggestionCaption: P.func,
  label: P.node.isRequired,
  hint: P.node,
  className: P.string,
  onChange: P.func,
  allowCustomValue: P.bool,
  value: P.any, // eslint-disable-line react/forbid-prop-types
  onFocus: P.func,
  onBlur: P.func,
  error: P.oneOfType([P.bool, P.string]),
  optional: P.bool,
  placeholder: P.string,
  disabled: P.bool,
  readOnly: P.bool,
  multiline: P.bool,
  delay: P.number,
  dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
  fixed: P.bool,
};

RawAutocomplete.defaultProps = {
  onGetSuggestionId: R.identity,
  onGetValueCaption: R.identity,
  onGetSuggestionCaption: R.identity,
  onGetValueExtraText: null,
  className: '',
  onChange: null,
  allowCustomValue: false,
  value: null,
  onFocus: null,
  onBlur: null,
  error: false,
  optional: false,
  placeholder: '',
  disabled: false,
  readOnly: false,
  multiline: false,
  delay: 0,
  dataAttrs: null,
  fixed: false,
};

export function Autocomplete(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue, setTouched, getTouched, getError }) => (
        <RawAutocomplete
          {...rest}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
          onBlur={() => {
            setTouched();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
        />
      )}
    </FormInput>
  );
}

Autocomplete.propTypes = R.merge(RawAutocomplete.propTypes, {
  field: P.string.isRequired,
});

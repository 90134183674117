import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import { getDataAttrs } from './utils';

export function Link(props) {
  const className = cn('link', props.className, {
    'is-disabled': props.disabled,
  });

  return (
    <a
      className={className}
      href={props.href}
      onClick={props.onClick}
      {...getDataAttrs(props.dataAttrs)}
    >
      {props.children}
    </a>
  );
}

Link.propTypes = {
  children: P.node,
  href: P.string,
  disabled: P.bool,
  onClick: P.func,
  className: P.string,
  dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
};

Link.defaultProps = {
  children: null,
  href: null,
  disabled: false,
  onClick: null,
  className: '',
  dataAttrs: null,
};

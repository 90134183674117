/* eslint-disable react/no-multi-comp, no-console */
import React from 'react';
import P from 'prop-types';
import * as R from 'ramda';
import SuperAgent from 'superagent';
import { FormInput } from './form';
import { Button } from './button';

export class RawImageUploader extends React.Component {
  static propTypes = {
    label: P.string,
    uploadUrl: P.string,
    previewUrl: P.func,
    metaUrl: P.func,
    accept: P.string,
    value: P.oneOfType([P.string, P.number]),
    onChange: P.func,
    error: P.string,
    // This function must return object: {name, size, width, height}
    parseMetaResponse: P.func,
    parseSuccessResponse: P.func,
    parseFailureRepsonse: P.func,
    sizeLimit: P.number,
  };

  static defaultProps = {
    label: null,
    uploadUrl: null,
    previewUrl: null,
    metaUrl: null,
    accept: null,
    value: null, // This is file ID
    onChange: null,
    error: null,
    parseMetaResponse: null,
    parseSuccessResponse: null,
    parseFailureRepsonse: null,
    sizeLimit: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: -1,
      meta: null,
      preview: null,
      error: props.error,
    };

    this.reader = new window.FileReader();
    this.image = new window.Image();

    this.reader.onload = (ev) => {
      this.image.onload = () => {
        this.setState({
          meta: R.merge(this.state.meta, {
            width: this.image.width,
            height: this.image.height,
          }),
        });
      };

      this.image.src = ev.target.result;

      this.setState({
        preview: ev.target.result,
      });
    };
  }

  componentDidMount() {
    if (this.props.value) {
      if (this.props.previewUrl) {
        this.setState({
          // eslint-disable-line
          preview: this.props.previewUrl(this.props.value),
        });
      }

      if (this.props.metaUrl) {
        SuperAgent('get', this.props.metaUrl(this.props.value))
          .withCredentials()
          .then(({ body }) => {
            const meta = this.props.parseMetaResponse(body);
            this.setState({ meta });
          });
      }
    }
  }

  readFileForPreview = (file) => {
    this.reader.readAsDataURL(file);
  };

  isSizeValid(size) {
    return this.props.sizeLimit <= 0 ? true : size <= this.props.sizeLimit;
  }

  startUpload(file) {
    if (!this.props.uploadUrl) {
      return;
    }

    SuperAgent('post', this.props.uploadUrl)
      .withCredentials()
      .attach('file', file)
      .on('progress', (ev) => {
        this.setState({
          uploadProgress: ev.percent,
        });
      })
      .then(this.handleSuccessUpload, this.handleFailureUpload);
  }

  handleSuccessUpload = (res) => {
    this.setState(
      {
        uploadProgress: -1,
      },
      () => {
        if (this.props.onChange) {
          const value = this.props.parseSuccessResponse
            ? this.props.parseSuccessResponse(res.body)
            : res.body;
          this.props.onChange(value);
        }
      },
    );
  };

  handleFailureUpload = (res) => {
    const err = this.props.parseFailureRepsonse
      ? this.props.parseFailureRepsonse(res)
      : res;
    // Do something with err
    console.log(err);
  };

  handleSelectFileDialog = () => {
    this.fileInput.click();
  };

  handleInputChange = () => {
    this.handleSelectFile(this.fileInput.files[0]);
  };

  handleSelectFile = (file) => {
    if (!this.isSizeValid(file.size)) {
      this.setState({
        error: 'Invalid size',
      });

      return;
    }

    this.setState(
      {
        meta: {
          name: file.name,
          size: file.size,
        },
      },
      () => {
        this.readFileForPreview(file);
        this.startUpload(file);
      },
    );
  };

  handleDetachFile = () => {
    this.setState(
      {
        meta: null,
        preview: null,
        uploadProgress: -1,
        error: null,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(null);
        }
      },
    );
  };

  renderPreviewButton() {
    return (
      <div>
        {this.state.preview ? (
          <img
            src={this.state.preview}
            style={{ width: '80px', height: '80px' }}
            alt=""
          />
        ) : (
          <div
            onClick={this.handleSelectFileDialog}
            style={{
              width: '100px',
              height: '100px',
              background: 'yellow',
            }}
            role="presentation"
          >
            Icon
          </div>
        )}
        {this.state.meta ? (
          <Button onClick={this.handleDetachFile}>Remove file</Button>
        ) : null}
      </div>
    );
  }

  renderLabel() {
    if (!this.props.label) {
      return null;
    }

    return <div>Label: {this.props.label}</div>;
  }

  renderUploadProgress() {
    if (this.state.uploadProgress < 0) {
      return null;
    }

    return <div>Progress: {this.state.uploadProgress}</div>;
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return <div>{this.state.error}</div>;
  }

  renderMeta() {
    if (!this.state.meta) {
      return null;
    }

    return (
      <div>
        Name: {this.state.meta.name}, Size: {this.state.meta.size}, Dim:{' '}
        {!R.isNil(this.state.meta.width) && !R.isNil(this.state.meta.height)
          ? `${this.state.meta.width} x ${this.state.meta.height}`
          : null}
      </div>
    );
  }

  renderInput() {
    return (
      <input
        ref={(ref) => {
          this.fileInput = ref;
        }}
        type="file"
        accept={this.props.accept}
        onChange={this.handleInputChange}
        hidden
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderInput()}
        {this.renderPreviewButton()}
        {this.renderLabel()}
        {this.renderMeta()}
        {this.renderUploadProgress()}
        {this.renderError()}
      </div>
    );
  }
}

export function ImageUploader(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue, getTouched, getError }) => (
        <RawImageUploader
          {...rest}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

ImageUploader.propTypes = R.merge(RawImageUploader.propTypes, {
  field: P.string.isRequired,
});

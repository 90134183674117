import cn from 'classnames';
import P from 'prop-types';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Icon,
  NewSlider,
  Row,
  Text,
  TextField,
} from '../../base';
import { getLoanAmountOrDefault } from '../../domain';
import * as Format from '../../helpers/format_helpers';
import { formatBigMoney } from '../../helpers/format_helpers';
import { isEmpty, noError, isConfirmationCode } from '../../validation';
import { WidgetTypes } from '../credit_request/application/application_form';
import { abPublickStep } from '../credit_request/application/steps';

const initialValue = {
  lastName: undefined,
  firstName: undefined,
  middleName: undefined,
  birthDate: undefined,
  email: undefined,
  creditRegion: undefined,
  creditCity: undefined,
};
export function ABTestFirstStepForm({
  isSubmitPending,
  calcLocation,
  defaultValues: defaultVal,
  onFieldChange,
  onSubmit,
  calcProps,
}) {
  const state = useSelector((s) => s);
  const { minAmount, maxAmount, amountStep } = calcProps;
  const handleSubmit = (values) => {
    const res = onSubmit(values);
    return res;
  };
  const handleFieldChange = (value, field) => {
    if (onFieldChange) {
      onFieldChange(value, field);
    }
  };

  const defaultValues = defaultVal || {};

  const getFieldValidator = (field) => {
    return field.validate || (field.optional ? () => noError : isEmpty);
  };

  const handleValidate = (values) => {
    const validate = R.reduce(
      (errors, field) =>
        R.assoc(
          field.name,
          getFieldValidator(field)(values[field.name], {
            required: !field.optional,
          }),
          errors,
        ),
      {},
      abPublickStep[1]().fields(values),
    );

    const validate2 = {
      ...validate,
      registrationSms: state.abBasicFormSended
        ? validate.registrationSms
        : undefined,
    };
    return validate2;
  };
  const renderField = (field, i) => {
    const isHidden = field.widgetOptions.hidden;
    const widgetComponent = WidgetTypes[field.type];
    const widget = React.createElement(
      widgetComponent,
      R.mergeDeepRight(field.widgetOptions, {
        field: field.name,
        optional: field.optional,
        fixed: field.fixed,
        onChange: handleFieldChange(field),
        dataAttrs: { id: field.name, stat: '1', scroll: `${field.name}-${i}` },
      }),
    );
    if (isHidden) {
      return null;
    }

    return (
      <Row className="u-mb-2">
        <Col>
          {field.beforeDescription}
          {widget}
        </Col>
      </Row>
    );
  };

  const isCabinet = useMemo(() => calcLocation === 'cabinet', [calcLocation]);

  const formClassName = cn('public-form', 'u-mt-3', 'u-mt-4-lg', {
    'is-cabinet': isCabinet,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      validate={handleValidate}
      defaultValues={{
        amount: getLoanAmountOrDefault(
          minAmount,
          maxAmount,
          defaultValues.amount,
        ),
        ...initialValue,
      }}
    >
      {({ values }) => {
        return (
          <div className={formClassName}>
            <div className="public-form__slider">
              <Row className="u-mb-2-md">
                <Col>
                  <NewSlider
                    size="large"
                    titleDirection="column"
                    calcLocation={calcLocation}
                    captionStart="₽"
                    field="amount"
                    step={amountStep}
                    min={minAmount}
                    max={maxAmount}
                    getValueCaption={(val) =>
                      formatBigMoney(val, { withoutCurrency: false })
                    }
                    minValueCaption={minAmount}
                    maxValueCaption={maxAmount}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Row>

              <Row className="u-mb-2-md landing-form__info landing-form--mobile-hide">
                <Col>
                  <div className="landing-form--description">
                    <div className="landing-form--points">
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Заполните заявку онлайн, это займет не более 5 минут
                        </div>
                      </div>
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Finspin сравнит предложения банков и подберет для вас
                          лучшее предложение
                        </div>
                      </div>
                      <div className="landing-form--point">
                        <div className="landing-form--point-icon">
                          <Icon name="complete-icon" />
                        </div>
                        <div className="landing-form--point-text">
                          Никаких дополнительных сборов и комиссий
                        </div>
                      </div>
                    </div>

                    <div className="landing-form--safety">
                      <div className="landing-form--safety-icon">
                        <Icon name="lock-icon" />
                      </div>
                      <div className="landing-form--safety-text">
                        Мы работаем в строгом соответствии с законодательством
                        РФ. Здесь и далее ваши данные надежно защищены.
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="public-form__form">
              {React.createElement(
                'div',
                { className: 'calc__form' },
                ...abPublickStep[1]()
                  .fields(state.abBasicFormSended)
                  .map(renderField),
              )}
              {state.abBasicFormSended &&
                (state.auth.timer > 0 ? (
                  <div className="u-mt-1">
                    <Text type="body-2" color="secondary" inline>
                      SMS с кодом подтверждения отправлено.
                    </Text>
                    <Text
                      type="body-2"
                      color="secondary"
                      className="SEO_SMS_ERROR_MSG"
                    >
                      Доставка сообщений абонентам МТС затруднена и может
                      осуществляться с задержкой.
                    </Text>{' '}
                    <Text type="body-2" color="secondary" inline>
                      Запросить новый код можно через:
                    </Text>
                    <Text type="body-2" inline>
                      {Format.formatTimer(state.auth.timer)}
                    </Text>
                  </div>
                ) : (
                  <div className="u-mt-1">
                    <Text type="body-2" color="secondary" inline>
                      SMS с кодом подтверждения отправлено.{' '}
                    </Text>
                    <Text
                      type="body-2"
                      color="secondary"
                      inline
                      className="SEO_SMS_ERROR_MSG"
                    >
                      Доставка сообщений абонентам МТС затруднена и может
                      осуществляться с задержкой.
                    </Text>{' '}
                    {/* <Text type="body-2" inline>
                    <Link onClick={handleResendCode}>Выслать код повторно</Link>
                  </Text> */}
                  </div>
                ))}

              <Row hStart vCenter className="u-mb-2 u-mt-3 u-mt-4-lg">
                <Col className="u-mb-0-md u-mb-2">
                  <Button
                    type="submit"
                    maxWidth
                    size={isCabinet ? 'md' : 'lg'}
                    isPending={isSubmitPending}
                  >
                    Подобрать кредит
                  </Button>
                </Col>
              </Row>
            </div>
            <Row className="u-mb-2-md landing-form__info landing-form--desktop-hide">
              <Col>
                <div className="landing-form--description">
                  <div className="landing-form--points">
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Заполните заявку онлайн, это займет не более 5 минут
                      </div>
                    </div>
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Finspin сравнит предложения банков и подберет для вас
                        лучшее предложение
                      </div>
                    </div>
                    <div className="landing-form--point">
                      <div className="landing-form--point-icon">
                        <Icon name="complete-icon" />
                      </div>
                      <div className="landing-form--point-text">
                        Никаких дополнительных сборов и комиссий
                      </div>
                    </div>
                  </div>

                  <div className="landing-form--safety">
                    <div className="landing-form--safety-icon">
                      <Icon name="lock-icon" />
                    </div>
                    <div className="landing-form--safety-text">
                      Мы работаем в строгом соответствии с законодательством РФ.
                      Здесь и далее ваши данные надежно защищены.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
      }}
    </Form>
  );
}

ABTestFirstStepForm.propTypes = {
  calcLocation: P.oneOf(['cabinet', 'landing']),
  // minAmount: P.number.isRequired,
  // maxAmount: P.number.isRequired,
  // amountStep: P.number.isRequired,
  // buttonCaption: P.string,
  onSubmit: P.func.isRequired,
  isSubmitPending: P.bool,
  // defaultValues: P.shape({
  //   term: P.number,
  //   amount: P.number,
  // }),
  onFieldChange: P.func,
};

ABTestFirstStepForm.defaultProps = {
  isSubmitPending: false,
  // defaultValues: null,
  onFieldChange: null,
  // calcProps: null,
};

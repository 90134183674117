import React, { useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { useMedia } from 'react-use';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ModalType } from '../../modals/modal_type';
import { changePage } from '../../../metrics';
import { AgreementVendor, AgreementName } from '../../../domain';
import { Actions } from '../../state';
import { NewHeader, Footer } from '../default';
import { Link } from '../../../base';

moment.locale('ru-RU');

function Features({ onOpenAgreement }) {
  const featuresList = useMemo(
    () => [
      {
        title: 'Все кредиторы в одном месте',
        text: (
          <div className="text">
            Кому срочно нужна сумма до 30 000 ₽: нет времени ходить по кредитным
            организациям и собирать справки. Finspin подберет и одобрит займ за
            несколько минут.
          </div>
        ),
      },
      {
        title: 'Получить деньги онлайн',
        text: (
          <div className="text">
            Кто хочет сравнить разные предложения кредиторов. Наш сервис покажет
            несколько уже одобренных вариантов и условия кредитования (сумму,
            срок, процентную ставку).
          </div>
        ),
      },
      {
        title: 'Только телефон и паспорт',
        text: (
          <div className="text">
            Кто дорожит персональными данными и не готов оставлять много заявок
            на разных сайтах. Мы передаем контактные данные только тому
            кредитору, у которого вы решите взять кредит. Почитайте нашу{' '}
            <Link onClick={onOpenAgreement}>
              политику обработки персональных данных
            </Link>
            .
          </div>
        ),
      },
      {
        title: 'Полная прозрачность и безопасность',
        text: (
          <div className="text">
            Кому сложно найти займ в других организациях. Мы работаем сразу с
            несколькими кредиторами — с разными требованиями к заемщикам. Одному
            кредитору нужна идеальная кредитная история, другому — нет. Поэтому
            в нашем сервисе вы с большой вероятностью получите одобрение.
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <div className="about-page__features">
      <div className="title-2">Кто пользуется нашим сервисом</div>
      <div className="feature__list">
        {featuresList.map(({ title, text }, i) => (
          <div className="feature" key={i}>
            <div className="feature__icon">
              <div className={`feature__img feature__img--${i + 1}`}>
                {i + 1}
              </div>
            </div>
            {/* <div className='subtitle feature__subtitle'>{title}</div> */}
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}

export function AboutPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    changePage('about');
  }, []);

  function onSignIn() {
    dispatch(Actions.setWantedCreditConditions(null, null));
    history.push('/auth');
  }

  function onOpenAgreement() {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor: AgreementVendor.Finspin,
        agreement: AgreementName.ProcessingPersonalDataPolicy,
      }),
    );
  }

  return (
    <div className="l-land">
      <div className="about-page">
        <div className="bg-about-1">
          <NewHeader onLogin={onSignIn} />

          <div className="l-content">
            <div className="about">
              <div className="about__content">
                <h1 className="title-1 about__title">
                  Сервис подбора <br /> займов Finspin
                </h1>
                <div className="text about__description">
                  Finspin подберет для вас займ, кредит и кредитную карту среди
                  проверенных кредиторов, состоящих в реестре Центробанка.
                  Оставьте на сайте всего одну заявку и получите несколько
                  одобренных предложений: выберите лучший вариант и заберите
                  деньги. Сервис бесплатный — без комиссий и платежей.
                </div>
              </div>
              <div className="about__logo">
                <div className="about__logo-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="l-content">
          <Features onOpenAgreement={onOpenAgreement} />
        </div>
        <div className="bg-about-2 ">
          <div className="l-content">
            <div className="get-money">
              <div className="title-2 get-money__title">
                Получите деньги онлайн за <span>10 минут</span>
                <br />
                Нужен только телефон и паспорт
              </div>
              <div>
                <button
                  className="btn__about get-money__btn"
                  onClick={onSignIn}
                >
                  Получить деньги
                </button>
              </div>
            </div>
          </div>
        </div>
        <Creditors />
      </div>

      <Footer onOpenAgreement={onOpenAgreement} />
    </div>
  );
}

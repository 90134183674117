import * as R from 'ramda';
import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import InputMask from 'react-input-mask';
import { FormInput } from './form';
import { getDataAttrs } from './utils';
import { DataField } from './data_field';

export class RawTextField extends React.Component {
  static propTypes = {
    label: P.oneOfType([P.string, P.number]).isRequired,
    hint: P.string,
    hintAlways: P.bool,
    hidden: P.bool,
    className: P.string,
    dataAttrs: P.object,
    onChange: P.func,
    value: P.oneOfType([P.string, P.number]),
    onFocus: P.func,
    onBlur: P.func,
    error: P.oneOfType([P.string, P.bool]),
    type: P.oneOf([
      'text',
      'number',
      'tel',
      'password',
      'url',
      'search',
      'email',
    ]),
    optional: P.bool,
    placeholder: P.string,
    disabled: P.bool,
    readOnly: P.bool,
    maskChar: P.string,
    mask: P.string,
    dropdownSlot: P.node,
    multiline: P.bool,
    withoutErrorSign: P.bool,
    noBrowserAutocomplete: P.bool,
    autocomplete: P.string,
    fixed: P.bool,
  };

  static defaultProps = {
    value: '',
    hidden: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
    dataAttrs: null,
    error: false,
    type: 'text',
    optional: false,
    placeholder: '',
    disabled: false,
    readOnly: false,
    mask: '',
    maskChar: '_',
    dropdownSlot: null,
    multiline: false,
    withoutErrorSign: false,
    className: '',
    autocomplete: null,
    noBrowserAutocomplete: false,
    fixed: false,
  };

  state = {
    focused: false,
  };

  // position = null

  componentDidMount() {
    this.correctHeight();
  }

  componentDidUpdate() {
    // const index = this.position
    // if (this.position) {
    //   this.input.setSelectionRange(index, index)
    //   this.position = null
    // }
    this.correctHeight();
  }

  getPlaceholder = () => {
    if (!this.props.placeholder) {
      return '';
    }
    if (
      this.state.focused &&
      !R.isNil(this.props.value) &&
      this.props.value !== ''
    ) {
      return '';
    }
    if (!this.state.focused) {
      return '';
    }

    return this.props.placeholder;
  };

  getInputElement() {
    if (this.input) {
      return this.input;
    }
    if (this.inputMask) {
      return ReactDOM.findDOMNode(this.inputMask);
    }
    if (this.textarea) {
      return this.textarea;
    }
    return null;
  }

  getValue() {
    return R.isNil(this.props.value) ? '' : this.props.value;
  }

  handleChange = (ev) => {
    if (this.props.onChange) {
      // this.position = ev.target.selectionStart
      this.props.onChange(ev.target.value);
    }
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleBlur = () => {
    this.setState({
      focused: false,
    });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleControlClick = () => {
    if (this.state.focused) {
      return;
    }

    this.getInputElement().focus();
  };

  correctHeight() {
    if (this.textarea) {
      const el = this.textarea;
      el.style.height = 'auto';
      el.style.height = `${el.scrollHeight}px`;
    }
  }

  /**
   * Public api
   */
  blur() {
    this.getInputElement().blur();
  }

  /**
   * Public api
   */
  focus() {
    this.getInputElement().focus();
  }

  renderControl() {
    const placeholder = this.getPlaceholder();
    const type = this.props.type || 'text';
    const value = this.getValue();

    if (this.props.mask) {
      return (
        <InputMask
          className="text-field--input"
          value={value}
          mask={this.props.mask}
          maskChar={this.props.maskChar}
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          type={type}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          autoComplete={
            R.isNil(this.props.autocomplete)
              ? (!this.props.noBrowserAutocomplete).toString()
              : this.props.autocomplete
          }
          ref={(ref) => {
            this.inputMask = ref;
          }}
          {...getDataAttrs(this.props.dataAttrs)}
        />
      );
    }

    if (this.props.multiline) {
      return (
        <textarea
          className="text-field--textarea"
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          rows={1}
          autoComplete={(!this.props.noBrowserAutocomplete).toString()}
          ref={(ref) => {
            this.textarea = ref;
          }}
          {...getDataAttrs(this.props.dataAttrs)}
        />
      );
    }

    return (
      <input
        className="text-field--input"
        value={value}
        placeholder={placeholder}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        type={type}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        autoComplete={(!this.props.noBrowserAutocomplete).toString()}
        ref={(ref) => {
          this.input = ref;
        }}
        {...getDataAttrs(this.props.dataAttrs)}
      />
    );
  }

  render() {
    if (this.props.fixed) {
      return (
        <DataField
          label={this.props.label}
          value={this.getValue()}
          error={this.props.error}
          dataAttrs={this.props.dataAttrs}
        />
      );
    }

    const className = cn('text-field', this.props.className, {
      'is-disabled': this.props.disabled,
      'is-error': Boolean(this.props.error),
      'is-filled':
        !R.isNil(this.props.value) &&
        ((typeof this.props.value === 'string' &&
          this.props.value.length > 0) ||
          typeof this.props.value === 'number'),
      'is-focused': this.state.focused,
      'is-no-error-sign': this.props.withoutErrorSign,
    });

    return (
      <div className={className}>
        <div
          className="text-field--control"
          onClick={this.handleControlClick}
          role="presentation"
        >
          {this.renderControl()}
          <div className="text-field--label">{this.props.label}</div>
        </div>
        {this.props.hint && (
          <div
            className={`text-field--hint ${
              this.props.hintAlways ? 'is-always' : ''
            }`}
          >
            {this.props.hint}
          </div>
        )}
        {this.props.dropdownSlot ? (
          <div className="text-field--dropdown-container">
            <div className="text-field--dropdown">
              {this.props.dropdownSlot}
            </div>
          </div>
        ) : null}
        {typeof this.props.error === 'string' ? (
          <div className="text-field--error-message">{this.props.error}</div>
        ) : null}
      </div>
    );
  }
}

export function TextField(props) {
  const { field, ...rest } = props;
  return (
    <FormInput field={field}>
      {({ setValue, getValue, setTouched, getTouched, getError }) => (
        <RawTextField
          {...rest}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
          onBlur={() => {
            setTouched();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
        />
      )}
    </FormInput>
  );
}

TextField.propTypes = R.merge(RawTextField.propTypes, {
  field: P.string.isRequired,
});

import cn from 'classnames';
import moment from 'moment';
import P from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Icon, Link, NewCalcPlaceholder, Text } from '../../base';
import { AgreementName, AgreementVendor } from '../../domain';
import { formatPhone, parsePhone } from '../../helpers/format_helpers';
import { changePage } from '../../metrics';
import { ModalType } from '../modals/modal_type';
import { ABTestFirstStepForm } from '../shared/first_step_form-ab-test';
import { Actions } from '../state';
import { NewHeader } from './default';

moment.locale('ru-RU');

function HowItWorks() {
  return (
    <div className="l-work SEO-HOW-IT-WORKS">
      <div className="l-content">
        <h2 className="title-1 l-work__header">
          Подбор предложения <span className="no-wrap">за 5 минут</span>
        </h2>
        <div className="l-work__sub-title">Как это работает</div>
        <div className="l-work__steps">
          <div className="l-work__step step">
            <div className="step__image step__image--1" />
            <div className="step__content">
              <div className="title-3">Заполните анкету онлайн</div>
              <p>Нужен только паспорт и 5 минут вашего времени</p>
            </div>
          </div>
          <div className="l-work__arrow">
            <Icon name="arrow-right-icon-lg" />
          </div>
          <div className="l-work__step step">
            <div className="step__image step__image--2" />
            <div className="step__content">
              <div className="title-3">Выберите предложение</div>
              <p>
                После заполнения анкеты мы обратимся к нашим банкам-партнерам и
                найдем самые подходящие продукты с максимальной вероятностью
                одобрения
              </p>
            </div>
          </div>
          <div className="l-work__arrow">
            <Icon name="arrow-right-icon-lg" />
          </div>
          <div className="l-work__step step">
            <div className="step__image step__image--3" />
            <div className="step__content">
              <div className="title-3">Оформите кредит</div>
              <p>
                После того, как мы найдем для вас подходящие предложения,
                останется только дождаться звонка оператора банка
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function scrollToCulc() {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

const paymentLogos = [
  'visa',
  'mastercard',
  'maestro',
  'mir',
  'yandex',
  'qiwi',
  'contact',
];

function PaymentMethods() {
  return (
    <div className="l-payment SEO-PAYMENT-METHODS">
      <div className="l-content">
        <div className="logos">
          {paymentLogos.map((logo) => (
            <div key={logo} className="logos__item">
              <div className={`logos__${logo} logos__logo`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function LoanForm({ calc, onSubmit, pending }) {
  return (
    <div className="l-base-loan">
      <h1 className="l-base-loan__title title-1">
        Подберем кредитную карту или кредит{' '}
        <span className="no-wrap">на лучших</span> условиях
      </h1>
      {calc ? (
        <>
          <ABTestFirstStepForm
            onSubmit={onSubmit}
            calcProps={calc}
            isSubmitPending={pending}
            calcLocation="landing"
          />
        </>
      ) : (
        <NewCalcPlaceholder />
      )}
    </div>
  );
}

LoanForm.defaultProps = {
  calc: null,
};

LoanForm.propTypes = {
  onSubmit: P.func.isRequired,
  calc: P.object,
};

export function Footer({ onOpenAgreement }) {
  return (
    <div className="l-footer">
      <div className="l-content">
        <div className="footer">
          <div className="footer__side">
            <div className="footer__logo" />
            <div className="footer__copyright">{`© 2016-${new Date().getFullYear()} ООО «Финспин»`}</div>
            <div className="footer__agreements">
              <Text color="secondary" type="caption" className="u-mt-1">
                <Link onClick={onOpenAgreement}>
                  Политика по обработке персональных данных
                </Link>
              </Text>
            </div>
          </div>
          <div className="footer__info">
            <div className="footer__section">
              <div className="footer__slogan">
                Подбираем кредитные карты и кредиты на любые цели. Кредитный
                лимит до 3 млн ₽. Заявка онлайн.
              </div>
              <div className="footer__phone">
                <div className="footer__phone--number">
                  <Link href={`tel:8${process.env.SUPPORT_PHONE}`}>
                    {formatPhone(process.env.SUPPORT_PHONE, {
                      firstDigit: '8',
                    })}
                  </Link>
                </div>
                <div className="footer__phone--text">
                  С 9:00 до 18:00 по московскому времени звонок по России
                  бесплатный
                </div>
              </div>
              <div className="footer__email">
                <div className="footer__email--text">Email:</div>
                <div className="footer__email--email">
                  <Link href="mailto:support@finspin.ru">
                    support@finspin.ru
                  </Link>
                </div>
              </div>
            </div>

            <div className="footer__about">
              Общество с ограниченной ответственностью «Финспин». Юридический
              адрес: 620075, г. Екатеринбург, ул. Мамина-Сибиряка, д. 101, офис
              8.10. ОГРН 1146670012444, ИНН 6670424655. ООО «Финспин»
              сотрудничает только с надлежащим образом зарегистрированными
              финансовыми организациями и банками, состоящими в государственном
              реестре ЦБ РФ.
            </div>
          </div>
        </div>
        <div className="footer__warning u-mt-2 u-pb-2">
          Если просрочить платеж по кредиту от одного до трех дней, многие банки
          закроют на это глаза. Такие просрочки часто случаются из-за
          технических сбоев, например, задержка банковского перевода. А вот
          более долгие просрочки караются санкциями: штрафами, занесением в
          реестр должников, испорченной кредитной историей. За просрочку платежа
          по потребительскому кредиту или кредитной карте средний размер штрафа
          составляет ~0,03% в день от суммы долга. При длительном нарушении
          графика платежей по кредитам, данные заемщика могут попасть в реестр
          должников, а долг — коллекторам.
          <br />
          Рекомендуем платить за кредиты вовремя — по графику, установленному
          кредитным договором. Исправно выплачиваемый кредит формирует хорошую
          кредитную историю и делает вас образцовым заемщиком для кредитных
          организаций. Это откроет вам доступ к лучшим условиям кредитования —
          крупным суммам по минимальным ставкам.
          <br />
        </div>
        <div className="footer__warning">
          <strong>Пример расчета кредита:</strong> <br />
          Если вы хотите взять 400 000 ₽ и на три года под 11,9% годовых,
          итоговый платеж составит 477 612 ₽, из которых 77 612 ₽ — проценты. На
          текущий момент минимальная ставка по потребительскому кредиту и
          кредитной карте составляет 7%, максимальная — 18,9%. Срок погашения от
          полугода до семи лет. При нарушении сроков платежей неустойка составит
          0,10% от суммы просрочки в день, но не более 10% от суммы кредита.
          Длительная просрочка влечет за собой передачу данных в бюро кредитных
          историй, что приведет к ухудшению кредитной истории заемщика <br />
          Мы не являемся кредитной организацией и не занимаемся кредитованием.
          Мы только предоставляем информацию о рынке кредитования.
        </div>
      </div>
    </div>
  );
}

const creditorsList = [
  {
    name: 'АО «Альфа-Банк»',
    inn: '7728168971',
    ogrn: '1027700067328',
    regNumb: '1326',
  },
  {
    name: 'АО «Почта-Банк»',
    inn: '3232005484',
    ogrn: '1023200000010',
    regNumb: '1500010',
  },
  {
    name: 'АО «Тинькофф Банк»',
    inn: '7710140679',
    ogrn: '1027739642281',
    regNumb: '2673',
  },
  {
    name: 'АО “ОТП БАНК”',
    inn: '7708001614',
    ogrn: '027739176563',
    regNumb: ' 2766',
  },
  {
    name: 'ПАО «Московский Кредитный Банк»',
    inn: '7734202860',
    ogrn: '1027739555282',
    regNumb: '1500010',
  },
  {
    name: 'ПАО КБ «Восточный»',
    inn: '2801015394',
    ogrn: '1022800000112',
    regNumb: '1460',
  },
  {
    name: 'АО КБ «Ситибанк»',
    inn: '7710401987',
    ogrn: '1027700431296',
    regNumb: '2557',
  },
  {
    name: 'АО «Райффайзенбанк»',
    inn: '7744000302',
    ogrn: '1027739326449',
    regNumb: '3292',
  },
  {
    name: 'ПАО Росбанк',
    inn: '7730060164',
    ogrn: '1027739460737',
    regNumb: '2272',
  },
];

function Creditor({ name, ogrn, regNumb, inn }) {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMedia('(max-width: 670px)', true);

  const toggleBtn = useCallback(() => {
    if (isMobile) {
      setIsOpen((s) => !s);
    }
  }, [isMobile]);

  const className = cn('creditor', {
    'is-open': isOpen,
  });

  useEffect(() => {
    setIsOpen(false);
  }, [isMobile]);

  return (
    <div className="creditor__wrapper">
      <div className={className}>
        <div className="creditor__main" onClick={toggleBtn}>
          <div className="creditor__name">{name}</div>

          <div className="creditor__icon creditor__plus" />
        </div>
        <div className="creditor__more">
          <div className="creditor__info">
            <div>ИНН:</div>
            <div>{inn}</div>
          </div>
          <div className="creditor__info">
            <div>ОГРН:</div>
            <div>{ogrn}</div>
          </div>
          <div className="creditor__info">
            <div>Рег. номер:</div>
            <div>{regNumb}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const countToShow = 4;

function Creditors() {
  const [showMore, setShowMore] = useState(false);
  const isMobile = useMedia('(max-width: 670px)', true);
  const arrLength = useMemo(() => creditorsList.length, []);

  const onShowMoreCreditors = useCallback(() => {
    setShowMore(true);
  }, []);

  const howMatchShow = useMemo(() => {
    if (isMobile) {
      return showMore ? arrLength : countToShow;
    }
    return arrLength;
  }, [isMobile, showMore, arrLength]);

  return (
    <div className="l-content">
      <div className="creditors">
        <div className="creditors__content">
          <div className="title-1 creditors__title">
            Список банков-партнеров
          </div>
        </div>
        <div className="creditors__list">
          {creditorsList.slice(0, howMatchShow).map((cred) => {
            return <Creditor {...cred} key={cred.ogrn} />;
          })}
          {creditorsList.length % 3 === 2 && (
            <div className="creditor__wrapper u-hidden-lt-md">{}</div>
          )}
        </div>
        {isMobile && !showMore && (
          <div className="creditors__show-more">
            <button
              type="button"
              className="btn btn__outline"
              onClick={onShowMoreCreditors}
            >
              Показать весь список
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// const PDLInfoCard = () => {
//   return (
//     <div className="pdl-card">
//       <div className="pdl-card__img-container">
//         <div className="pdl-card__img" />
//       </div>
//       <div className="pdl-card__content">
//         <div className="l-text-1">
//           Если вас интересует небольшая сумма на короткий срок, воспользуйтесь
//           нашим сервисом — <strong>подбором моментальных займов</strong>
//         </div>
//         <button type="button" className="btn--primary">
//           Подробнее
//         </button>
//       </div>
//     </div>
//   );
// };

function CentralBank() {
  return (
    <div className="bg-4">
      <div className="l-content">
        <div className="central-bank">
          <div className="central-bank__img-container">
            <div className="central-bank__img" />
          </div>
          <div className="title-1 central-bank__title">
            Проверено Центробанком
          </div>

          <div className="l-text-1 central-bank__text">
            Мы сотрудничаем только с проверенными кредиторами.
            <br /> Каждый из них состоит в реестре ЦБ РФ и работает с
            соблюдением всех нормативных актов и предписаний.
          </div>
        </div>
      </div>
    </div>
  );
}

const GetCreditNow = () => {
  return (
    <>
      <div className="bg-3 ">
        <div className="l-content">
          <div className="get-credit-now">
            {/* <PDLInfoCard /> */}
            <div className="title-1 get-credit-now__title">
              Мы готовы подобрать кредитное предложение для вас прямо сейчас!
            </div>
            <div className="get-credit-now__info l-text-1">
              Заполните анкету и получите предложение всего за 5 минут
            </div>
            <div className="get-credit-now__btn">
              <button
                type="button"
                className="btn--primary"
                onClick={scrollToCulc}
              >
                Получить деньги
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export function ABTestLanding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((s) => s);

  useEffect(() => {
    if (!state.newLoanOptions) {
      dispatch(Actions.fetcCreditConditions());
    }
  }, [dispatch, state.newLoanOptions]);

  useEffect(() => {
    changePage('main');
  }, []);

  function onSignIn() {
    dispatch(Actions.setWantedCreditConditions(null, null));
    history.push('/auth');
  }

  function onSignUp(values) {
    dispatch(Actions.setWantedCreditConditions(values.amount));
    if (!state.abBasicFormSended) {
      const phone = parsePhone(values.regestrationPhone);
      dispatch(Actions.sendAbBasicForm());
      return dispatch(Actions.requestSMS(phone));
    }
    return dispatch(Actions.ABTestAuthorize(values.registrationSms)).then(
      () => {
        history.push('/full-form/credit-request');
      },
    );
  }

  function onOpenAgreement() {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor: AgreementVendor.Finspin,
        agreement: AgreementName.ProcessingPersonalDataPolicy,
      }),
    );
  }

  return (
    <div className="l-land">
      <div className="bg-1">
        <NewHeader onLogin={onSignIn} />
        <LoanForm
          onSubmit={onSignUp}
          calc={state.newLoanOptions}
          pending={state.pendings.requestSMS}
        />
      </div>
      <HowItWorks />
      {/* <CentralBank /> */}
      {/* <Creditors /> */}
      <GetCreditNow onLogin={onSignIn} />
      {/* <PaymentMethods /> */}

      <Footer onOpenAgreement={onOpenAgreement} />
    </div>
  );
}

import * as R from 'ramda';
import cn from 'classnames';
import P from 'prop-types';
import noUiSlider from 'nouislider';
import React from 'react';
import { FormInput } from './form';
import { DataField } from './data_field';

const wNumb = require('wnumb');

function getValueOrDefault(value, min) {
  return R.isNil(value) ? min : value;
}

const defaultFormat = wNumb({
  decimals: 0,
});

export class RawSlider extends React.PureComponent {
  static propTypes = {
    max: P.number,
    min: P.number,
    onChange: P.func,
    value: P.number,
    step: P.number,
    title: P.node,
    getValueCaption: P.func,
    minValueCaption: P.node,
    maxValueCaption: P.node,
    disabled: P.bool,
    format: P.shape({
      to: P.func.isRequired,
      from: P.func.isRequired,
    }),
    className: P.string,
    label: P.string, // Show only in fixed mode
    fixed: P.bool,
  };

  static defaultProps = {
    min: 0,
    max: 100,
    onChange: null,
    value: null,
    step: 1,
    title: null,
    getValueCaption: null,
    minValueCaption: null,
    maxValueCaption: null,
    disabled: false,
    format: null,
    className: '',
    label: null,
    fixed: false,
  };

  componentDidMount() {
    if (!this.props.fixed) {
      noUiSlider.create(this.element, {
        connect: [true, false],
        format: this.props.format ? this.props.format : defaultFormat,
        range: {
          max: this.props.max,
          min: this.props.min,
        },
        start: [getValueOrDefault(this.props.value, this.props.min)],
        step: this.props.step,
      });

      this.element.noUiSlider.on('slide', () => {
        const value = this.element.noUiSlider.get();

        if (this.props.onChange) {
          this.props.onChange(Number(value));
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.fixed) {
      this.element.noUiSlider.updateOptions({
        range: {
          min: nextProps.min,
          max: nextProps.max,
        },
        step: nextProps.step,
        format: nextProps.format ? nextProps.format : defaultFormat,
      });
    }
  }

  componentWillUnmount() {
    if (this.element) {
      this.element.noUiSlider.destroy();
    }
  }

  render() {
    const className = cn('slider', this.props.className, {
      'is-disabled': this.props.disabled,
    });
    const {
      title,
      getValueCaption,
      minValueCaption,
      maxValueCaption,
      value,
      min,
    } = this.props;

    if (this.props.fixed) {
      return (
        <DataField
          label={this.props.label}
          value={
            R.isNil(getValueCaption)
              ? ''
              : getValueCaption(getValueOrDefault(value, min))
          }
        />
      );
    }

    return (
      <div className={className}>
        {R.isNil(title) && R.isNil(getValueCaption) ? null : (
          <div className="slider--info-top-line">
            {R.isNil(title) ? null : (
              <div className="slider--title">{title}</div>
            )}
            {R.isNil(getValueCaption) ? null : (
              <div className="slider--value-caption">
                {getValueCaption(getValueOrDefault(value, min))}
              </div>
            )}
          </div>
        )}
        <div
          ref={(ref) => {
            this.element = ref;
          }}
        />
        {R.isNil(minValueCaption) && R.isNil(maxValueCaption) ? null : (
          <div className="slider--info-bottom-line">
            {R.isNil(minValueCaption) ? null : (
              <div className="slider--min-value-caption">{minValueCaption}</div>
            )}
            {R.isNil(minValueCaption) ? null : (
              <div className="slider--max-value-caption">{maxValueCaption}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export function Slider(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue }) => (
        <RawSlider
          {...rest}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

Slider.propTypes = R.merge(Slider.propTypes, {
  field: P.string.isRequired,
});

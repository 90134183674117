import React from 'react';
import P from 'prop-types';
import { Col, Row, Text, Button, Card } from '../../base';
export function LoanParam(props) {
  return (
    <div className="u-pl-1-md">
      {/*
        Row and Col provide correct paddings, but nested flex containers don't work in IE,
        so we switch back to display: block.
      */}
      <Row className="u-block u-mb-1">
        <Col>
          <Text color="secondary">{props.caption}</Text>
        </Col>
      </Row>
      <Row className="u-block">
        <Col>{props.value}</Col>
      </Row>
    </div>
  );
}

export function CardTemplate(props) {
  const button = (
    <Button
      color={props.buttonColor}
      onClick={props.onAction}
      maxWidth
      className="u-mb-2"
    >
      {props.buttonCaption}
    </Button>
  );

  return (
    <Card
      borderType={props.cardBorderType}
      className={props.className}
      simple={props.cardSimple}
    >
      <Row className="u-pl-2-md u-pr-4-md">
        <Col md={4}>
          <Row className="u-mt-2 u-mb-3">
            <Col>{props.block1}</Col>
          </Row>
          <Row className="u-hidden-lt-md">
            <Col md={11}>{button}</Col>
          </Row>
        </Col>

        {props.block2 || props.block3 ? (
          <Col md={8}>
            {props.block2 ? (
              <Row className="u-mb-2 u-mb-4-md u-mt-2-md">
                <Col>{props.block2}</Col>
              </Row>
            ) : null}
            {props.block3 ? (
              <Row className="u-mb-2 u-mb-0-md">
                <Col>{props.block3}</Col>
              </Row>
            ) : null}
          </Col>
        ) : null}

        <Col className="u-hidden-mt-md">{button}</Col>
      </Row>
    </Card>
  );
}

CardTemplate.propTypes = {
  block1: P.node.isRequired,
  block2: P.node,
  block3: P.node,
  onAction: P.func.isRequired,
  buttonCaption: P.string.isRequired,
  buttonColor: P.oneOf(['primary', 'secondary']),
  cardBorderType: P.oneOf([
    'none',
    'success',
    'danger',
    'neutral',
    'accentLeft',
  ]),
  className: P.string,
  cardSimple: P.bool,
};

CardTemplate.defaultProps = {
  buttonColor: 'secondary',
  cardBorderType: 'success',
  block2: null,
  block3: null,
  className: null,
  cardSimple: false,
};

import React from 'react';
import { Row, Col, Text, Button, Link, Modal } from '../../base';

export function ServerErrorModal(props) {
  return (
    <Modal
      open={props.open}
      hasCloseButton
      title="Что-то пошло не так"
      onClose={props.onClose}
    >
      <Row hCenter className="u-mb-4">
        <Col size={10}>
          <Text center>
            Извините, кажется, у нас что-то сломалось. Мы уже знаем об этом и
            работаем над устранением проблемы. Пожалуйста, попробуйте повторить
            попытку через несколько минут или напишите нам в службу поддержки{' '}
            <Link href="mailto:support@finspin.ru">support@finspin.ru</Link>.
          </Text>
        </Col>
      </Row>
      <Row hCenter className="u-mb-4 u-mt-4">
        <Col size={10}>
          <Text center>
            <Button color="primary" onClick={props.onClose}>
              Попробовать ещё раз
            </Button>
          </Text>
        </Col>
      </Row>
    </Modal>
  );
}

import React from 'react';
import { Modal, Row, Col, Button, Text } from '../../base';

export function CloseApplicationModal(props) {
  function onConfirm() {
    props.onClose();
    props.history.push('/loans');
  }

  return (
    <Modal
      open={props.open}
      hasCloseButton
      title="Отложить заполнение заявки?"
      onClose={props.onClose}
    >
      <Row hCenter>
        <Col size={10}>
          <Text center>
            Мы сохраним все ваши данные, вы сможете продолжить заполнение заявки
            в любое время.
          </Text>
        </Col>
      </Row>
      <Row hCenter className="u-mb-4">
        <Col md={5} className="u-mt-4">
          <Button maxWidth color="primary" onClick={onConfirm}>
            Отложить
          </Button>
        </Col>
        <Col md={5} className="u-mt-4">
          <Button maxWidth color="secondary" onClick={props.onClose}>
            Нет
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

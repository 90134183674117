import React from 'react';
import cn from 'classnames';
import P from 'prop-types';
import ReactForm from './react_form/src/form';

export class Form extends React.Component {
  // Documentation: https://github.com/tannerlinsley/react-form

  setValue(field, value) {
    this.form.setValue(field, value);
  }

  // Public api.
  resetErrors() {
    this.form.setState({ errors: {} });
  }

  invalidate(res) {
    this.form.invalidate(res);
  }

  render() {
    const { children, ...other } = this.props;

    return (
      <ReactForm
        {...other}
        ref={(ref) => {
          this.form = ref;
        }}
        className={cn(this.props.className)}
      >
        {({ submitForm, errors, values, setAllValues, getValue, setValue }) => (
          <form onSubmit={submitForm}>
            {typeof children === 'function'
              ? children({
                  isInvalid: errors != null,
                  values,
                  setAllValues,
                  getValue,
                  setValue,
                })
              : children}
          </form>
        )}
      </ReactForm>
    );
  }
}

Form.propTypes = {
  validate: P.func,
  /* eslint-disable react/forbid-prop-types */
  defaultValues: P.object,
  /* eslint-enable */
  children: P.oneOfType([P.func, P.arrayOf(P.node), P.node]),
  preSubmit: P.func,
  onSubmit: P.func,
  values: P.func,
  className: P.string,
};

Form.defaultProps = {
  validate: undefined,
  defaultValues: undefined,
  children: null,
  preSubmit: undefined,
  onSubmit: undefined,
  values: undefined,
  className: null,
};

import * as R from 'ramda';
import React from 'react';
import P from 'prop-types';
import cn from 'classnames';
import { FormInput } from './form';
import { RawTextField } from './text_field';
import { Icon } from './icon';
import { Dropdown } from './dropdown';
import { OnClickOutsideContainer } from './on_click_outside_container';
import { getDataAttrs } from './utils';
import { DataField } from './data_field';

export class RawSelect extends React.Component {
  static propTypes = {
    onGetOptionId: P.func.isRequired,
    onGetValueCaption: P.func.isRequired,
    onGetOptionCaption: P.func.isRequired,
    items: P.array.isRequired, // eslint-disable-line react/forbid-prop-types
    label: P.oneOfType([P.string, P.number]).isRequired,
    onChange: P.func,
    onBlur: P.func,
    onFocus: P.func,
    value: P.any, // eslint-disable-line react/forbid-prop-types
    error: P.oneOfType([P.string, P.bool]),
    disabled: P.bool,
    optional: P.bool,
    dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
    fixed: P.bool,
  };

  static defaultProps = {
    onChange: null,
    onBlur: null,
    onFocus: null,
    value: null,
    error: false,
    disabled: false,
    optional: false,
    dataAttrs: {},
    fixed: false,
  };

  state = {
    dropdownIsOpen: false,
    textFieldValue: this.props.value
      ? this.props.onGetValueCaption(this.props.value)
      : '',
  };

  handleClickOutside = () => {
    if (this.state.dropdownIsOpen) {
      this.handleBlur();
    }
  };

  handleBlur = () => {
    this.setState({
      dropdownIsOpen: false,
    });

    setTimeout(() => {
      const value = this.state.textFieldValue.toString();

      if (value.trim().length === 0) {
        this.setState({
          textFieldValue: value.trim(),
        });
      }
    }, 30);

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleFocus = () => {
    if (this.props.disabled) {
      return;
    }

    // Если до этого не было выбрано ни одного элемента, вставляем строку из пробела, чтобы
    // нормально отрабатывала анимация для label.
    const textFieldValue = this.state.textFieldValue
      ? this.state.textFieldValue
      : ' ';
    this.setState({
      textFieldValue,
      dropdownIsOpen: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  handleSelect = (item) => {
    this.setState({
      textFieldValue: this.props.onGetValueCaption(item),
    });

    if (this.props.onChange) {
      this.props.onChange(item);
    }

    this.handleBlur();
  };

  handleLeave = () => {
    this.textField.blur();
    this.handleBlur();
  };

  render() {
    const className = cn('select', this.props);

    if (this.props.fixed) {
      return (
        <DataField label={this.props.label} value={this.state.textFieldValue} />
      );
    }

    return (
      <OnClickOutsideContainer
        className={className}
        onClick={this.handleClickOutside}
        maxWidth
      >
        <div {...getDataAttrs(this.props.dataAttrs)}>
          <RawTextField
            value={this.state.textFieldValue}
            error={this.props.error}
            hint={this.props.hint}
            label={this.props.label}
            optional={this.props.optional}
            disabled={this.props.disabled}
            readOnly
            onFocus={this.handleFocus}
            ref={(ref) => {
              this.textField = ref;
            }}
            withoutErrorSign
            dropdownSlot={
              <Dropdown
                items={this.props.items}
                onGetItemCaption={this.props.onGetOptionCaption}
                onGetItemId={this.props.onGetOptionId}
                onSelect={this.handleSelect}
                open={this.state.dropdownIsOpen}
                onLeave={this.handleLeave}
              />
            }
          />
          {this.state.dropdownIsOpen ? (
            <div
              className="select--closable-area"
              onClick={this.handleLeave}
              role="presentation"
            />
          ) : null}
          <div className="select--caret-icon">
            <Icon name="caret-down-icon" />
          </div>
        </div>
      </OnClickOutsideContainer>
    );
  }
}

export function Select(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue, setTouched, getTouched, getError }) => (
        <RawSelect
          {...rest}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
          onBlur={() => {
            setTouched();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
        />
      )}
    </FormInput>
  );
}

Select.propTypes = R.merge(RawSelect.propTypes, {
  field: P.string.isRequired,
});

import * as R from 'ramda';

export function getDataAttrs(obj) {
  if (R.isNil(obj) || R.isEmpty(obj)) {
    return {};
  }

  return Object.keys(obj).reduce((acc, cur) => {
    acc[`data-${cur}`] = obj[cur];
    return acc;
  }, {});
}

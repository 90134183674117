import cn from 'classnames';
import P from 'prop-types';
import * as R from 'ramda';
import Select, { Option } from 'rc-select';
import React, { useCallback, useRef, useState } from 'react';
import { FormInput } from './form';
import { Icon } from './icon';

export function RawNewSelect({
  items,
  label,
  disabled,
  error,
  value,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const className = cn('text-field', props.className, {
    'is-disabled': disabled,
    'is-error': Boolean(error),
    'is-filled':
      !R.isNil(value) &&
      ((typeof value === 'string' && value.length > 0) ||
        typeof value === 'number'),
    'is-focused': focused,
    'is-no-error-sign': props.withoutErrorSign,
  });
  const ref = useRef(null);

  const onFocus = useCallback(() => {
    setOpen(true);
    setFocused(true);
    if (props.onFocus) {
      props.onFocus();
    }
  }, [props]);

  const onBlur = useCallback(() => {
    setOpen(false);
    setFocused(false);

    if (ref.current) {
      ref.current.blur();
    }
    if (props.onBlur) {
      props.onBlur();
    }
  }, [ref, props]);

  const onChange = useCallback(
    (val) => {
      if (props.onChange) {
        props.onChange(val);
      }
      onBlur();
    },
    [onBlur, props],
  );

  return (
    <div className={className}>
      <div className="new-select__control">
        <Select
          ref={ref}
          onChange={onChange}
          open={open}
          showSearch
          onFocus={onFocus}
          onBlur={onBlur}
          className="new-select"
          virtual={false}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          notFoundContent="Ничего не найдено"
          value={value}
          dropdownRender={(menu) =>
            open ? <div className="dropdown">{menu}</div> : null
          }
        >
          {items.map((region) => {
            return (
              <Option key={region} value={region} className="dropdown--item">
                {region}
              </Option>
            );
          })}
        </Select>
        <div className="text-field--label">{label}</div>

        <div className="select--caret-icon">
          <Icon name="caret-down-icon" />
        </div>
      </div>
      {props.hint && (
        <div
          className={`text-field--hint ${props.hintAlways ? 'is-always' : ''}`}
        >
          {props.hint}
        </div>
      )}
      {typeof error === 'string' ? (
        <div className="text-field--error-message">{error}</div>
      ) : null}
    </div>
  );
}

export function NewSelect(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue, setTouched, getTouched, getError }) => (
        <RawNewSelect
          {...rest}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
          onBlur={() => {
            setTouched();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
        />
      )}
    </FormInput>
  );
}

NewSelect.propTypes = R.merge(NewSelect.propTypes, {
  field: P.string.isRequired,
});

import React, { useEffect } from 'react';
import * as R from 'ramda';
import P from 'prop-types';
import moment from 'moment';
import {
  Button,
  Col,
  Container,
  Icon,
  IconButton,
  Link,
  Panel,
  Row,
  Text,
  Title,
} from '../../base';
import {
  formatDayCount,
  formatMoney,
  formatRemainingTime,
  formatISODateToTextField,
} from '../../helpers/format_helpers';
import { calcRemainingDaysUntilExpiration, Creditor } from '../../domain';
import { LoanConditions } from './shared';
// import {LoanProps} from '../shared/loan_props'
import { getAllAgreements } from '../../agreements';
import { changePage } from '../../metrics';

const specialCondition = {
  [Creditor.MoneyMan]: (
    <Text center>
      На первый займ в размере до 10 000 рублей включительно и сроком от 5 до 15
      дней действует специальное предложение: 0% переплаты
    </Text>
  ),
  [Creditor.SmsFinance]: (
    <Text center>
      Первый займ до 30 000 до 10 дней без процентов!{' '}
      <Link href="https://www.smsfinance.ru/discount-terms">Подробнее</Link>
    </Text>
  ),
  // [Creditor.Kviku]: (
  //   <Text center>
  //     Кредитор "Квику" выдает займ только в виде виртуальной карты для
  //     совершения платежей в интернете с первичным активируемым лимитом до 1000
  //     рублей.
  //   </Text>
  // ),
  [Creditor.MigCredit]: (
    <Text center>
      Кредитор «МигКредит» может предложить несколько займов. Ознакомиться с
      ними можно при переходе по кнопке ниже.
    </Text>
  ),
};

function Agreements({ vendor, onShowAgreement }) {
  function onClick(ev) {
    if (onShowAgreement) {
      onShowAgreement(vendor, ev.target.getAttribute('data-value'));
    }
  }

  const agreements = getAllAgreements(vendor);

  if (R.isNil(agreements)) {
    return null;
  }

  const links = agreements.agreements.map((agr) => (
    <Link dataAttrs={{ value: agr.name }} onClick={onClick} key={agr.name}>
      {agr.agreement.title}
    </Link>
  ));

  const [p1, p2] = R.splitAt(links.length - 1, links);

  return (
    <Row className="u-mb-4">
      <Col md={6} offsetMd={3}>
        <Text center>
          <div>
            {p1.map((link, i) => {
              if (i === p1.length - 1) {
                return <span key={i}>{link}</span>;
              }
              return <span key={i}>{link}, </span>;
            })}
            {p1.length > 0 ? <span> и {p2[0]}</span> : p2[0]}
            <span> {agreements.vendorDisplayString}</span>
          </div>
        </Text>
      </Col>
    </Row>
  );
}

Agreements.propTypes = {
  vendor: P.string.isRequired,
  onShowAgreement: P.func.isRequired,
};

export function ApprovedLoan(props) {
  const { loan } = props;

  useEffect(() => {
    changePage(`${loan.mfiAlias}Approved`);
  }, []);

  return (
    <Container>
      <Row className="u-mt-2 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-glad-icon" />
        </Col>
        <Col size={2} start>
          <IconButton
            name="close"
            onClick={props.onClose}
            className="u-align-right"
            dataAttrs={{
              state:
                'ev:click;ctx:Детали approved займа, кнопка "Закрыть форму"',
            }}
          />
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col>
          <Title size={3} sm={2} center>
            Займ одобрен!
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-2">
        <Col md={6} offsetMd={3}>
          <Panel color="success">
            <Row className="u-mb-1">
              <Col>
                <Title size={1} color="inverse" center>
                  {formatMoney(loan.amount)}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <Title size={3} color="inverse" center>
                  на {loan.term}&nbsp;{formatDayCount(loan.term)}
                </Title>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col md={6} offsetMd={3}>
          <Text center>
            {formatISODateToTextField(loan.approvedDate)} вам одобрен займ. Еще
            раз обратите внимание на условия получения займа. Если вы согласны
            на эти условия, вам осталось только получить деньги.
          </Text>
        </Col>
      </Row>

      <Row className="">
        <Col md={6} offsetMd={3}>
          <LoanConditions loan={loan} visibleParams={['decisionDate']} />
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col md={6} offsetMd={3}>
          <Text center>
            Одобрение займа действительно до{' '}
            {moment(loan.expirationDate).format('DD.MM.YYYY')}
            {'. '}
            <Text capitalize inline="block">
              {formatRemainingTime(
                calcRemainingDaysUntilExpiration(loan.expirationDate),
              )}
              {', '}
              чтобы получить займ.
            </Text>
          </Text>
        </Col>
      </Row>

      {specialCondition[loan.mfiAlias] ? (
        <Row className="u-mb-4">
          <Col md={6} offsetMd={3}>
            {specialCondition[loan.mfiAlias]}
          </Col>
        </Row>
      ) : null}

      <Agreements
        vendor={props.loan.mfiAlias} // ПЕРЕДЕЛАТЬ ВОЗМОЖНО НА КРЕДИТОРА
        onShowAgreement={props.onShowAgreement}
      />

      <Row className="u-mb-8">
        <Col offsetMd={4} md={4}>
          <Button
            onClick={() => props.onGetMoney(loan.mfiAlias, loan.mfiUrl)}
            maxWidth
            dataAttrs={{
              stat:
                'ev:click;ctx:Детали approved займа, кнопка "Получить деньги"',
            }}
          >
            Получить деньги
          </Button>
          {/* <Button
            onClick={() => props.onGetMoney(loan, props.userPhone)}
            maxWidth
            dataAttrs={{
              stat: 'ev:click;ctx:Детали approved займа, кнопка "Получить деньги"'
            }}
          >
            Получить деньги
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
}

ApprovedLoan.propTypes = {
  // loan: LoanProps.isRequired,
  // userPhone: P.string.isRequired,
  onClose: P.func.isRequired,
  onGetMoney: P.func.isRequired,
  onShowAgreement: P.func.isRequired,
};

import * as R from 'ramda';
import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import { FormInput } from './form';
import { getDataAttrs } from './utils';
import { DataField } from './data_field';

export class RawCheckbox extends React.PureComponent {
  static propTypes = {
    caption: P.string.isRequired,
    className: P.string,
    dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
    onChange: P.func,
    name: P.string,
    checked: P.bool,
    disabled: P.bool,
    fixed: P.bool,
  };

  static defaultProps = {
    className: '',
    dataAttrs: null,
    onChange: null,
    name: '',
    checked: false,
    disabled: false,
    fixed: false,
  };

  static lastId = 0;

  constructor(props) {
    super(props);
    this.id = RawCheckbox.lastId;
    RawCheckbox.lastId += 1;
  }

  handleChange = (ev) => {
    this.setState({
      checked: ev.target.checked,
    });
    if (this.props.onChange) {
      this.props.onChange(ev.target.checked);
    }
  };

  render() {
    const className = cn('checkbox', this.props.className, {
      'is-checked': this.props.checked,
      'is-disabled': this.props.disabled,
    });
    const id = `${this.id}chb:${this.props.name}`;
    const checked = R.isNil(this.props.checked) ? false : this.props.checked;

    if (this.props.fixed) {
      return (
        <DataField label={this.props.caption} value={checked ? 'Да' : 'Нет'} />
      );
    }

    return (
      <div className={className}>
        <input
          className="checkbox--input"
          onChange={this.handleChange}
          type="checkbox"
          checked={checked}
          id={id}
          {...getDataAttrs(this.props.dataAttrs)}
        />
        <div className="checkbox--label-container">
          <label className="checkbox--label" htmlFor={id}>
            <span className="checkbox--control">
              <span className="checkbox--check" />
            </span>
            {this.props.caption}
          </label>
        </div>
      </div>
    );
  }
}

export function Checkbox(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue }) => (
        <RawCheckbox
          {...rest}
          name={field}
          checked={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

Checkbox.propTypes = R.merge(Checkbox.propTypes, {
  field: P.string.isRequired,
});

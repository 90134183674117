import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

export function Panel(props) {
  const className = cn('panel', props.className, {
    [`is-${props.color}`]: true,
  });

  return <div className={className}>{props.children}</div>;
}

Panel.propTypes = {
  children: P.node.isRequired,
  color: P.oneOf(['success', 'danger', 'neutral']),
  className: P.string,
};

Panel.defaultProps = {
  color: 'success',
  className: '',
};

import * as R from 'ramda';

import { Fields } from './fields';

function configure(step, fixedSteps, config) {
  return config
    .filter((x) => !R.isNil(x))
    .map((x) => {
      const [name, section] = x;
      const field = Fields[name];
      const isFixed = R.includes(name, fixedSteps);
      return R.mergeRight(field, {
        fixed: field.fixable && isFixed,
        section,
        name,
      });
    });
}

const firstStep = (isPublick) => {
  return [
    ['lastName'],
    ['firstName'],
    ['middleName'],
    ['gender'],
    ['birthDate'],
    ['creditAddress'],
    ['email'],
    isPublick ? ['regestrationPhone'] : undefined,
    isPublick ? ['processDataCheckboxAgreement'] : undefined,
  ];
};

export const publickStep = {
  1: (isPublick) => ({
    title: { percent: 0, plusPercent: 30, stepName: 'первый' },
    fields: () => configure(1, [], firstStep(isPublick)),
  }),
};

export const abPublickStep = {
  1: () => ({
    title: { percent: 54, plusPercent: 17, stepName: 'первый' },
    fields: (abBasicFormSended) =>
      configure(
        1,
        [],
        [
          ['lastName'],
          ['firstName'],
          ['middleName'],
          ['birthDate'],
          ['creditAddress'],
          ['email'],
          ['regestrationPhone'],
          abBasicFormSended ? ['registrationSms'] : null,
        ],
      ),
  }),
};
export const steps = {
  1: (fixedSteps) => ({
    title: { percent: 0, plusPercent: 30, stepName: 'первый' },
    fields: (values) => configure(1, fixedSteps, firstStep()),
  }),

  2: (fixedSteps) => ({
    title: { percent: 50, plusPercent: 0, stepName: 'следующий' },
    fields: (values) => {
      const jobType = values.jobType && values.jobType.value;
      const hasJob = jobType !== 5;

      return configure(2, fixedSteps, [
        ['birthPlace', 'Данные паспорта'],
        ['passportSN'],
        ['passportIssuedDate'],
        ['passportIssuedBy'],
        ['departmentCode'],
        ['registrationAddress', 'Адрес регистрации'],
        ['registrationDate'],
        ['registrationCoincidesWithTheFact', 'Адрес проживания'],
        values.registrationCoincidesWithTheFact ? null : ['factAddress'],
        ['jobType', 'Работа'],
        hasJob ? ['jobStartDate'] : null,
      ]);
    },
  }),

  3: (fixedSteps) => ({
    title: { percent: 48, plusPercent: 26, stepName: 'последний' },
    fields: (values) => {
      return configure(3, fixedSteps, [
        ['monthlyIncome'],
        ['marriageStatus', 'Семья'],
        ['amountOfChildren'],
        ['education', 'Образование'],

        ['additionalPhone', 'Доверенное лицо'],
        ['additionalPhoneOwnerType'],
      ]);
    },
  }),
};

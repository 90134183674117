import React from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Icon, Link, Menu, Navbar, Row } from '../../base';
// import {SessionActions} from '../session_data'
// ДОБАВИТЬ ФИЛКОСЫ
import { Actions } from '../state';
import { clearABRejectedCookie } from './filkos_pixel';

export function ABAccountNavbar() {
  const dispatch = useDispatch();
  const history = useHistory();

  function onLogout() {
    // dispatch(Actions.logout());
    clearABRejectedCookie();
    history.push('/full-form/');
  }

  function onNavToLoanList() {
    history.push('/credit-requests');
  }

  function handleMenuSelect(id) {
    switch (id) {
      case 'loan-requests':
        onNavToLoanList();
        break;
      case 'logout':
        onLogout();
        break;
      default:
    }
  }

  return (
    <Navbar>
      <Container>
        <Row hBetween>
          <Col center>
            <Link onClick={onNavToLoanList} className="account-navbar--logo">
              <Icon name="finspin-logo-icon" />
            </Link>
          </Col>
          <Col className="u-text-align-right">
            <Menu
              items={[
                {
                  id: 'logout',
                  caption: 'Выйти',
                  iconName: 'exit-icon',
                  dataAttrs: {
                    stat: 'ev:click;ctx:Навигация, пункт меню "Выйти"',
                  },
                },
              ]}
              onSelect={handleMenuSelect}
              caption="Профиль"
              captionIconName="person-icon"
              dataAttrs={{
                stat: 'ev:click;ctx:Навигация, кнопка "Открыть меню навигации"',
              }}
            />
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

import validatorIsEmail from 'validator/lib/isEmail';
import validatorIsAlphanumeric from 'validator/lib/isAlphanumeric';

export const errors = {
  required: process.env.THEME === 'eng' ? 'Enter the field' : 'Заполните поле',
  email: 'Введите корректный адрес эл. почты',
  phone:
    process.env.THEME === 'eng'
      ? 'Number must contain 11 digits'
      : 'Номер телефона должен состоять из 11 цифр',
  confirmationCode:
    process.env.THEME === 'eng'
      ? 'Code must contain 4 digits'
      : 'Код должен содержать 4 цифры',
  passportSN: 'Серия должна состоять из 4 цифр, номер паспорта из 6 цифр',
  textFieldDate: 'Введите корректную дату',
  departmentCode: 'Введите корректный код подразделения',
  firstName: 'Имя может содержать только буквы русского алфавита и пробелы',
  middleName:
    'Отчество может содержать только буквы русского алфавита и пробелы',
  lastName:
    'Фамилия может содержать только буквы русского алфавита, дефисы и пробелы',
  firstNameLastName: 'Введите фамилию и имя',
  flat: 'Номер квартиры может содержать только буквы и цифры',
  office: 'Номер офиса может содержать только буквы и цифры',
  INN: 'ИНН должен содержать 12 цифр',
};

export const noError = undefined;

export function isEmpty(value) {
  if (
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value !== 'number' && !value && value !== false)
  ) {
    return errors.required;
  }
  return noError;
}

function rule(error, fn) {
  return (value, options) => {
    if (options && options.required) {
      const r = isEmpty(value);

      if (r) {
        return r;
      }
    }

    const prepared = typeof value === 'string' ? value.trim() : value;

    if (prepared === '' || prepared == null) {
      return noError;
    }

    if (fn(prepared)) {
      return noError;
    }
    return error;
  };
}

export const isPhone = rule(
  errors.phone,
  (v) => /\+?[78]\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/.test(v), // this rule must be the same as on server
);

export const isConfirmationCode = rule(errors.confirmationCode, (v) =>
  /^\d\s?\d\s?\d\s?\d$/.test(v),
);

export const isFirstName = rule(errors.firstName, (v) =>
  /^[ёЁа-яА-Я- ]+$/.test(v),
);

export const isMiddleName = rule(errors.middleName, (v) =>
  /^[ёЁа-яА-Я ]+$/.test(v),
);

export const isOnganizationName = rule(errors.required, (v) => {
  const t = /^[ёЁа-яА-Я'".,;/!#$№&*^+|=`><)(\d -]+$/.test(v);
  return t;
});

export const isLastName = rule(errors.lastName, (v) =>
  /^[ёЁа-яА-Я- ]+$/.test(v),
);

export const isFirstNameLastName = rule(errors.firstNameLastName, (value) =>
  /^[ёЁа-яА-Я-]+([ ]+[ёЁа-яА-Я-]+)+$/.test(value),
);

export const isTextFieldDate = rule(errors.textFieldDate, (v) =>
  /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(v),
);

export const isPassportSN = rule(errors.passportSN, (v) =>
  /^\d{4}\s\d{6}/.test(v),
);

export const isEmail = rule(errors.email, (v) => validatorIsEmail(v));

export const isDepartmentCode = rule(errors.departmentCode, (v) =>
  /^\d{3}-\d{3}$/.test(v),
);

export const isFlat = rule(errors.flat, (v) =>
  validatorIsAlphanumeric(v, 'ru-RU'),
);
export const isOffice = rule(errors.office, (v) =>
  validatorIsAlphanumeric(v, 'ru-RU'),
);

export const isINN = rule(errors.INN, (v) => /^\d{12}$/.test(v));

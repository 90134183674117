import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Container,
  ContentBeforeFooter,
  ContentWithFooter,
  Footer,
  Row,
} from '../../base';
import { getCookies } from '../../helpers/storage_helpers';
import { ListLoader } from '../credit_request_list/list_loader';
import { ABAccountNavbar } from '../shared/ab-test-account_navbar';
import { Support } from '../shared/support';
import { Actions } from '../state';
import { Application } from './application/ab-test-application';
import { Rejected } from './rejected';

export function ABTestCreditRequest() {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);

  const cookie = useMemo(() => getCookies(), []);
  useEffect(() => {
    if (cookie.ab_rejected && JSON.parse(cookie.ab_rejected)) {
      dispatch(Actions.sendAbRejected());
    }
  }, [dispatch, cookie]);

  function isVisibleDataLoading() {
    return false;
  }

  function renderLoanRequest() {
    if (isVisibleDataLoading()) {
      return null;
    }
    if (state.abRejected) {
      return <Rejected />;
    }
    return <Application />;
  }

  return (
    <ContentWithFooter>
      <ContentBeforeFooter>
        <ABAccountNavbar />
        {renderLoanRequest()}
        {isVisibleDataLoading() ? <ListLoader /> : null}
      </ContentBeforeFooter>
      <Footer>
        <Container>
          <Row className="u-mb-4 u-pt-4">
            <Col offsetMd={3} md={6}>
              <Support phone={process.env.SUPPORT_PHONE} />
            </Col>
          </Row>
        </Container>
      </Footer>
    </ContentWithFooter>
  );
}

import * as R from 'ramda';
import SuperAgent from 'superagent';
import { toCamelCase } from './helpers/format_helpers';

const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest';

function transformDadataAddress(dadata) {
  return R.pipe(
    R.pick([
      'postalCode',
      'regionFiasId',
      'regionKladrId',
      'regionWithType',
      'region',
      'regionType',
      'regionTypeFull',
      'areaFiasId',
      'area',
      'areaTypeFull',
      'areaKladrId',
      'areaType',
      'cityFiasId',
      'city',
      'cityTypeFull',
      'cityKladrId',
      'cityType',
      'settlementFiasId',
      'settlement',
      'settlementTypeFull',
      'settlementKladrId',
      'settlementType',
      'streetFiasId',
      'street',
      'streetTypeFull',
      'streetWithType',
      'streetType',
      'streetKladrId',
      'houseFiasId',
      'houseTypeFull',
      'house',
      'houseType',
      'blockTypeFull',
      'block',
      'fiasId',
      'kladrId',
      'fiasLevel',
      'geoLat',
      'geoLon',
      'okato',
    ]),
    R.filter((val) => !R.isNil(val)),
    R.merge({
      value: dadata.value,
      unrestrictedValue: dadata.unrestricted_value,
    }),
  )(toCamelCase(dadata.data));
}

/**
 *
 * @param query {string}
 * @param options {Object} - https://confluence.hflabs.ru/pages/viewpage.action?pageId=529793265
 * @param isPublicToken {boolean}
 */
export function searchAddresses(query, options, isPublicToken) {
  const isPublic =
    window.location.hash === '#/full-form/credit-request' ||
    window.location.hash === '#/';

  return SuperAgent('post', `${url}/address`)
    .set({
      Authorization: `Token ${
        isPublic
          ? process.env.FREE_DADATA_AUTHORIZATION_TOKEN
          : process.env.DADATA_AUTHORIZATION_TOKEN
      }`,
    })
    .send({
      query,
      count: options.count || 10,
      from_bound: { value: options.fromBound },
      to_bound: options.toBound ? { value: options.toBound } : undefined,
      locations: R.isNil(options.constraints)
        ? []
        : [R.filter((x) => !R.isNil(x), options.constraints)],
      restrict_value: true,
    })
    .then((res) => res.body.suggestions.map(transformDadataAddress));
}

const getRegionFiasId = R.path(['regionFiasId']);
const getAreaFiasId = R.path(['areaFiasId']);
const getCityFiasId = R.path(['cityFiasId']);
const getSettlementFiasId = R.path(['settlementFiasId']);
const getStreetFiasId = R.path(['streetFiasId']);

export function searchRegions(query, isPublic) {
  return searchAddresses(
    query,
    {
      fromBound: 'region',
      toBound: 'region',
    },
    isPublic,
  );
}

export function searchCities(query, addressCtx, isPublic) {
  return searchAddresses(
    query,
    {
      constraints: {
        region_fias_id: getRegionFiasId(addressCtx),
      },
      fromBound: 'city',
      toBound: 'settlement',
    },
    isPublic,
  );
}

export function searchStreets(query, addressCtx) {
  function isAppropriateStreet(address) {
    if (R.isNil(addressCtx)) {
      return true;
    }
    return R.isNil(addressCtx.settlement) ? R.isNil(address.settlement) : true;
  }

  return searchAddresses(query, {
    constraints: {
      region_fias_id: getRegionFiasId(addressCtx),
      city_fias_id: getCityFiasId(addressCtx),
      settlement_fias_id: getSettlementFiasId(addressCtx),
    },
    fromBound: 'street',
    toBound: 'street',
  })
    .then((res) => res.filter(isAppropriateStreet))
    .then((res) => {
      if (res.length === 0) {
        return [R.merge(addressCtx, { street: 'Без улицы' })];
      }
      return res;
    });
}

export function searchHouses(query, addressCtx) {
  return searchAddresses(query, {
    constraints: {
      region_fias_id: getRegionFiasId(addressCtx),
      area_fias_id: getAreaFiasId(addressCtx),
      city_fias_id: getCityFiasId(addressCtx),
      settlement_fias_id: getSettlementFiasId(addressCtx),
      street_fias_id: getStreetFiasId(addressCtx),
    },
    fromBound: 'house',
  }).then((res) =>
    res.map((x) => {
      if (R.isNil(x.street)) {
        return R.assoc('street', 'Без улицы', x);
      }
      return x;
    }),
  );
}

const dadataGenderMap = {
  UNKNOWN: null,
  MALE: 'male',
  FEMALE: 'female',
};

const searchNames = (part) => (query) =>
  SuperAgent('post', `${url}/fio`)
    .set({
      Authorization: `Token ${process.env.DADATA_AUTHORIZATION_TOKEN}`,
    })
    .send({
      query,
      count: 10,
      parts: [part],
    })
    .then((res) =>
      res.body.suggestions.map((suggestion) => ({
        value: suggestion.value,
        gender: dadataGenderMap[suggestion.data.gender] || null,
      })),
    );

export const searchFirstNames = searchNames('NAME');
export const searchLastNames = searchNames('SURNAME');
export const searchMiddleNames = searchNames('PATRONYMIC');

export const searchOrganizationsInn = (query) =>
  SuperAgent('post', `${url}/party`)
    .set({
      Authorization: `Token ${process.env.DADATA_AUTHORIZATION_TOKEN}`,
    })
    .send({
      query,
      count: 10,
    })
    .then((res) =>
      res.body.suggestions.map((suggestion) => {
        return {
          value: suggestion.value,
          inn: suggestion.data.inn,
          adress: suggestion.data.address.unrestricted_value,
        };
      }),
    );

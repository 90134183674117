import * as R from 'ramda';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Format from '../../../helpers/format_helpers';

import {
  Container,
  Row,
  Col,
  Title,
  Text,
  IconButton,
  Link,
} from '../../../base';
import { scrollUp } from '../../../helpers/dom_helpers';
import { Loading } from './loading';
import { LoadingDots } from '../../credit_request_list/loadingDots'; // ПЕРЕМЕСТИТЬ
import { ApplicationForm } from './application_form';
import { Actions } from '../../state';
import { ModalType } from '../../modals/modal_type';
import { steps } from './steps';
import { API } from '../../../api';
import { changePage } from '../../../metrics';

const debounce = require('lodash/debounce');

function ProgressBar({ value = 0, plus = 0 }) {
  return (
    <div className="progress-bar">
      {plus > 0 ? (
        <div
          className="progress-bar--plus"
          style={{ width: `${value + plus}%` }}
        />
      ) : null}
      {value > 0 ? (
        <div className="progress-bar--value" style={{ width: `${value}%` }} />
      ) : null}
    </div>
  );
}

function StepTitle({ percent, plusPercent, stepName }) {
  const color = '#817AFF';

  return (
    <div>
      <Text
        bold
        center
        style={{ color }}
        sm="body-1"
        type="caption"
        className="u-mb-1"
      >
        Вероятность одобрения кредита
        {percent > 0 ? ` ${percent}%` : ''}
      </Text>
      <ProgressBar value={percent} plus={plusPercent} />
      {/* <Text sm="subtitle" center className="u-mt-2">
        Получите{' '}
        <Text bold inline sm="subtitle" style={{ color }}>
          +{plusPercent}%
        </Text>{' '}
        к{' '}
        <Text center inline bold sm="subtitle">
          вероятности одобрения займа
        </Text>
        , заполнив {stepName} шаг
      </Text> */}
    </div>
  );
}

let shouldSendField = true;

function save(id, value, field) {
  const val = R.isNil(value) || R.isEmpty(value) ? null : value;
  if (shouldSendField) {
    API.saveProfileField(id, field, val);
  }
}

const saveField = debounce(save, 2000);

const analyticPageMap = {
  1: 'formFirstStep',
  2: 'formSecondStep',
  3: 'formThirdStep',
};

export function Application({ onCheckCreditRequestStatus }) {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  function componentDidUnmount() {
    dispatch(Actions.goToList());
    return dispatch(Actions.applicationUnmount());
  }
  useEffect(() => {
    scrollUp();
    dispatch(Actions.goToApplication());
    if (!state.newLoanOptions) {
      dispatch(Actions.fetcCreditConditions());
    }
    if (!state.profile && state.creditRequest.status === 'needInfo') {
      dispatch(Actions.fetchProfile(state.creditRequest.id));
    }
    return () => componentDidUnmount();
  }, []);

  useEffect(() => {
    if (state.creditRequest.step != null) {
      shouldSendField = true;
      scrollUp();
      changePage(analyticPageMap[state.creditRequest.step]);
    }
  }, [state.creditRequest.step]);

  useEffect(() => {
    const intervalId = window.setInterval(onCheckCreditRequestStatus, 15000);
    return () => window.clearInterval(intervalId);
  }, [onCheckCreditRequestStatus]);

  let prevSavedField = null;

  // function onClose() {
  //   dispatch(Actions.openModal(ModalType.CloseApplication));
  // }

  function onSubmitProfile(profile) {
    // Отменяем сохранение поля, чтобы не перезатирать значение отправленное через sendApplication.
    // Если значение отправляется через sendApplication, нет особого смысла перед этим сохранять его
    // через saveProfielField.
    if (
      state.creditRequest.status !== 'needInfo' &&
      state.creditRequest.onApplication
    ) {
      dispatch(Actions.goToList());
    }
    shouldSendField = false;
    saveField.cancel();
    return dispatch(Actions.sendApplication(profile));
  }

  function onFieldChange(value, field) {
    // Если начали редактировать новое поле, предыдущее немедленно сохраняем
    if (!R.isNil(prevSavedField) && prevSavedField !== field) {
      saveField.flush();
    }
    saveField(state.creditRequest.id, value, field);
    prevSavedField = field;
  }

  function onShowPassportChangeHelp() {
    dispatch(Actions.openModal(ModalType.PassportChangeHelp));
  }

  function isSubmitPending() {
    // let shouldPendingLoanRequest = state.creditRequest
    //   ? false
    //   : state.pendings.fetchCreditRequest

    return (
      state.pendings.sendApplication ||
      (state.pendings.fetchCreditRequest && !state.creditRequest.onApplication)
    );
  }
  const moscowDate = moment().utcOffset(3);
  const agreementsData = {
    phone: Format.formatPhone(state.userPhone),
    date: moscowDate.format('DD.MM.YYYY'),
    time: moscowDate.format('HH:mm'),
  };
  function onShowAgreement(vendor, agreement) {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor,
        agreement,
        data: agreementsData,
      }),
    );
  }

  function renderNotificationBar() {
    const count = state.creditRequest.countLoanRequestItem;
    if (state.creditRequest.step !== 1) {
      return count !== 0 ? (
        <Row className="notification-bar">
          <div className="notification-bar-container u-mt-2">
            <LoadingDots />

            <Text inline bold center>
              {Format.formatPickedUpWords(count)}{' '}
              {/* <Link onClick={returnToSuggestionList}>
                {count} {Format.formatSuggestionCount(count)} &rarr;
              </Link> */}
            </Text>
          </div>
        </Row>
      ) : null;
      // <Text inline bold center>
      //   Уже подбираем займы…
      // </Text>
    }
    return null;
  }

  function renderTitle() {
    const config = steps[state.creditRequest.step]([]);
    // Find fields with bonus in config and check the profile are filled these or not
    const bonusFields = config.fields({}).filter((x) => R.has('bonus', x));
    const bonus = bonusFields.reduce((total, field) => {
      if (
        state.profile[field.name] == null ||
        state.profile[field.name] === ''
      ) {
        return total;
      }
      return total + field.bonus.value;
    }, 0);
    const percent = config.title.percent + bonus;

    return <StepTitle {...config.title} percent={percent} />;
  }

  function renderForm() {
    return (
      <ApplicationForm
        key={state.creditRequest.step} // Для перерисовки после смена шага
        stepNumber={state.creditRequest.step} // Для аналитики
        onFieldChange={onFieldChange}
        // Показываем loader на submit кнопке при отправке анкеты либо при загрузке заявки,
        // тк после отправки анкеты происходит загрузка заявки
        isSubmitPending={isSubmitPending()}
        // isSubmitPending={
        //   state.pendings.sendApplication || state.pendings.fetchCreditRequest
        // }
        onSubmit={onSubmitProfile}
        step={steps[state.creditRequest.step](state.profile.fixed)}
        profile={state.profile.profile}
        onShowPassportChangeHelp={onShowPassportChangeHelp}
        onShowAgreement={onShowAgreement}
      />
    );
  }

  if (!state.profile || state.pendings.fetchProfile || !state.newLoanOptions) {
    return <Loading />;
  }

  return (
    <>
      {/* {renderNotificationBar()} */}
      <Container>
        <Row className="u-mt-2 u-mt-4-sm u-mb-1 u-mb-2-sm">
          <Col offset={2} center>
            <Title size={3} sm={2} center>
              Заявка на кредит
            </Title>
          </Col>
          <Col size={2} start />
        </Row>
        {renderTitle()}
        {renderForm()}
        {process.env.ENV === 'production' && state.creditRequest.step === 1 && (
          <img
            src={`https://kviku.ru/pixel?market=1013&id=${state.creditRequest.id}`}
            alt="pixel"
            width="1"
            height="1"
          />
        )}
      </Container>
    </>
  );
}

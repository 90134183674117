import * as R from 'ramda';
import moment from 'moment';
import SuperAgent from 'superagent';

function hasValue(x) {
  return !R.isNil(x) && !R.isEmpty(x);
}
const optinalFields = ['email', 'jobInn'];

function hasValueWithOptional(field, value) {
  if (optinalFields.includes(field) && R.isNil(value)) {
    return true;
  }
  return !R.isNil(value) && !R.isEmpty(value);
}

let serverErrorHandler = () => {};
// return Promise.reslove({...data})

function convertApplicationDTO(dto) {
  function convertAddress(address) {
    if (!address) return null;
    return R.pipe(
      R.assoc('value', address.value),
      R.assoc('unrestrictedValue', address.unrestrictedValue),
      R.assoc(
        'street',
        R.path(['data', 'streetIsAbsent'], address)
          ? 'Без улицы'
          : R.path(['data', 'street'], address),
      ),
      R.assoc('withoutFlat', R.path(['data', 'flatIsAbsent'], address)),
    )(R.filter(hasValue, address.data));
  }

  const profile = dto.profile || {};
  return {
    profile: R.pipe(
      R.mergeLeft({
        registrationAddress: convertAddress(profile.registrationAddress),
        factAddress: convertAddress(profile.factAddress),
        jobAddress: convertAddress(profile.jobAddress),
      }),
      R.filter(hasValue),
    )(profile),
    required: dto.required || [],
    fixed: dto.disabled || [],
  };
}

function convertToApplicationDTO(application) {
  function convertAddress(address) {
    if (!address) return null;
    const data = R.pipe(
      R.dissoc('value'),
      R.dissoc('unrestrictedValue'),
      R.mergeLeft({
        street: address.street === 'Без улицы' ? '' : address.street,
        streetIsAbsent: address.street === 'Без улицы',
      }),
      R.mergeLeft({
        flat: address.withoutFlat ? '' : address.flat,
        flatIsAbsent: address.withoutFlat,
      }),
      R.dissoc('withoutFlat'),
      R.filter(hasValue),
    )(address);

    return {
      value: address.value,
      unrestrictedValue: address.unrestrictedValue,
      data,
    };
  }

  const updatedApp = R.mergeLeft(
    {
      creditAddress: convertAddress(application.creditAddress),
      registrationAddress: convertAddress(application.registrationAddress),
      jobAddress: convertAddress(application.jobAddress),
      factAddress: convertAddress(application.factAddress),
    },
    application,
  );

  return Object.keys(updatedApp).reduce((acc, cur) => {
    if (hasValueWithOptional(cur, updatedApp[cur])) {
      return R.assoc(cur, updatedApp[cur], acc);
    }
    return acc;
  }, {});
}

function convertApplicationValidationErrors(errs) {
  function convertAddressErrors(errors, addressName) {
    return {
      region: errors[`${addressName}.data.region`],
      city: errors[`${addressName}.data.city`],
      street: errors[`${addressName}.data.street`],
      house: errors[`${addressName}.data.house`],
      flat: errors[`${addressName}.data.flat`],
    };
  }

  function getErrorsWithoutFlatAddress(errors, addressesName) {
    return R.pipe(
      R.dissoc(`${addressesName[0]}.data.flat`),
      R.dissoc(`${addressesName[1]}.data.flat`),
      R.dissoc(`${addressesName[2]}.data.flat`),
    )(errors);
  }

  return R.mergeRight(
    getErrorsWithoutFlatAddress(errs, [
      'registrationAddress',
      'factAddress',
      'jobAddress',
    ]),
    {
      registrationAddress: convertAddressErrors(errs, 'registrationAddress'),
      factAddress: convertAddressErrors(errs, 'factAddress'),
      jobAddress: convertAddressErrors(errs, 'jobAddress'),
    },
  );
}

function transformValidationErrors(arr) {
  const newObj = arr.reduce(
    (acc, error) => R.assoc(error.path, error.message, acc),
    {},
  );
  return newObj;
}

function transformErrorResponse(fn) {
  return (res) => {
    const { body } = res.response;
    const error = new Error('ERROR');
    if (res.status === 400 || res.status === 422) {
      error.status = 400;
      error.data = fn(transformValidationErrors(body.details));
    } else {
      error.status = res.status;
      error.data = body.details;
    }
    throw error;
  };
}

export function setServerErrorHandler(handler) {
  serverErrorHandler = handler;
}

function makeAPIRequest(method, url, params = {}) {
  const headers = R.merge(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Request-Date': new Date().toISOString(),
    },
    params.headers,
  );
  const isLocal = process.env.ENV === 'local';
  let agent = SuperAgent(
    method,
    isLocal ? `/credit-card${url}` : `/api/credit-card${url}`,
  )
    .withCredentials()
    .set(headers);

  const { body } = params;

  if (method === 'post' && body) {
    agent = agent.send(body);
  } else if (method === 'get' && body) {
    agent = agent.query(body);
  }
  return agent.on('error', serverErrorHandler);
}

const getVerificationHeaders = (now) => ({
  'Request-Token': window
    .btoa(`${now.toISOString()}82EF09DB7BE7F7236FCD53D4F7B26B7F`)
    .toLowerCase(),
  'Request-Date': now.toISOString(),
});

export const API = {
  // Authoruzation
  abTestSMS(body) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Request-Date': new Date().toISOString(),
    };

    // const agent = SuperAgent('post', 'https://admin.p1sms.ru/apiSms/create/')
    //   .set(headers)
    //   .withCredentials();

    // return agent.send({
    //   apiKey: process.env.P1SMS_API_KEY,
    //   sms: [
    //     {
    //       phone: body.phone,
    //       channel: 'char',
    //       text: 'test from Kovalev',
    //     },
    //   ],
    // });
    fetch('https://admin.p1sms.ru/apiSms/create', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        apiKey: process.env.P1SMS_API_KEY,
        sms: [
          {
            phone: body.phone,
            channel: 'char',
            text: 'test from Kovalev',
          },
        ],
      }),
    });
  },
  checkAuthStatus() {
    return makeAPIRequest('get', '/v3/authStatus').then(({ body }) => {
      return {
        isAuthorized: body.authorized,
      };
    });
  },
  postRoute(data) {
    return makeAPIRequest('post', '/v3/route', {
      body: R.filter(hasValue, data || {}),
    }).then(({ body }) => body);
  },
  getRoute() {
    return makeAPIRequest('get', '/v3/route').then(({ body }) => body);
  },
  requestSMS(phone) {
    return makeAPIRequest('post', '/v3/sendVerificationCode', {
      body: { phone },
      headers: getVerificationHeaders(new Date()),
    })
      .then(({ body }) => ({
        primaryUser: body.isNewUser,
        resendTimeout: body.canRepeatAfter,
      }))
      .catch(transformErrorResponse((res) => ({ phone: res.phone })));
  },

  authorize(code, primaryUser) {
    const timezone = moment().format('Z');
    const host =
      process.env.ENV === 'local' ? undefined : document.location.hostname;

    // Факт того, что нужно отправлять какие-то согласия находится здесь, тк
    // флага primaryUser достаточно внутри приложения, чтобы отправить согласия в API слое
    // ps Ваня
    const agreements = primaryUser
      ? {
          processDataAgreement: true,
          receiveInfoAgreement: true,
          creditHistoryRequestAgreement: true,
          usePersonalSignAgreement: true,
        }
      : null;

    return makeAPIRequest('post', '/v3/auth', {
      body: {
        code,
        timezone,
        host,
        ...(R.isNil(agreements) ? {} : agreements),
      },
      headers: getVerificationHeaders(new Date()),
    }).catch(
      transformErrorResponse((res) => {
        return { confirmationCode: res.code };
      }),
    );
  },

  logout() {
    return makeAPIRequest('post', '/v3/logOut').then(({ body }) => {
      // window.location.search = '';
      return body;
    });
  },

  // Unauthorized
  sendUnauthApplication(profile) {
    return makeAPIRequest('post', `/v3/form`, {
      body: convertToApplicationDTO(profile),
      headers: getVerificationHeaders(new Date()),
    })
      .then((res) => res)
      .catch(transformErrorResponse(convertApplicationValidationErrors));
  },

  saveUnauthProfileField(field, value) {
    return makeAPIRequest('post', `/v3/creditRequest/formField`, {
      body: convertToApplicationDTO({ [field]: value }),
      headers: getVerificationHeaders(new Date()),
    }).then((res) => res);
  },

  fetcCreditConditions() {
    return makeAPIRequest('get', '/v3/conditions').then(({ body }) => body);
  },

  checkCreditRequestStatus() {
    return makeAPIRequest('get', '/v3/creditRequest').then(({ body }) => ({
      canCreateNewCreditRequest: body.canCreateNewCreditRequest,
      creditRequestId: body.creditRequestId,
    }));
  },

  createCreditRequest(commonLeadProviderParams) {
    return makeAPIRequest('post', '/v3/creditRequest', {
      body: R.filter(hasValue, commonLeadProviderParams || {}),
    }).then(({ body }) => ({
      id: body.creditRequestId,
    }));
  },

  fetchCreditRequest(creditRequestId) {
    return makeAPIRequest('get', `/v3/creditRequest/${creditRequestId}`).then(
      ({ body }) => {
        return {
          id: body.id,
          status: body.status,
          step: body.step,
          expirationDate: body.expirationDate,
          isLongProcess: body.isLongProcess,
          offers: body.offers,
        };
      },
    );
  },

  fetchProfile(creditRequestId) {
    return makeAPIRequest(
      'get',
      `/v3/creditRequest/${creditRequestId}/form`,
    ).then(({ body }) => convertApplicationDTO(body));
  },

  sendApplication(creditRequestId, profile) {
    return makeAPIRequest('post', `/v3/creditRequest/${creditRequestId}/form`, {
      body: convertToApplicationDTO(profile),
    })
      .then((res) => res)
      .catch(transformErrorResponse(convertApplicationValidationErrors));
  },

  saveProfileField(creditRequestId, field, value) {
    return makeAPIRequest(
      'post',
      `/v3/creditRequest/${creditRequestId}/formField`,
      {
        body: {
          fieldName: field,
          value: convertToApplicationDTO({ [field]: value })[field],
        },
      },
    ).then((res) => res);
  },

  fetchAlternativeOffers(creditRequestId) {
    return makeAPIRequest(
      'get',
      `/v3/creditRequest/${creditRequestId}/offersForRejectScreen`,
    ).then(({ body }) => {
      return body;
    });
  },
};

import * as R from 'ramda';

export function getDataFromQuery(queries) {
  const result = {
    cookies: {},
    phone: null,
    loanParams: {},
    leadProviderParams: {},
  };

  if (R.isEmpty(queries)) {
    return result;
  }

  const utm = R.pick(
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
    queries,
  );

  if (R.keys(utm).length) {
    result.cookies.utm = R.keys(utm)
      .map((k) => `${k}=${utm[k]}`)
      .join('&');
  }

  if (!R.isNil(queries.scenario)) {
    result.cookies.scenario = queries.scenario;
  }

  if (!R.isNil(queries.phone)) {
    result.phone = queries.phone;
  }

  const loanParams = {
    amount: parseInt(queries.amount, 10),
    // term: parseInt(queries.term, 10),
  };

  if (loanParams.amount || loanParams.term) {
    result.loanParams = loanParams;
  }

  if (!R.isNil(queries.lp) || !R.isNil(queries.rid)) {
    result.leadProviderParams.common = {
      lp: queries.lp,
      rid: queries.rid,
    };
  }

  return result;
}

import React from 'react';
import P from 'prop-types';
import { Col, Modal, Row, Text, Title } from '../../base';
import { LoanCalculator } from '../shared/loan_calculator';

export function LoanCalculatorModal(props) {
  function onSubmit(payload) {
    props.onClose();
    props.data.onSubmit(payload);
  }

  const data = props.data || {};

  return (
    <Modal
      open={props.open}
      hasCloseButton
      onClose={props.onClose}
      className="loan-calculator-modal"
    >
      <Row className="u-mb-2">
        <Col md={10} offsetMd={1}>
          <Title center size={3}>
            {data.title}
          </Title>
        </Col>
      </Row>
      <Row className="u-mb-4">
        <Col md={10} offsetMd={1}>
          <Text center>{data.description}</Text>
        </Col>
      </Row>
      <Row className="u-mb-2">
        <Col md={10} offsetMd={1}>
          {data.calc ? (
            <LoanCalculator onSubmit={onSubmit} {...data.calc} />
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
}

LoanCalculatorModal.propTypes = {
  open: P.bool,
  data: P.shape({
    title: P.string,
    description: P.string,
    calc: P.object,
    onSubmit: P.func,
  }),
};

LoanCalculatorModal.defaultProps = {
  open: false,
  data: null,
};

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';
import querystring from 'querystring';
import { API } from '../../api';
import {
  Button,
  Col,
  Container,
  Form,
  Icon,
  IconButton,
  Link,
  Row,
  Text,
  TextField,
  Title,
} from '../../base';
import { AgreementName, AgreementVendor } from '../../domain';
import { scrollUp } from '../../helpers/dom_helpers';
import * as Format from '../../helpers/format_helpers';
import { changePage } from '../../metrics';
import { isConfirmationCode, isPhone } from '../../validation';
import { LoadingDots } from '../credit_request_list/loadingDots';
import { ModalType } from '../modals/modal_type';
import { Actions } from '../state';

function AuthTitle() {
  // const state = useSelector((s) => s);
  // const title = state.creditRequest.amount
  //   ? `Кредитные карты на ${Format.formatMoney(state.creditRequest.amount)} `
  //   : 'Кредитные карты на любые цели';

  return (
    <Col offsetMd={1} md={5}>
      <div className="u-text-align-center">
        <div className="u-hidden-lt-sm u-mb-6 u-mt-2 get-money-icon" />
      </div>
      <Title className="u-mb-2" size={4} sm={3} center>
        Кредит на любые цели
      </Title>
      <div className="u-border-bottom u-border-bottom-none-md">
        <Text type="body-2" sm="subtitle" center className="u-mb-2">
          Мы бесплатно подберём наиболее подходящее предложение среди всех
          кредиторов.
        </Text>
      </div>
    </Col>
  );
}

function PhoneForm() {
  const [formRef, setFormRef] = useState(null);
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const loc = useLocation();
  const queryString = loc.hash.split('#/auth?')[1];

  const query = querystring.parse(queryString) || {};

  function handleValidate({ phone }) {
    return { phone: isPhone(phone, { required: true }) };
  }

  function handlePreSubmit({ phone }) {
    return { phone: Format.parsePhone(phone) };
  }

  function handleSubmit({ phone }) {
    formRef.form.invalidate(dispatch(Actions.requestSMS(phone)));
  }

  return (
    <div className="u-pl-1-md">
      <Title className="u-my-2" size={3} sm={2} center leftMd>
        Подтверждение телефона
      </Title>
      <Text className="u-mb-2">
        Укажите ваш номер мобильного телефона. Мы вышлем на него бесплатное
        SMS-сообщение с кодом для подтверждения.
      </Text>
      <Form
        preSubmit={handlePreSubmit}
        onSubmit={handleSubmit}
        validate={handleValidate}
        defaultValues={{
          phone: Format.formatPhone(query.phone || state.auth.phone),
        }}
        ref={setFormRef}
      >
        <TextField
          type="tel"
          label="Мобильный телефон"
          field="phone"
          mask="+7 (999) 999-99-99"
          dataAttrs={{ stat: '1', id: 'phone' }}
        />
        {state.auth.timer > 0 ? (
          <div className="u-mt-1">
            <Text type="body-2" color="secondary" inline>
              SMS с кодом подтверждения отправлено. Продолжить можно через:
            </Text>
            <Text type="body-2" inline>
              {Format.formatTimer(state.auth.timer)}
            </Text>
          </div>
        ) : null}
        <div className="u-my-4">
          <Button
            maxWidth
            isPending={state.pendings.requestSMS}
            disabled={state.auth.timer > 0}
            type="submit"
            dataAttrs={{ stat: '1', id: 'phoneSubmit' }}
          >
            Продолжить
          </Button>
        </div>
      </Form>
    </div>
  );
}

function SMSCodeForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((s) => s);
  const [formRef, setFormRef] = useState(null);

  const moscowDate = moment().utcOffset(3);
  const agreementsData = {
    phone: Format.formatPhone(state.auth.phone),
    date: moscowDate.format('DD.MM.YYYY'),
    time: moscowDate.format('HH:mm'),
  };

  function handlePreSubmit({ confirmationCode }) {
    return { confirmationCode: Format.parseConfirmationCode(confirmationCode) };
  }

  const [loading, setLoading] = useState(false);

  const asyncRouteInterval = (period) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        return API.getRoute().then((data) => {
          if (data.status === 'finished') {
            clearInterval(interval);
            return resolve(data);
          }
        });
      }, period);
    });
  };

  function handleSubmit(values) {
    dispatch(Actions.setAuthorizeProcessing(true));
    setLoading(true);
    return dispatch(Actions.sendAuthorizeCode(values.confirmationCode))
      .then(() => API.postRoute(state.leadProviderParams.common))
      .then(() => {
        return asyncRouteInterval(1000);
      })
      .then((getRouteResponse) => {
        if (getRouteResponse.availableRoute === 'creditCard') {
          return dispatch(
            Actions.checkCreditRequestAfterAuth({ history }),
          ).then(() => {
            setLoading(false);
            dispatch(Actions.setAuthorizeProcessing(false));
          });
        }
        window.location = `${
          window.location.origin
        }/loan-requests?utm_medium=bank_finspin&redirectFromCreditCard=true&${window.location.search.slice(
          1,
        )}`;
      })
      .catch((e) => {
        setLoading(false);
        return Promise.reject(e);
      });
  }

  function handleValidate({ confirmationCode }) {
    return {
      confirmationCode: isConfirmationCode(confirmationCode, {
        required: true,
      }),
    };
  }

  function onShowAgreement(vendor, agreement, data) {
    dispatch(
      Actions.openModal(ModalType.Agreement, { vendor, agreement, data }),
    );
  }

  function handleChangePhone() {
    dispatch(Actions.reenterAuthPhone());
  }

  function handleResendCode() {
    formRef.form.invalidate(dispatch(Actions.requestSMS(state.auth.phone)));
  }

  return (
    <div className="u-pl-1-md">
      <Title className="u-my-2" size={3} sm={2} center leftMd>
        Подтверждение телефона.
      </Title>
      <Form
        preSubmit={handlePreSubmit}
        onSubmit={handleSubmit}
        validate={handleValidate}
        defaultValues={{ phone: Format.formatPhone(state.auth.phone) }}
        ref={setFormRef}
      >
        {state.primaryUser ? (
          <Text className="u-mb-2" type="body-2">
            Регистрируясь, вы принимаете{' '}
            <Link
              onClick={() =>
                onShowAgreement(
                  AgreementVendor.Finspin,
                  AgreementName.PersonalSignUse,
                )
              }
              dataAttrs={{ stat: '1' }}
            >
              Условия использования аналога собственноручной подписи.
            </Link>
          </Text>
        ) : null}
        <TextField
          type="tel"
          label="Мобильный телефон"
          field="phone"
          mask="+7 (999) 999-99-99"
          disabled
        />
        <Text className="u-mb-2 u-mt-1" type="body-2">
          <Link onClick={handleChangePhone} dataAttrs={{ stat: '1' }}>
            Изменить номер
          </Link>
        </Text>
        <TextField
          label="Код подтверждения"
          field="confirmationCode" // code
          autocomplete="one-time-code"
          mask="9999"
          maskChar=""
          dataAttrs={{ stat: '1', id: 'code' }}
        />
        {state.auth.timer > 0 ? (
          <div className="u-mt-1">
            <Text type="body-2" color="secondary" inline>
              SMS с кодом подтверждения отправлено.
            </Text>
            <Text type="body-2" color="secondary" className="SEO_SMS_ERROR_MSG">
              Доставка сообщений абонентам МТС затруднена и может осуществляться
              с задержкой.
            </Text>{' '}
            <Text type="body-2" color="secondary" inline>
              Запросить новый код можно через:
            </Text>
            <Text type="body-2" inline>
              {Format.formatTimer(state.auth.timer)}
            </Text>
          </div>
        ) : (
          <div className="u-mt-1">
            <Text type="body-2" color="secondary" inline>
              SMS с кодом подтверждения отправлено.{' '}
            </Text>
            <Text
              type="body-2"
              color="secondary"
              inline
              className="SEO_SMS_ERROR_MSG"
            >
              Доставка сообщений абонентам МТС затруднена и может осуществляться
              с задержкой.
            </Text>{' '}
            <Text type="body-2" inline>
              <Link onClick={handleResendCode}>Выслать код повторно</Link>
            </Text>
          </div>
        )}

        {loading && (
          <div className="u-my-2">
            <LoadingDots />
            <Text center>Пожалуйста, подождите</Text>
          </div>
        )}

        <Button
          className="u-mb-4"
          maxWidth
          isPending={state.pendings.authorize || loading}
          type="submit"
          dataAttrs={{ stat: '1', id: 'confirmationCodeSubmit' }}
        >
          Подтвердить
        </Button>
        {state.primaryUser ? (
          <Text className="u-mb-2" type="body-2">
            Указывая полученный из SMS код, вы соглашаетесь с{' '}
            <Link
              onClick={() =>
                onShowAgreement(
                  AgreementVendor.Finspin,
                  AgreementName.ProcessingPersonalData,
                  agreementsData,
                )
              }
              dataAttrs={{ stat: '1' }}
            >
              Соглашением на обработку персональных данных
            </Link>
            ,{' '}
            <Link
              onClick={() =>
                onShowAgreement(
                  AgreementVendor.Finspin,
                  AgreementName.InfoReceiving,
                  agreementsData,
                )
              }
              dataAttrs={{ stat: '1' }}
            >
              Соглашением на получение информации
            </Link>
            ,{' '}
            <Link
              onClick={() =>
                onShowAgreement(
                  AgreementVendor.Finspin,
                  AgreementName.CreditInfoDisclosure,
                  agreementsData,
                )
              }
              dataAttrs={{ stat: '1' }}
            >
              Соглашением на получение доступа к кредитной истории
            </Link>
            .
          </Text>
        ) : null}
      </Form>
    </div>
  );
}

function AuthForm() {
  const state = useSelector((s) => s);

  switch (state.auth.form) {
    case 'code':
      return <SMSCodeForm />;
    case 'phone':
      return <PhoneForm />;
    default:
      return null;
  }
}

export function Auth() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    changePage('auth');
    scrollUp();
    return () => dispatch(Actions.resetAuth());
  }, [dispatch]);

  return (
    <Container>
      <Row hBetween className="u-my-3 u-my-6-md">
        <Col center>
          <Icon name="finspin-logo-icon" />
        </Col>
        <Col center>
          <IconButton
            name="close"
            onClick={() => history.push('/')}
            className="u-align-right"
            dataAttrs={{ stat: '1' }}
          />
        </Col>
      </Row>
      <Row className="u-mb-2">
        <AuthTitle />
        <Col offsetMd={1} md={4} className="u-border-left-md">
          <AuthForm />
        </Col>
      </Row>
    </Container>
  );
}

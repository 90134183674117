import React from 'react';
import P from 'prop-types';
import cn from 'classnames';

export function Navbar(props) {
  const className = cn('navbar', props.className, {
    'is-transparent': props.transparent,
  });

  return <div className={className}>{props.children}</div>;
}

Navbar.propTypes = {
  children: P.node,
  transparent: P.bool,
  className: P.string,
};

Navbar.defaultProps = {
  children: null,
  transparent: false,
  className: '',
};

import React from 'react';
import { Button, Col, Container, Icon, Row, Text, Title } from '../../base';

export function Rejected() {
  function onMoveToPrunto() {
    window.open('https://finspin.ru/loan-requests/');
  }

  return (
    <Container>
      <Row className="u-mt-3 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-warning-icon" />
        </Col>
        <Col size={2} start className="u-text-align-center" />
      </Row>

      <Row className="u-mb-4">
        <Col>
          <Title size={4} sm={3} center>
            К сожалению, ни один банк пока не готов одобрить вам кредит
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-2">
        <Col md={6} offsetMd={3}>
          <Text center type="subtitle">
            Если вам срочно необходима небольшая сумма на короткий срок,
            воспользуйтесь нашим сервисом&nbsp;—{' '}
            <Text bold inline type="subtitle">
              подбором моментальных займов
            </Text>
          </Text>
        </Col>
      </Row>

      <Row className="u-mb-5">
        <Col sm={8} md={4} offsetSm={2} offsetMd={4}>
          <Button onClick={onMoveToPrunto} maxWidth>
            Подобрать
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

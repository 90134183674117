import React from 'react';
import P from 'prop-types';
import * as R from 'ramda';
import cn from 'classnames';
import { Col, Row, Text, Title, FormInput } from './index';
import { RawAutocomplete } from './autocomplete';
import { RawTextField } from './text_field';
import { RawCheckbox } from './checkbox';
import { DataField } from './data_field';

const GET_ADDRESS_DELAY = 300;

const selectors = {
  region(address) {
    if (R.isNil(address) || R.isNil(address.region)) return '';
    if (R.isNil(address.regionWithType) && R.isNil(address.regionTypeFull))
      return address.region;
    if (R.isNil(address.regionWithType))
      return `${address.region} ${address.regionTypeFull}`;
    return address.regionWithType;
  },

  area(address) {
    if (R.isNil(address) || R.isNil(address.area)) return '';
    if (R.isNil(address.areaTypeFull)) return address.area;
    return `${address.area} ${address.areaTypeFull}`;
  },

  city(address) {
    if (R.isNil(address) || R.isNil(address.city)) return '';
    if (R.isNil(address.cityTypeFull)) return address.city;
    return `${address.cityTypeFull} ${address.city}`;
  },

  settlement(address) {
    if (R.isNil(address) || R.isNil(address.settlement)) return '';
    if (R.isNil(address.settlementTypeFull)) return address.settlement;
    return `${address.settlementTypeFull} ${address.settlement}`;
  },

  cityAndSettlement(address) {
    return [selectors.city(address), selectors.settlement(address)]
      .filter((x) => !R.isEmpty(x))
      .join(' ');
  },

  street(address) {
    if (R.isNil(address) || R.isNil(address.street)) return '';
    if (R.isNil(address.streetTypeFull)) return address.street;
    return `${address.streetTypeFull} ${address.street}`;
  },

  house(address) {
    if (R.isNil(address) || R.isNil(address.house)) {
      return '';
    }
    const r = [];
    if (!R.isNil(address.houseTypeFull)) {
      r.push(address.houseTypeFull);
    }
    r.push(address.house);
    if (!R.isNil(address.blockTypeFull)) {
      r.push(address.blockTypeFull);
    }
    if (!R.isNil(address.block)) {
      r.push(address.block);
    }
    return r.join(' ');
  },
};

function pickInfo(suggestion, ctx, fields) {
  return fields.reduce((acc, f) => {
    if (
      R.is(Object, f) &&
      f.required &&
      R.path([f.name], ctx) !== R.path([f.name], suggestion)
    ) {
      return R.append(selectors[f.name](suggestion), acc);
    }
    if (R.isNil(R.path([f], ctx)) && !R.isNil(R.path([f], suggestion))) {
      return R.append(selectors[f](suggestion), acc);
    }
    return acc;
  }, []);
}

function createFullSelector(mainField, additionalFields) {
  return (suggestion, ctx) => [
    selectors[mainField](suggestion),
    ...pickInfo(suggestion, ctx, additionalFields),
  ];
}

function createIdGenerator(fullSelector) {
  return (suggestion, ctx) => fullSelector(suggestion, ctx).join(':');
}

function suggestionTemplate(info) {
  return (
    <div>
      <Title size={4} regular>
        {info[0]}
      </Title>
      {info.length > 1 ? (
        <Text color="secondary">{info.slice(1).join(', ')}</Text>
      ) : null}
    </div>
  );
}

// Region autocomplete

export function getRegionValue(address) {
  return selectors.region(address);
}

export function getRegionId(suggestion) {
  return selectors.region(suggestion);
}

export function getRegionCaption(suggestion) {
  return (
    <Title size={4} regular>
      {selectors.region(suggestion)}
    </Title>
  );
}

// City autocomplete

function getCityValue(address) {
  return selectors.cityAndSettlement(address);
}

const getCityInfo = createFullSelector('cityAndSettlement', [
  'region',
  { name: 'area', required: true },
]);

function getCityCaption(suggestion, ctx) {
  return suggestionTemplate(getCityInfo(suggestion, ctx));
}

const getCityId = createIdGenerator(getCityInfo);

// Street autocomplete

function getStreetValue(item) {
  return selectors.street(item);
}

const getStreetInfo = createFullSelector('street', [
  'region',
  { name: 'area', required: true },
  'city',
  'settlement',
]);

function getStreetCaption(suggestion, ctx) {
  return suggestionTemplate(getStreetInfo(suggestion, ctx));
}

const getStreetId = createIdGenerator(getStreetInfo);

// House autocomplete

function getHouseValue(suggestion) {
  return selectors.house(suggestion);
}

const getHouseInfo = createFullSelector('house', [
  'region',
  { name: 'area', required: true },
  'city',
  'settlement',
  'street',
]);

function getHouseCaption(item, ctx) {
  return suggestionTemplate(getHouseInfo(item, ctx));
}

const getHouseId = createIdGenerator(getHouseInfo);

const regionFields = [
  'region',
  'regionFiasId',
  'regionKladrId',
  'regionType',
  'regionTypeFull',
];

const cityFields = [
  'cityFiasId',
  'city',
  'cityTypeFull',
  'settlementFiasId',
  'settlement',
  'settlementTypeFull',
];

const streetFields = [
  'streetFiasId',
  'street',
  'streetTypeFull',
  'streetWithType',
  'streetType',
  'streetKladrId',
  'postalCode',
];

const houseFields = ['houseFiasId', 'houseTypeFull', 'house', 'postalCode'];

const flatFields = ['flat', 'withoutFlat'];

function concatAll(...arr) {
  return R.reduce((acc, val) => acc.concat(val), [], arr);
}

function removeFields(address, fields) {
  if (R.isEmpty(address)) {
    return address;
  }
  return fields.reduce((acc, cur) => R.dissoc(cur, acc), address);
}

const defaultLabels = {
  region: 'Регион',
  city: 'Город или населенный пункт',
  street: 'Улица',
  house: 'Дом',
  flat: 'Квартира',
  withoutFlat: 'Номер квартиры отсутствует',
};
const defaultHints = {
  region: 'Начните вводить и выберите регион из выпадающего списка',
  city: 'Начните вводить и выберите город из выпадающего списка',
  street: 'Начните вводить и выберите улицу из выпадающего списка',
  house: 'Начните вводить и выберите номер дома из выпадающего списка',
};

function takeLabelFor(field, alt = {}) {
  return R.propOr(defaultLabels[field], field, alt);
}

function takeHintFor(field) {
  return defaultHints[field];
}

export class RawAddress extends React.Component {
  getAddress = () => this.props.value || {};

  getErrors = () => this.props.error || {};

  createChangeHandler = (fields) => (value) => {
    if (R.isNil(value)) {
      this.props.onChange(removeFields(this.props.value, fields));
    } else {
      this.props.onChange(value);
    }
  };

  handleChangeRegion = this.createChangeHandler(
    concatAll(regionFields, cityFields, streetFields, houseFields, flatFields),
  );

  handleChangeCity = this.createChangeHandler(
    concatAll(cityFields, streetFields, houseFields, flatFields),
  );

  handleChangeStreet = this.createChangeHandler(
    concatAll(streetFields, houseFields, flatFields),
  );

  handleChangeHouse = this.createChangeHandler(houseFields, flatFields);

  handleChangeFlat = (value) => {
    this.props.onChange(
      R.merge(this.getAddress(), {
        flat: value,
      }),
    );
  };

  handleChangeWithoutFlat = (value) => {
    this.props.onChange(
      R.dissoc('flat')(
        R.merge(this.getAddress(), {
          withoutFlat: value,
        }),
      ),
    );
  };

  render() {
    const { value, field, fixed } = this.props;
    return (
      <div className={cn(this.props.className)}>
        <Row className="u-mb-2">
          <Col md={12}>
            {fixed ? (
              <DataField
                label={takeLabelFor('region', this.props.labels)}
                value={getRegionValue(value)}
              />
            ) : (
              <RawAutocomplete
                isAddres
                value={value}
                label={takeLabelFor('region', this.props.labels)}
                hint={takeHintFor('region')}
                delay={GET_ADDRESS_DELAY}
                onGetSuggestions={this.props.searchRegion}
                onGetSuggestionCaption={getRegionCaption}
                onGetValueCaption={getRegionValue}
                onGetSuggestionId={getRegionId}
                multiline
                onChange={this.handleChangeRegion}
                error={this.getErrors().region}
                dataAttrs={{ id: `${field}:region` }}
              />
            )}
          </Col>
        </Row>

        {this.props.renderFields.city && (
          <Row className="u-mb-2">
            <Col md={12}>
              {fixed ? (
                <DataField
                  label={takeLabelFor('city', this.props.labels)}
                  value={getCityValue(value)}
                />
              ) : (
                <RawAutocomplete
                  isAddres
                  value={value}
                  label={takeLabelFor('city', this.props.labels)}
                  hint={takeHintFor('city')}
                  delay={GET_ADDRESS_DELAY}
                  onGetSuggestions={(term) =>
                    this.props.searchCity(term, value)
                  }
                  onGetSuggestionCaption={(val) => getCityCaption(val, value)}
                  onGetValueCaption={getCityValue}
                  onGetSuggestionId={(val) => getCityId(val, value)}
                  multiline
                  onChange={this.handleChangeCity}
                  error={this.getErrors().city}
                  dataAttrs={{ id: `${field}:city` }}
                />
              )}
            </Col>
          </Row>
        )}

        {this.props.renderFields.street && (
          <Row className="u-mb-2">
            <Col md={12}>
              {fixed ? (
                <DataField
                  label={takeLabelFor('street', this.props.labels)}
                  value={getStreetValue(value)}
                />
              ) : (
                <RawAutocomplete
                  isAddres
                  value={value}
                  label={takeLabelFor('street', this.props.labels)}
                  hint={takeHintFor('street')}
                  delay={GET_ADDRESS_DELAY}
                  onGetSuggestions={(term) =>
                    this.props.searchStreet(term, value)
                  }
                  onGetSuggestionCaption={(val) => getStreetCaption(val, value)}
                  onGetValueCaption={getStreetValue}
                  onGetSuggestionId={(val) => getStreetId(val, value)}
                  multiline
                  onChange={this.handleChangeStreet}
                  error={this.getErrors().street}
                  dataAttrs={{ id: `${field}:street` }}
                />
              )}
            </Col>
          </Row>
        )}

        {this.props.renderFields.house && (
          <Row className="u-mb-2">
            <Col lg={6}>
              {fixed ? (
                <DataField
                  label={takeLabelFor('house', this.props.labels)}
                  value={getHouseValue(value)}
                />
              ) : (
                <RawAutocomplete
                  isAddres
                  value={value}
                  label={takeLabelFor('house', this.props.labels)}
                  hint={takeHintFor('house')}
                  delay={GET_ADDRESS_DELAY}
                  onGetSuggestions={(term) =>
                    this.props.searchHouse(term, value)
                  }
                  onGetSuggestionCaption={(val) => getHouseCaption(val, value)}
                  onGetValueCaption={getHouseValue}
                  onGetSuggestionId={(val) => getHouseId(val, value)}
                  onChange={this.handleChangeHouse}
                  error={this.getErrors().house}
                  dataAttrs={{ id: `${field}:house` }}
                />
              )}
            </Col>
          </Row>
        )}

        {this.props.renderFields.flat && (
          <div>
            {this.getAddress().withoutFlat ? null : (
              <Row className="u-mb-2">
                <Col lg={6}>
                  {fixed ? (
                    <DataField
                      label={takeLabelFor('flat', this.props.labels)}
                      value={this.getAddress().flat}
                    />
                  ) : (
                    <RawTextField
                      value={this.getAddress().flat}
                      label={takeLabelFor('flat', this.props.labels)}
                      onChange={this.handleChangeFlat}
                      error={this.getErrors().flat}
                      dataAttrs={{ id: `${field}:flat` }}
                    />
                  )}
                </Col>
              </Row>
            )}
          </div>
        )}

        {this.props.renderFields.withoutFlat && (
          <Row>
            <Col md={12}>
              <RawCheckbox
                checked={this.getAddress().withoutFlat}
                caption={takeLabelFor('withoutFlat', this.props.labels)}
                onChange={this.handleChangeWithoutFlat}
                dataAttrs={{ id: `${field}:withoutFlat` }}
                fixed={fixed}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

RawAddress.propTypes = {
  searchRegion: P.func.isRequired,
  searchCity: P.func.isRequired,
  searchStreet: P.func,
  searchHouse: P.func,
  onChange: P.func.isRequired,
  labels: P.shape({
    region: P.string,
    city: P.string,
    street: P.string,
    house: P.string,
    flat: P.string,
    withoutFlat: P.string,
  }),
  field: P.string,
  value: P.object, // eslint-disable-line react/forbid-prop-types
  error: P.object, // eslint-disable-line react/forbid-prop-types
  className: P.string,
  fixed: P.bool,
  renderFields: P.shape({
    region: P.bool,
    city: P.bool,
    street: P.bool,
    house: P.bool,
    flat: P.bool,
    withoutFlat: P.bool,
  }),
};

RawAddress.defaultProps = {
  field: '',
  value: null,
  error: null,
  className: null,
  fixed: false,
  renderFields: {
    region: true,
    city: true,
    street: true,
    house: true,
    flat: true,
    withoutFlat: true,
  },
};

export function Address(props) {
  return (
    <FormInput field={props.field}>
      {({ setValue, getValue, getTouched, getError }) => (
        <RawAddress
          {...props}
          error={getTouched() && getError()}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, props.field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

Address.propTypes = R.merge(RawAddress.propTypes, {
  field: P.string.isRequired,
});

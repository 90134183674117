import React from 'react';
import P from 'prop-types';
import { Col, Container, Row, Text, Title, Link } from '../../base';
import { formatPhone } from '../../helpers/format_helpers';

export function Support(props) {
  return (
    <Container>
      <Row className="u-pt-4 u-border-top">
        <Col>
          <Title
            size={5}
            center
            className="u-mb-2"
            style={{ letterSpacing: '1px' }}
          >
            Служба поддержки
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-1">
        <Col>
          <Title size={4} center noWrap>
            <Link href={`tel:8${props.phone}`}>
              {formatPhone(props.phone, { firstDigit: '8' })}
            </Link>
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-1">
        <Col>
          <Text type="caption" color="secondary" center>
            С 9:00 до 18:00 по московскому времени звонок по России бесплатный
          </Text>
        </Col>
      </Row>

      <Row>
        <Col>
          <Text type="caption" color="secondary" center>
            Email:{' '}
            <Link href="mailto:support@finspin.ru">support@finspin.ru</Link>
          </Text>
        </Col>
      </Row>
    </Container>
  );
}

Support.propTypes = {
  phone: P.string.isRequired,
};

import * as R from 'ramda';
// import moment from 'moment'
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { API } from '../api';
import { createReducer } from '../helpers/redux_helpers';
import { setCookies } from '../helpers/storage_helpers';
import { clearCookie } from './shared/filkos_pixel';

const Events = {
  // Запросить смс с код для авторизации
  REQUEST_SMS: 'REQUEST_SMS',

  // Поменять номер телефона с которым хотим войти
  REENTER_AUTH_PHONE: 'REENTER_AUTH_PHONE',

  // Авторизоваться с кодом из смс (signup или signin без разницы)
  AUTHORIZE: 'AUTHORIZE',
  AB_TEST_AUTHORIZE: 'AB_TEST_AUTHORIZE',
  AB_SET_REJECTED: 'AB_SET_REJECTED',

  AUTHORIZE_PROCESSING: 'AUTHORIZE_PROCESSING',

  // Обновить таймер обратного отсчета до возмножности повторого запроса смс кода
  UPDATE_SMS_TIMER: 'UPDATE_SMS_TIMER',

  LOGOUT: 'LOGOUT',

  // Установить телефон пользователя (например из url)
  SET_USER_PHONE: 'SET_USER_PHONE',

  // Установить желаемые параметры займа (сумма и срок)
  SET_WANTED_CREDIT_CONDITIONS: 'SET_WANTED_CREDIT_CONDITIONS',

  // Загрузить данные о creditRequest
  CHECK_CREDIT_REQUEST_STATUS: 'CHECK_CREDIT_REQUEST_STATUS',

  // Загрузить условия нового займа (например для анкеты на шаге выбора суммы и срока)
  FETCH_CREDIT_CONDITIONS: 'FETCH_CREDIT_CONDITIONS',

  CHECK_AUTH_STATUS: 'CHECK_AUTH_STATUS',
  // Проверить, есть ли кука авторизации
  // CHECK_AUTH_COOKIE: 'CHECK_AUTH_COOKIE',

  CREATE_CREDIT_REQUEST: 'CREATE_CREDIT_REQUEST',

  RESET_AUTH: 'RESET_AUTH',

  OPEN_MODAL: 'OPEN_MODAL',

  CLOSE_MODAL: 'CLOSE_MODAL',

  FETCH_CREDIT_REQUEST: 'FETCH_CREDIT_REQUEST',

  FETCH_ACTIVE_LOAN_REQUEST: 'FETCH_ACTIVE_LOAN_REQUEST',

  FETCH_ARCHIVE_LOAN_REQUEST: 'FETCH_ARCHIVE_LOAN_REQUEST',

  FETCH_LOAN_REQUEST_SUGGESTIONS: 'FETCH_LOAN_REQUEST_SUGGESTIONS',

  FETCH_ACTIVE_LOAN_REQUEST_SUGGESTIONS:
    'FETCH_ACTIVE_LOAN_REQUEST_SUGGESTIONS',

  FETCH_ALTERNATIVE_OFFERS: 'FETCH_ALTERNATIVE_OFFERS',

  RESET_CREDIT_REQUEST: 'RESET_CREDIT_REQUEST',

  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_REGIONS: 'FETCH_REGIONS',

  APPLICATION_UNMOUNT: 'APPLICATION_UNMOUNT',

  CREDIT_REQUEST_LIST_UNMOUNT: 'CREDIT_REQUEST_LIST_UNMOUNT',

  CREDIT_REQUEST_UNMOUNT: 'CREDIT_REQUEST_UNMOUNT',

  // Отправить анкету
  SEND_APPLICATION: 'SEND_APPLICATION',
  SEND_AB_BASIC_FORM: 'SEND_AB_BASIC_FORM',

  SEND_UNAUTH_APPLICATION: 'SEND_UNAUTH_APPLICATION',

  ADD_LEAD_PROVIDER_PARAMS: 'ADD_LEAD_PROVIDER_PARAMS',

  REMOVE_LEAD_PROVIDER_PARAMS: 'REMOVE_LEAD_PROVIDER_PARAMS',

  GO_TO_FIRST_STEP: 'GO_TO_FIRST_STEP',

  // Переключение между списками предложений и анкетой
  GO_TO_APPLICATION: 'GO_TO_APPLICATION',
  GO_TO_LIST: 'GO_TO_LIST',
};

export const Actions = {
  fetcCreditConditions() {
    return {
      type: Events.FETCH_CREDIT_CONDITIONS,
      payload: API.fetcCreditConditions(),
    };
  },

  setWantedCreditConditions(amount) {
    return {
      type: Events.SET_WANTED_CREDIT_CONDITIONS,
      payload: { amount },
    };
  },

  checkAuthStatus: () => (dispatch) =>
    dispatch({
      type: Events.CHECK_AUTH_STATUS,
      payload: API.checkAuthStatus(),
    }),

  requestSMS(phone) {
    return (dispatch, getState) => {
      dispatch({
        type: Events.REQUEST_SMS,
        payload: {
          promise: API.requestSMS(phone),
          data: { phone },
        },
      }).then(() => {
        // Запускаем таймер обратного отсчета для разблокирования возможности
        // перезапроса смс
        const id = setInterval(() => {
          if (getState().auth.timer === 0) {
            clearInterval(id);
          } else {
            dispatch({ type: Events.UPDATE_SMS_TIMER });
          }
        }, 1000);
      });
    };
  },

  sendAuthorizeCode(code) {
    return (dispatch, getState) => {
      return dispatch({
        type: Events.AUTHORIZE,
        payload: API.authorize(code, getState().primaryUser),
      });
    };
  },
  checkCreditRequestAfterAuth(router) {
    return (dispatch, getState) => {
      return new Promise((resolve) => {
        const { primaryUser } = getState();
        if (!primaryUser) {
          const response = dispatch(Actions.checkCreditRequestStatus());

          return resolve(response);
        }
        return resolve();
      })
        .then(() => {
          const { creditRequest, leadProviderParams } = getState();
          if (creditRequest.id == null && creditRequest.amount != null) {
            return dispatch(
              Actions.createCreditRequest(leadProviderParams.common),
            );
          }
        })
        .then(() => {
          const { creditRequest } = getState();
          if (creditRequest.id !== null) {
            router.history.push(`/credit-request/${creditRequest.id}`);
          } else {
            router.history.push('/credit-request');
          }
        });
    };
  },

  authorize(code, router) {
    return (dispatch, getState) => {
      dispatch(Actions.setAuthorizeProcessing(true));
      return dispatch({
        type: Events.AUTHORIZE,
        payload: API.authorize(code, getState().primaryUser),
      })
        .then(() => {
          const { primaryUser } = getState();
          if (!primaryUser) {
            return dispatch(Actions.checkCreditRequestStatus());
          }
        })
        .then(() => {
          const { creditRequest, leadProviderParams } = getState();

          if (creditRequest.id == null && creditRequest.amount != null) {
            return dispatch(Actions.createCreditRequest(leadProviderParams));
          }
        })
        .then(() => {
          const { creditRequest } = getState();
          if (creditRequest.id !== null) {
            router.history.push(`/credit-request/${creditRequest.id}`);
          } else {
            router.history.push('/credit-request');
          }
        })
        .then(() => dispatch(Actions.setAuthorizeProcessing(false)));
    };
  },
  ABTestAuthorize(code) {
    return (dispatch, getState) => {
      return dispatch({
        type: Events.AB_TEST_AUTHORIZE,
        payload: API.authorize(code, getState().primaryUser),
      }).then(() => dispatch(Actions.logout()));
    };
  },

  setAuthorizeProcessing(flag) {
    return {
      type: Events.AUTHORIZE_PROCESSING,
      payload: flag,
    };
  },

  reenterAuthPhone() {
    return { type: Events.REENTER_AUTH_PHONE };
  },

  addLeadProviderParams(leadProviderParams) {
    return (dispatch) => {
      dispatch({
        type: Events.ADD_LEAD_PROVIDER_PARAMS,
        payload: leadProviderParams,
      });
    };
  },

  removeLeadProviderParams(provider) {
    return (dispatch) => {
      dispatch({
        type: Events.REMOVE_LEAD_PROVIDER_PARAMS,
        payload: { provider },
      });
    };
  },

  checkCreditRequestStatus() {
    return {
      type: Events.CHECK_CREDIT_REQUEST_STATUS,
      payload: API.checkCreditRequestStatus(),
    };
  },

  createCreditRequest(commonLeadProviderParams) {
    return {
      type: Events.CREATE_CREDIT_REQUEST,
      payload: API.createCreditRequest(commonLeadProviderParams),
    };
  },

  setUserPhone(phone) {
    return {
      type: Events.SET_USER_PHONE,
      payload: phone,
    };
  },

  resetAuth() {
    return { type: Events.RESET_AUTH };
  },

  openModal(modalId, data) {
    return {
      type: Events.OPEN_MODAL,
      payload: {
        data,
        id: modalId,
      },
    };
  },

  closeModal(modalId) {
    return {
      type: Events.CLOSE_MODAL,
      payload: {
        id: modalId,
      },
    };
  },

  fetchCreditRequest(loanRequestId) {
    return {
      type: Events.FETCH_CREDIT_REQUEST,
      payload: API.fetchCreditRequest(loanRequestId),
    };
  },

  resetCreditRequest() {
    return { type: Events.RESET_CREDIT_REQUEST };
  },

  fetchProfile(loanRequestId) {
    return {
      type: Events.FETCH_PROFILE,
      payload: API.fetchProfile(loanRequestId),
    };
  },

  fetchAlternativeOffers(credutRequestId) {
    return {
      type: Events.FETCH_ALTERNATIVE_OFFERS,
      payload: API.fetchAlternativeOffers(credutRequestId),
    };
  },

  sendApplication(application) {
    return (dispatch, getState) => {
      const state = getState();
      return dispatch({
        type: Events.SEND_APPLICATION,
        payload: {
          promise: API.sendApplication(state.creditRequest.id, application),
          data: application,
        },
      })
        .then(() =>
          dispatch(Actions.fetchCreditRequest(state.creditRequest.id)),
        )
        .then(({ value }) => {
          if (value.status === 'needInfo') {
            return dispatch(Actions.fetchProfile(state.creditRequest.id));
          }
          dispatch(Actions.goToList());
        });
    };
  },
  sendUnauthApplication(application) {
    return (dispatch) => {
      return dispatch({
        type: Events.SEND_UNAUTH_APPLICATION,
        payload: {
          promise: API.sendUnauthApplication(application),
          data: application,
        },
      });
    };
  },
  sendAbBasicForm() {
    return (dispatch) => {
      return dispatch({
        type: Events.SEND_AB_BASIC_FORM,
        payload: true,
      });
    };
  },
  sendAbRejected() {
    return (dispatch) => {
      setCookies({ ab_rejected: true });
      return dispatch({
        type: Events.AB_SET_REJECTED,
        payload: true,
      });
    };
  },

  logout(history) {
    return (dispatch) => {
      clearCookie();
      return dispatch({
        type: Events.LOGOUT,
        payload: API.logout(),
      });
    };
  },

  applicationUnmount() {
    return { type: Events.APPLICATION_UNMOUNT };
  },

  creditRequestUnmount() {
    return { type: Events.CREDIT_REQUEST_UNMOUNT };
  },
  creditRequestListUnmount() {
    return { type: Events.CREDIT_REQUEST_UNMOUNT };
  },

  goToFirstStep() {
    return { type: Events.GO_TO_FIRST_STEP };
  },

  goToApplication() {
    return { type: Events.GO_TO_APPLICATION };
  },
  goToList() {
    return { type: Events.GO_TO_LIST };
  },
};

const defaultStates = {
  auth: {
    phone: '',
    form: 'phone',
    timer: 0,
  },

  userPhone: null,

  creditRequest: {
    // amount: null,
    onApplication: false,
    id: null,
    status: null,
    isLongProcess: false,
    offers: [],
  },
};

const initialState = {
  // Данные калькулятора для новой заявки
  newLoanOptions: null,
  abBasicFormSended: false,
  abRejected: false,
  // Данные о заявке
  creditRequest: defaultStates.creditRequest,

  auth: defaultStates.auth,

  // Флаги разрашений пользователя, что может что нет
  permissions: null,

  // Профиль пользователя, используется в анкете
  profile: null,

  // Авторизован ли пользователь
  authorized: false,
  didCheckAuthStatus: false,

  // Новый пользователь на сайте или нет
  primaryUser: false,

  // Filkos
  leadProviderParams: { common: null, filkos: null },

  // Модальные окна
  modals: {},

  pendings: {
    requestSMS: false,
    authorize: false,
    checkCreditRequestStatus: false,
    createCreditRequest: false,
    fetchCreditRequest: false,
    fetchProfile: false,
    sendApplication: false,
    sendAbBasicForm: false,
    sendUnauthApplication: false,
    fetchAlternativeOffers: false,
    checkLoanRequest: false,
  },
};

const reducer = createReducer(initialState, {
  [Events.FETCH_CREDIT_CONDITIONS]: {
    fulfilled(state, { payload }) {
      return R.mergeRight(state, {
        newLoanOptions: payload,
      });
    },
  },

  [Events.SET_WANTED_CREDIT_CONDITIONS](state, { payload }) {
    return R.mergeDeepRight(state, {
      creditRequest: {
        amount: payload.amount,
      },
    });
  },

  [Events.REQUEST_SMS]: {
    pending(state, { payload }) {
      return R.mergeDeepRight(state, {
        auth: {
          phone: payload.phone,
        },
        pendings: { requestSMS: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        primaryUser: payload.primaryUser,
        auth: {
          timer: payload.resendTimeout,
          form: 'code',
        },
        pendings: { requestSMS: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { requestSMS: false },
      });
    },
  },

  [Events.ADD_LEAD_PROVIDER_PARAMS](state, { payload }) {
    return R.mergeDeepRight(state, {
      leadProviderParams: R.pick(['common', 'filkos'], payload),
    });
  },

  [Events.REMOVE_LEAD_PROVIDER_PARAMS](state, { payload }) {
    return R.mergeDeepRight(state, {
      leadProviderParams: { [payload.provider]: null },
    });
  },

  [Events.AUTHORIZE]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { authorize: true },
      });
    },
    fulfilled(state) {
      return R.mergeDeepRight(state, {
        authorized: true,
        pendings: { authorize: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { authorize: false },
      });
    },
  },

  [Events.AUTHORIZE_PROCESSING](state, { payload }) {
    return R.mergeRight(state, {
      authorizeProcessing: payload,
    });
  },

  [Events.UPDATE_SMS_TIMER](state) {
    return R.mergeDeepRight(state, {
      auth: { timer: state.auth.timer - 1 },
    });
  },

  [Events.REENTER_AUTH_PHONE](state) {
    return R.mergeDeepRight(state, {
      auth: { form: 'phone' },
    });
  },

  [Events.CHECK_CREDIT_REQUEST_STATUS]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { checkCreditRequestStatus: true },
      });
    },

    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        creditRequest: {
          id: payload.creditRequestId,
        },
        permissions: {
          canCreateNewCreditRequest: payload.canCreateNewCreditRequest,
        },
        pendings: { checkCreditRequestStatus: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { checkCreditRequestStatus: false },
      });
    },
  },

  [Events.CREATE_CREDIT_REQUEST]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { createCreditRequest: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        pendings: { createCreditRequest: false },
        creditRequest: { id: payload.id },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { createCreditRequest: false },
      });
    },
  },

  [Events.SET_USER_PHONE](state, { payload }) {
    return R.mergeDeepRight(state, {
      auth: { phone: payload },
    });
  },

  [Events.RESET_AUTH](state) {
    return R.mergeRight(state, {
      auth: defaultStates.auth,
    });
  },

  [Events.OPEN_MODAL](state, action) {
    return R.mergeDeepRight(state, {
      modals: {
        [action.payload.id]: {
          open: true,
          data: action.payload.data,
        },
      },
    });
  },

  [Events.CLOSE_MODAL](state, action) {
    return R.mergeDeepRight(state, {
      modals: {
        [action.payload.id]: {
          open: false,
          data: null,
        },
      },
    });
  },

  [Events.FETCH_CREDIT_REQUEST]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchCreditRequest: true },
      });
    },
    fulfilled(state, { payload }) {
      const onApp = state.creditRequest.onApplication;
      return R.mergeDeepRight(state, {
        creditRequest: {
          ...payload,
          onApplication: onApp,
          step:
            payload.step == null && onApp
              ? state.creditRequest.step
              : payload.step,
        },
        pendings: { fetchCreditRequest: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchCreditRequest: false },
      });
    },
  },

  [Events.FETCH_ACTIVE_LOAN_REQUEST]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchActiveLoanRequest: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        loanRequestList: payload,
        pendings: { fetchActiveLoanRequest: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchActiveLoanRequest: false },
      });
    },
  },

  [Events.FETCH_ARCHIVE_LOAN_REQUEST]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchArchiveLoanRequest: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        loanRequestList: payload,
        pendings: { fetchArchiveLoanRequest: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchArchiveLoanRequest: false },
      });
    },
  },

  [Events.FETCH_LOAN_REQUEST_SUGGESTIONS]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchLoanRequestSuggestions: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        creditRequest: { suggestions: payload },
        pendings: { fetchLoanRequestSuggestions: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchLoanRequestSuggestions: false },
      });
    },
  },

  [Events.FETCH_ACTIVE_LOAN_REQUEST_SUGGESTIONS]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchActiveLoanRequestSuggestion: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        loanRequestList: { activeLoanRequest: { suggestions: payload } },
        pendings: { fetchActiveLoanRequestSuggestions: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchActiveLoanRequestSuggestions: false },
      });
    },
  },

  [Events.FETCH_ALTERNATIVE_OFFERS]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchAlternativeOffers: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        creditRequest: { alternativeOffers: payload },
        pendings: { fetchAlternativeOffers: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchAlternativeOffers: false },
      });
    },
  },

  [Events.RESET_CREDIT_REQUEST](state) {
    return R.mergeRight(state, {
      creditRequest: defaultStates.creditRequest,
    });
  },

  [Events.FETCH_PROFILE]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchProfile: true },
      });
    },
    fulfilled(state, { payload }) {
      return R.mergeDeepRight(state, {
        profile: payload,
        pendings: { fetchProfile: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { fetchProfile: false },
      });
    },
  },

  [Events.SEND_APPLICATION]: {
    pending(state, { payload }) {
      return R.mergeDeepRight(state, {
        pendings: { sendApplication: true },
        profile: payload,
      });
    },
    fulfilled(state) {
      return R.mergeDeepRight(state, {
        pendings: { sendApplication: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { sendApplication: false },
      });
    },
  },
  // [Events.SEND_AB_BASIC_FORM]: {
  //   pending(state) {
  //     return R.mergeDeepRight(state, {
  //       pendings: { sendAbBasicForm: true },
  //     });
  //   },
  //   fulfilled(state, { payload }) {
  //     return R.mergeDeepRight(state, {
  //       pendings: { sendAbBasicForm: false },
  //       abBasicFormSended: payload,
  //     });
  //   },
  //   rejected(state) {
  //     return R.mergeDeepRight(state, {
  //       pendings: { sendAbBasicForm: false },
  //     });
  //   },
  // },

  [Events.SEND_AB_BASIC_FORM](state, { payload }) {
    return R.mergeDeepRight(state, {
      abBasicFormSended: payload,
    });
  },
  [Events.AB_SET_REJECTED](state, { payload }) {
    return R.mergeDeepRight(state, {
      abRejected: payload,
    });
  },
  [Events.SEND_UNAUTH_APPLICATION]: {
    pending(state) {
      return R.mergeDeepRight(state, {
        pendings: { sendUnauthApplication: true },
      });
    },
    fulfilled(state) {
      return R.mergeDeepRight(state, {
        pendings: { sendUnauthApplication: false },
      });
    },
    rejected(state) {
      return R.mergeDeepRight(state, {
        pendings: { sendUnauthApplication: false },
      });
    },
  },

  // [Events.LOGOUT] (state) {
  //   return R.mergeDeepRight(state, {
  //     authorized: initialState.authorized
  //   })
  // },
  [Events.LOGOUT]: {
    fulfilled() {
      return R.merge(initialState, {
        didCheckAuthStatus: true,
      });
    },
  },

  [Events.APPLICATION_UNMOUNT](state) {
    return R.mergeDeepRight(state, {
      profile: initialState.profile,
    });
  },

  [Events.CREDIT_REQUEST_UNMOUNT](state) {
    return R.mergeDeepRight(state, {
      profile: initialState.profile,
      creditRequest: initialState.creditRequest,
    });
  },

  // [Events.LOAN_REQUEST_LIST_UNMOUNT](state) {
  //   return R.mergeDeepRight(state, {
  //     loanRequestList: initialState.loanRequestList,
  //   });
  // },

  [Events.GO_TO_FIRST_STEP](state) {
    return R.mergeDeepRight(state, {
      creditRequest: {
        step: 1,
        onApplication: true,
      },
    });
  },

  [Events.GO_TO_APPLICATION](state) {
    return R.mergeDeepRight(state, {
      creditRequest: { onApplication: true },
    });
  },

  [Events.GO_TO_LIST](state) {
    return R.mergeDeepRight(state, {
      creditRequest: { onApplication: false },
    });
  },

  // [Events.CHECK_AUTH_COOKIE](state, { payload }) {
  //   return R.mergeRight(state, {
  //     authorized: payload,
  //   });
  // },

  [Events.CHECK_AUTH_STATUS]: {
    fulfilled(state, { payload }) {
      return R.merge(state, {
        authorized: payload.isAuthorized,
        didCheckAuthStatus: true,
      });
    },
  },
});

export function configureStore(initial) {
  const middlewares = [thunkMiddleware, promiseMiddleware()];

  if (process.env.REDUX_LOG) {
    middlewares.push(createLogger({ collapsed: true }));
  }

  return createStore(reducer, initial, applyMiddleware(...middlewares));
}

import React from 'react';
import P from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import { Col, Container, Icon, Row, Text, Title } from '../../base';
import { formatDayCount, formatMoney } from '../../helpers/format_helpers';
import { calcTotalLoanAmount } from '../../domain';
import { LoanProps } from '../shared/loan_props';

/* eslint-disable import/prefer-default-export */
export function LoanConditions(props) {
  const visibleParams = props.visibleParams || [];
  const { loan } = props;

  return (
    <Container>
      <Row className="u-mb-2">
        <Col>
          <Title size={5} center style={{ letterSpacing: '1px' }}>
            Условия займа
          </Title>
        </Col>
      </Row>

      {R.isNil(loan.approvedDate) ||
      R.none((x) => x === 'decisionDate', visibleParams) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Срок одобрения</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{moment(loan.approvedDate).format('DD.MM.YYYY')}</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.approvedDate) ||
      R.none((x) => x === 'moneyIssuedDate', visibleParams) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Займ выдан</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{moment(loan.approvedDate).format('DD.MM.YYYY')}</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.amount) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Сумма займа</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{formatMoney(loan.amount)}</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.term) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Срок займа</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>
              {loan.term}&nbsp;{formatDayCount(loan.term)}
            </Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.mfiAlias) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Кредитор</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Icon name={`${loan.mfiAlias}-logo-icon`} pos="right" />
          </Col>
        </Row>
      )}

      {R.isNil(loan.rate) || R.isNil(loan.overpayment) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Ставка в день</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{loan.rate}%</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.overpayment) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Переплата</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{formatMoney(loan.overpayment)}</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.overpayment) || R.isNil(loan.amount) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">К возврату</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>
              {formatMoney(calcTotalLoanAmount(loan.amount, loan.overpayment))}
            </Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.expirationDate) ||
      R.none((x) => x === 'deadline', visibleParams) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Вернуть до</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{moment(loan.expirationDate).format('DD.MM.YYYY')}</Text>
          </Col>
        </Row>
      )}

      {R.isNil(loan.closeDate) ||
      R.none((x) => x === 'closeDate', visibleParams) ? null : (
        <Row hBetween vCenter className="u-mb-2">
          <Col size="auto" className="u-p-0">
            <Text color="secondary">Займ закрыт</Text>
          </Col>
          <Col size="auto" className="u-p-0">
            <Text>{moment(loan.closeDate).format('DD.MM.YYYY')}</Text>
          </Col>
        </Row>
      )}
    </Container>
  );
}

LoanConditions.propTypes = {
  loan: LoanProps.isRequired,
  visibleParams: P.arrayOf(
    P.oneOf(['decisionDate', 'moneyIssuedDate', 'closeDate', 'deadline']),
  ),
};

LoanConditions.defaultProps = {
  visibleParams: null,
};

import React, { useEffect } from 'react';
import P from 'prop-types';
// import moment from 'moment'
import {
  Button,
  Col,
  Container,
  Icon,
  IconButton,
  Panel,
  Row,
  Text,
  Title,
} from '../../base';
import {
  formatDayCount,
  formatMoney,
  formatISODateToTextField,
} from '../../helpers/format_helpers';
import { LoanConditions } from './shared';
import { LoanProps } from '../shared/loan_props';
import { changePage } from '../../metrics';

export function ExpiredLoan(props) {
  const { loan } = props;

  useEffect(() => {
    changePage(`${loan.mfiAlias}ErlierApproved`);
  }, []);

  return (
    <Container>
      <Row className="u-mt-2 u-mt-4-sm u-mb-2">
        <Col offset={2} center className="u-text-align-center">
          <Icon name="smile-neutral-icon" />
        </Col>
        <Col size={2} start>
          <IconButton
            name="close"
            onClick={props.onClose}
            className="u-align-right"
            dataAttrs={{
              state:
                'ev:click;ctx:Детали expired займа, кнопка "Закрыть форму"',
            }}
          />
        </Col>
      </Row>

      <Row className="u-mb-2">
        <Col>
          <Title size={3} sm={2} center>
            Срок одобрения истек
          </Title>
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col md={6} offsetMd={3}>
          <Panel color="neutral">
            <Row className="u-mb-1">
              <Col>
                <Title size={1} color="inverse" center>
                  {formatMoney(loan.amount)}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <Title size={3} color="inverse" center>
                  на {loan.term}&nbsp;{formatDayCount(loan.term)}
                </Title>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>

      <Row className="u-mb-4">
        <Col md={6} offsetMd={3}>
          <Text center>
            {formatISODateToTextField(loan.approvedDate)} вам был одобрен займ{' '}
            {formatMoney(loan.amount)} на {loan.term}{' '}
            {formatDayCount(loan.term)}. К сожалению, срок одобрения истек.
            Воспользуйтесь другим займом.
          </Text>
        </Col>
      </Row>

      <Row className="">
        <Col md={6} offsetMd={3}>
          <LoanConditions loan={loan} visibleParams={['decisionDate']} />
        </Col>
      </Row>

      {props.canCreateLoan ? (
        <Row className="u-mb-8">
          <Col offsetMd={4} md={4}>
            <Button
              onClick={props.onCreateLoan}
              maxWidth
              isPending={props.isCreateLoanPending}
              dataAttrs={{
                stat:
                  'ev:click;ctx:Детали expired займа, кнопка "Оформить новый займ"',
              }}
            >
              Оформить новый займ
            </Button>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}

ExpiredLoan.propTypes = {
  loan: LoanProps.isRequired,
  onClose: P.func.isRequired,
  // onCreateLoan: P.func.isRequired,
  // canCreateLoan: P.bool,
  // isCreateLoanPending: P.bool
};

ExpiredLoan.defaultProps = {
  canCreateLoan: false,
  isCreateLoanPending: false,
};

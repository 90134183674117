import moment from 'moment';
import * as R from 'ramda';

export function calcOverpayment(amount, term, rate) {
  return amount * term * (rate / 100);
}

export function getLoanAmountOrDefault(minAmount, maxAmount, amount) {
  return R.isNil(amount) ? Math.round(R.mean([minAmount, maxAmount])) : amount;
}

export function getLoanTermOrDefault(minTerm, maxTerm, term) {
  return R.isNil(term) ? Math.round(R.mean([minTerm, maxTerm])) : term;
}

export function calcTotalLoanAmount(base, overpayment) {
  return base + overpayment;
}

export function calcRemainingDaysUntilOverdue(deadline) {
  const overdue = moment(deadline).add(1, 'days');
  return moment(overdue).diff(moment(), 'days') + 1;
}

export function calcRemainingDaysUntilExpiration(expirationDate) {
  return moment(expirationDate).diff(moment(), 'days') + 1;
}

export const Creditor = {
  SravniRu: 'sravniRu',
};

export const MarriageStatuses = [
  { value: 0, caption: 'Не женат (не замужем)' },
  { value: 1, caption: 'В браке' },
  { value: 2, caption: 'В разводе' },
  { value: 3, caption: 'Гражданский брак' },
  { value: 4, caption: 'Повторный брак' },
  { value: 5, caption: 'Вдовец (вдова)' },
];

export const JobTypes = [
  { value: 0, caption: 'Работа по найму' },
  { value: 1, caption: 'Собственный бизнес' },
  { value: 2, caption: 'Государственная служба' },
  { value: 3, caption: 'Судья' },
  { value: 4, caption: 'Военнослужащий' },
  { value: 5, caption: 'Не работаю' },
];
export const UnemploymentReasons = [
  { value: 0, caption: 'Пенсионер по возрасту/стажу работы' },
  { value: 1, caption: 'Судья в отставке' },
  { value: 2, caption: 'Ищу работу' },
  { value: 3, caption: 'Содержит муж/жена' },
];
export const JobPositionTypes = [
  { value: 0, caption: 'Руководитель организации' },
  { value: 1, caption: 'Руководитель подразделения' },
  { value: 2, caption: 'Неруководящий сотрудник - специалист' },
  { value: 3, caption: 'Неруководящий сотрудник - обсл. персонал' },
];
export const JobActivityAreas = [
  { value: 0, caption: 'Горнодобывающая промышленность' },
  { value: 1, caption: 'Государственное, муниципальное управление' },
  {
    value: 2,
    caption: 'Здравоохранение, социальные услуги',
  },
  { value: 3, caption: 'Культура, искусство, спортивная деятельность' },
  { value: 4, caption: 'Оборона, правоохранительные органы' },
  { value: 5, caption: 'Обрабатывающая промышленность (производство)' },
  { value: 6, caption: 'Профессиональная, научная, техническая деятельность' },
  { value: 7, caption: 'Сельское хозяйство, рыболовство, охота, лесоводство' },
  { value: 8, caption: 'Строительство' },
  { value: 9, caption: 'Сфера торговли, услуг, связи' },
  { value: 10, caption: 'Транспорт' },
  { value: 11, caption: 'Финансовая деятельность, страхование' },
  { value: 12, caption: 'Электроэнергия, газо-, водо-, теплоснабжение' },
  { value: 13, caption: 'Иное (в крайнем случае)' },
];

export const JobIncomeProofTypes = [
  { value: 0, caption: 'Справка 2-НДФЛ' },
  { value: 1, caption: 'Справка 3-НДФЛ' },
  { value: 2, caption: 'Справка по форме банка' },
  { value: 3, caption: 'Без подтверждения дохода' },
];

export const JobExperience = [
  { value: 0, caption: 'До 6 месяцев' },
  { value: 1, caption: 'От 6 месяцев до года' },
  { value: 2, caption: 'От 1 года до 5 лет' },
  { value: 3, caption: 'Более 5 лет' },
];

export const JobEmployees = [
  { value: 0, caption: '1-10' },
  { value: 1, caption: '11-50' },
  { value: 2, caption: '51-100' },
  { value: 3, caption: '101-1000' },
  { value: 4, caption: 'более 1000' },
];

export const ChildrenAmounts = [
  { value: 0, caption: 'нет' },
  ...R.range(1, 11).map((value) => ({ value, caption: value.toString() })),
];

export const AgreementName = {
  CreditorRulesAcceptance: 'creditorRulesAcceptance',
  CreditInfoDisclosure: 'creditInfoDisclosure',
  ProcessingPersonalData: 'processingPersonalData',
  ServiceContract: 'serviceContract',
  IsNotStateOfficial: 'isNotStateOfficial',
  IsNotForeignCitizen: 'isNotForeignCitizen',
  BeneficiaryIsAbsent: 'beneficiaryIsAbsent',
  BeneficialOwnerIsAbsent: 'beneficialOwnerIsAbsent',
  InfoReceiving: 'infoReceiving',
  PersonalSignUse: 'personalSignUse',
  CommonConditions: 'commonConditions',
  LoanRequest: 'loanRequest',
  SendInfoTo3rdParty: 'sendInfoTo3rdParty',
  Collector: 'collector',
  ProcessingPersonalDataPolicy: 'processingPersonalDataPolicy',
};

export const AgreementVendor = {
  Finspin: 'finspin',
  SmsFinance: 'smsFinance',
  PayPS: 'payPS',
  DoZarplaty: 'dzp',
  SlonFinance: 'slonFinance',
  OneClickMoney: 'oneClickMoney',
};

export const AdditionalPhoneOwnerTypes = [
  { value: 0, caption: 'Мой номер' },
  { value: 1, caption: 'Номер родственника' },
  { value: 2, caption: 'Номер друга' },
];

export const Education = [
  { value: 0, caption: 'Отсутствует' },
  { value: 1, caption: 'Неполное среднее' },
  { value: 2, caption: 'Среднее' },
  { value: 3, caption: 'Средне-специальное' },
  { value: 4, caption: 'Незаконченное высшее' },
  { value: 5, caption: 'Высшее' },
  { value: 6, caption: '2 высших или учёная степень' },
];

import P from 'prop-types';

export const LoanProps = P.shape({
  id: P.oneOfType([P.string, P.number]),
  status: P.string,
  amount: P.number,
  term: P.number,
  rate: P.number,
  creditor: P.string,
  creditorUrl: P.string,
  decisionDate: P.string,
  expirationDate: P.string,
  moneyIssuedDate: P.string,
  closeDate: P.string,
  deadline: P.string,
  overpayment: P.number,
  isCounteroffer: P.bool,
});

import React from 'react';
import P from 'prop-types';
import cn from 'classnames';
import { Icon } from './icon';

export class ExpansionPanel extends React.Component {
  static propTypes = {
    caption: P.string.isRequired,
    className: P.string,
    captionCenter: P.bool,
    captionCenterSm: P.bool,
    captionCenterMd: P.bool,
    captionLeft: P.bool,
    captionLeftSm: P.bool,
    captionLeftMd: P.bool,
    captionRight: P.bool,
    captionRightSm: P.bool,
    captionRightMd: P.bool,
    defaultClosed: P.bool,
    children: P.node,
    onToggle: P.func,
  };

  static defaultProps = {
    className: '',
    captionCenter: false,
    captionCenterSm: false,
    captionCenterMd: false,
    captionLeft: false,
    captionLeftSm: false,
    captionLeftMd: false,
    captionRight: false,
    captionRightSm: false,
    captionRightMd: false,
    defaultClosed: false,
    children: null,
    onToggle: null,
  };

  state = {
    isOpen: !this.props.defaultClosed,
  };

  handleOpen = () => {
    const isOpen = !this.state.isOpen;

    this.setState({ isOpen }, () => {
      if (this.props.onToggle) {
        this.props.onToggle(isOpen);
      }
    });
  };

  render() {
    const className = cn('expansion-panel', this.props.className, {
      'is-open': this.state.isOpen,
    });
    const captionClassName = cn('expansion-panel--caption', {
      'u-text-align-center': this.props.captionCenter,
      'u-text-align-center-sm': this.props.captionCenterSm,
      'u-text-align-center-md': this.props.captionCenterMd,
      'u-text-align-left': this.props.captionLeft,
      'u-text-align-left-sm': this.props.captionLeftSm,
      'u-text-align-left-md': this.props.captionLeftMd,
      'u-text-align-right': this.props.captionRight,
      'u-text-align-right-sm': this.props.captionRightSm,
      'u-text-align-right-md': this.props.captionRightMd,
    });

    return (
      <div className={className}>
        <div className={captionClassName}>
          <div
            className="expansion-panel--handle"
            onClick={this.handleOpen}
            role="presentation"
          >
            <div
              className="expansion-panel--caption-text"
              style={{ letterSpacing: '1px' }}
            >
              {this.props.caption}
            </div>
            <div className="expansion-panel--caret">
              {this.state.isOpen ? (
                <Icon name="caret-up-icon" />
              ) : (
                <Icon name="caret-down-icon" />
              )}
            </div>
          </div>
        </div>

        {this.state.isOpen ? (
          <div className="expansion-panel--content">{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

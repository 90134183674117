/*
 * This code run only in a browser
 */
import React from 'react';
import * as R from 'ramda';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import querystring from 'querystring';
import App from './modules/app';
import { configureStore, Actions } from './modules/state';
import { setServerErrorHandler } from './api';
import { ModalType } from './modules/modals/modal_type';
import { getDataFromQuery } from './get_data_from_query';
import { getCookies, setCookies } from './helpers/storage_helpers';
import {
  parseParams,
  persistCookie,
  persistParams,
} from './modules/shared/filkos_pixel';
// import { StoreProvider, RouterProvider } from './context';

import './styles/index_default.scss';

// eslint-disable-next-line no-underscore-dangle
const store = configureStore(window.__PRELOADED_STATE__);

setServerErrorHandler((error) => {
  if (error.status >= 500) {
    store.dispatch(Actions.openModal(ModalType.ServerError, { error }));
  }
  if (error.status === 401) {
    window.location.replace(`${window.location.origin}/auth`);
  }
});

function handleUrlParams() {
  const queries = window.location.search
    ? querystring.parse(window.location.search.slice(1))
    : {};

  const filkoParams = parseParams(queries, getCookies);

  if (!R.isEmpty(filkoParams)) {
    persistParams(filkoParams, store.dispatch);
    persistCookie(filkoParams, setCookies);
  }

  if (!window.location.search) return;

  const data = getDataFromQuery(queries);

  setCookies(data.cookies);

  if (!R.isEmpty(data.leadProviderParams)) {
    store.dispatch(Actions.addLeadProviderParams(data.leadProviderParams));
  }

  if (data.phone) {
    store.dispatch(Actions.setUserPhone(data.phone));
  }

  if (!R.isEmpty(data.loanParams)) {
    store.dispatch(
      Actions.setWantedCreditConditions(
        data.loanParams.amount,
        // data.loanParams.term,
      ),
    );
  }
}

handleUrlParams();

ReactDOM.render(
  <StoreProvider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </StoreProvider>,
  document.getElementById('app'),
);

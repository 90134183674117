import * as R from 'ramda';
import moment from 'moment';

const wNumb = require('wnumb');

export function formatTimer(time) {
  const m = Math.floor(time / 60);
  const s = time - m * 60;

  return `${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
}

// Days caption

export function formatDayCount(count) {
  if (R.isNil(count)) {
    return '';
  }

  const valueStr = count.toString();
  const lastDigit = valueStr[valueStr.length - 1];

  const suffix1 = ['0', '5', '6', '7', '8', '9'];
  const suffix2 = ['1'];
  const suffix3 = ['2', '3', '4'];

  if (process.env.THEME === 'eng') {
    if (count >= 10 && count <= 20) {
      return 'days';
    }
    if (suffix1.indexOf(lastDigit) >= 0) {
      return 'days';
    }
    if (suffix2.indexOf(lastDigit) >= 0) {
      return 'day';
    }
    if (suffix3.indexOf(lastDigit) >= 0) {
      return 'days';
    }
  }

  if (count >= 10 && count <= 20) {
    return 'дней';
  }
  if (suffix1.indexOf(lastDigit) >= 0) {
    return 'дней';
  }
  if (suffix2.indexOf(lastDigit) >= 0) {
    return 'день';
  }
  if (suffix3.indexOf(lastDigit) >= 0) {
    return 'дня';
  }

  return '';
}

// Loans caprion

export function formatLoanCount(count) {
  if (R.isNil(count)) {
    return '';
  }
  // Можно воспользоваться
  const valueStr = count.toString();
  const lastDigit = valueStr[valueStr.length - 1];
  const suffix = ['1'];

  if (suffix.indexOf(lastDigit) >= 0) {
    return 'займом';
  }

  return 'займами';
}

export function formatSuggestionCount(count) {
  if (R.isNil(count)) {
    return '';
  }

  const valueStr = count.toString();
  const lastDigit = valueStr[valueStr.length - 1];

  const suffix1 = ['0', '5', '6', '7', '8', '9'];
  const suffix2 = ['1'];
  const suffix3 = ['2', '3', '4'];

  if (count >= 10 && count <= 20) {
    return 'займов';
  }
  if (suffix1.indexOf(lastDigit) >= 0) {
    return 'займов';
  }
  if (suffix2.indexOf(lastDigit) >= 0) {
    return 'займ';
  }
  if (suffix3.indexOf(lastDigit) >= 0) {
    return 'займа';
  }

  return '';
}

export function formatPickedUpWords(count) {
  if (R.isNil(count)) {
    return '';
  }

  const valueStr = count.toString();
  const lastDigit = valueStr[valueStr.length - 1];

  const suffix1 = ['0', '5', '6', '7', '8', '9'];
  const suffix2 = ['1'];
  const suffix3 = ['2', '3', '4'];

  if (count >= 10 && count <= 20) {
    return 'Уже подобрано';
  }
  if (suffix1.indexOf(lastDigit) >= 0) {
    return 'Уже подобрано';
  }
  if (suffix2.indexOf(lastDigit) >= 0) {
    return 'Уже подобран';
  }
  if (suffix3.indexOf(lastDigit) >= 0) {
    return 'Уже подобрано';
  }

  return '';
}

export function formatPickedUpSuggestion(count) {
  if (R.isNil(count)) {
    return '';
  }

  const valueStr = count.toString();
  const lastDigit = valueStr[valueStr.length - 1];

  const suffix1 = ['0', '5', '6', '7', '8', '9'];
  const suffix2 = ['1'];
  const suffix3 = ['2', '3', '4'];

  if (count >= 10 && count <= 20) {
    return `Уже подобрано ${count} ${formatSuggestionCount(count)}`;
  }
  if (suffix1.indexOf(lastDigit) >= 0) {
    return `Уже подобрано ${count} ${formatSuggestionCount(count)}`;
  }
  if (suffix2.indexOf(lastDigit) >= 0) {
    return `Уже подобран ${count} ${formatSuggestionCount(count)}`;
  }
  if (suffix3.indexOf(lastDigit) >= 0) {
    return `Уже подобрано ${count} ${formatSuggestionCount(count)}`;
  }

  return '';
}

// Phone

export function parsePhone(phone) {
  const filtered = phone.split('').filter((i) => /\d/.test(i));

  return R.tail(filtered).join('');
}

export function formatPhone(phone = '', options = { firstDigit: '+7' }) {
  if (phone.trim() === '') {
    return '';
  }
  const [d0, d1, d2, d3, d4, d5, d6, d7, d8, d9] = phone.split('');

  return `${options.firstDigit} (${d0}${d1}${d2}) ${d3}${d4}${d5}-${d6}${d7}-${d8}${d9}`;
}

// Money

const moneyFormat = wNumb({
  mark: '.',
  thousand: ' ',
  suffix: ' ₽',
  decimals: 0,
});

const moneyFormatWitoutCurrency = wNumb({
  mark: '.',
  thousand: ' ',
  suffix: '',
  decimals: 0,
});

export function roundNum(num, decimalPlaces) {
  if (Number.isInteger(num)) {
    return num;
  }
  return parseFloat(
    num.toFixed(typeof decimalPlaces !== 'undefined' ? decimalPlaces : 2),
  );
}

export function formatMoney(value, options = { withoutCurrency: false }) {
  if (options && options.withoutCurrency) {
    return moneyFormatWitoutCurrency.to(value);
  }
  return moneyFormat.to(value);
}
export function formatBigMoney(value, options = { withoutCurrency: false }) {
  const roundedValue = roundNum(value);
  if (roundedValue >= 1000000) {
    return `${roundedValue / 1000000} млн ₽`;
  }
  if (options && options.withoutCurrency) {
    return moneyFormatWitoutCurrency.to(value);
  }
  return moneyFormat.to(value);
}

// Gender

export function formatGender(gender) {
  if (gender === 'male') {
    return 'Мужчина';
  }
  if (gender === 'female') {
    return 'Женщина';
  }
  return '';
}

export function formatCoincidesWithTheFact(value) {
  return value ? 'Да' : 'Нет';
}

export function toCamelCase(object) {
  return Object.keys(object).reduce((acc, key) => {
    const parts = key.split('_');
    const camel =
      parts[0] +
      R.tail(parts)
        .map((el) => `${el[0].toUpperCase()}${R.tail(el)}`)
        .join('');

    acc[camel] = object[key];

    return acc;
  }, {});
}

// Info message about remaining days
export function formatRemainingTime(diff) {
  const n = diff.toString();
  if (n === '1') {
    return 'сегодня последний день';
  }
  if (R.last(n) === '1' && n !== '11') {
    return `остался ${n} ${formatDayCount(n)}`;
  }
  return `осталось ${n} ${formatDayCount(n)}`;
}

// Dates
function convertMonthNumberToString(month) {
  switch (month) {
    case '01':
      return 'января';
    case '02':
      return 'февраля';
    case '03':
      return 'марта';
    case '04':
      return 'апреля';
    case '05':
      return 'мая';
    case '06':
      return 'июня';
    case '07':
      return 'июля';
    case '08':
      return 'августа';
    case '09':
      return 'сентября';
    case '10':
      return 'октября';
    case '11':
      return 'ноября';
    case '12':
      return 'декабря';
    default:
      return null;
  }
}

export function formatISODateToTextField(date) {
  // return R.isNil(date) ? '' : moment(date).format('DD.MM.YYYY');
  return date;
}

export function formatISODateToTextFieldWithMonth(date) {
  return R.isNil(date)
    ? ''
    : `${moment(date).format('DD')} ${convertMonthNumberToString(
        moment(date).format('MM'),
      )} ${moment(date).format('YYYY')}`;
}

export function parseTextFieldDate(date) {
  console.log('parseTextFieldDate', date);
  // Naive (temporary) test of the correct date format,
  // due to the moment.isValid recognize some value as 10.10.20__ as the correct date
  // (may be I don't understand the moment api, who knows :[).
  if (/\d{2}.\d{2}.\d{4}/.test(date)) {
    return date;
  }
  return '';
}

// Confirmation code

export function parseConfirmationCode(code) {
  return code
    .split('')
    .filter((i) => /\d/.test(i))
    .join('');
}

import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import { IconButton } from './icon_button';
import { Title } from './typography';

export function Modal(props) {
  const contentClass = cn('modal--content', props.className, {
    'is-top': props.position === 'top',
  });

  return (
    <ReactModal
      isOpen={props.open}
      className={contentClass}
      overlayClassName="modal--overlay"
      contentLabel="modal"
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      onRequestClose={props.onClose}
      ariaHideApp={false}
    >
      {props.hasCloseButton && props.onClose ? (
        <div className="modal--close-button">
          <IconButton name="close" onClick={props.onClose} />
        </div>
      ) : null}
      {props.title ? (
        <Title center size={3} md={2} className="u-pb-2 u-pb-4-md">
          {props.title}
        </Title>
      ) : null}
      {props.children}
    </ReactModal>
  );
}

Modal.propTypes = {
  open: P.bool,
  children: P.node,
  position: P.oneOf(['center', 'top']),
  title: P.string,
  hasCloseButton: P.bool,
  onClose: P.func,
  shouldCloseOnOverlayClick: P.bool,
  className: P.string,
};

Modal.defaultProps = {
  open: false,
  children: null,
  position: 'center',
  title: '',
  hasCloseButton: false,
  onClose: null,
  shouldCloseOnOverlayClick: false,
  className: '',
};

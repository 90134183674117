import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Col, Container, Icon, IconButton, Row, Link, Title } from '../../base';
import { AgreementName, AgreementVendor } from '../../domain';
import { ModalType } from '../modals/modal_type';
import { changePage } from '../../metrics';
import { Actions } from '../state';

export function Agreements() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    changePage('agreements');
  }, []);

  function onShowAgreement(vendor, agreement) {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor,
        agreement,
        data: { removeOptional: true },
      }),
    );
  }

  return (
    <Container>
      <Row hBetween className="u-my-3 u-my-6-md">
        <Col center>
          <Icon name="finspin-logo-icon" />
        </Col>
        <Col center>
          <IconButton
            name="close"
            onClick={() => history.goBack()}
            className="u-align-right"
            dataAttrs={{
              stat:
                'ev:click;ctx:Авторизация, кнопка "Закрыть страницу авторизации"',
            }}
          />
        </Col>
      </Row>
      <Row className="u-mb-2">
        <Col className="u-text-align-left-md">
          <Title size={3}>Соглашения и документы</Title>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Link
            onClick={() =>
              onShowAgreement(
                AgreementVendor.Finspin,
                AgreementName.PersonalSignUse,
              )
            }
            dataAttrs={{ stat: '1' }}
          >
            Условия использования аналога собственноручной подписи
          </Link>
        </Col>
        <Col className="col-12 u-mt-2 u-mt-1-sm">
          <Link
            onClick={() =>
              onShowAgreement(
                AgreementVendor.Finspin,
                AgreementName.ProcessingPersonalData,
              )
            }
            dataAttrs={{ stat: '1' }}
          >
            Соглашение на обработку персональных данных
          </Link>
        </Col>

        <Col className="col-12 u-mt-2 u-mt-1-sm">
          <Link
            onClick={() =>
              onShowAgreement(
                AgreementVendor.Finspin,
                AgreementName.InfoReceiving,
              )
            }
            dataAttrs={{ stat: '1' }}
          >
            Соглашение на получение информации
          </Link>
        </Col>

        <Col className="col-12 u-mt-2 u-mt-1-sm">
          <Link
            onClick={() =>
              onShowAgreement(
                AgreementVendor.Finspin,
                AgreementName.CreditInfoDisclosure,
              )
            }
            dataAttrs={{ stat: '1' }}
          >
            Соглашение на получение доступа к кредитной истории
          </Link>
        </Col>

        <Col className="col-12 u-mt-2 u-mt-1-sm">
          <Link
            onClick={() =>
              onShowAgreement(
                AgreementVendor.Finspin,
                AgreementName.ProcessingPersonalDataPolicy,
              )
            }
            dataAttrs={{ stat: '1' }}
          >
            Политика по обработке персональных данных
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

import * as R from 'ramda';
import { createAgreements } from './helpers';
import { Agreements, VendorDisplayStrings } from './agreements';

const agreements = createAgreements(Agreements);

export function getAgreement(vendor, type) {
  return agreements[vendor][type];
}

export function getAllAgreements(vendor) {
  const ags = agreements[vendor];

  if (R.isNil(ags)) {
    return null;
  }

  return {
    vendorDisplayString: VendorDisplayStrings[vendor],
    agreements: R.keys(agreements[vendor]).reduce(
      (acc, key) => R.append({ name: key, agreement: ags[key] }, acc),
      [],
    ),
  };
}

import React from 'react';
import { Button, Card, Col, Container, Icon, Row, Title } from '../../base';
import { Creditor } from '../../domain';
import { formatMoney } from '../../helpers/format_helpers';
import { LoanParam } from './shared';

function getExpirationDuration(creditor) {
  switch (creditor) {
    case Creditor.SravniRu:
      return 'До 5 лет';
    default:
      return 'До 5 лет';
  }
}

const creditorCardMap = {
  [Creditor.SravniRu]: {
    expirationDuration: 'До 5 лет',
    creditLimit: 'до 240 000 ₽',
    annualRate: 'от 8%',
  },
};

export function ApprovedBigCard({ offer, onGetDetails }) {
  return (
    <Card borderType="none" simple className="u-mb-2">
      <Row className="u-px-4-md u-mt-2 u-mb-3">
        <Col md={4} className="u-mb-0-md u-mb-2 u-px-0-md">
          <LoanParam
            caption="Кредитор"
            value={
              <Icon
                name={`${offer.creditorAlias}-large-logo-icon`}
                pos="left"
              />
            }
          />
        </Col>
        <Col md={8}>
          <Container>
            <Row>
              <Col size={12} sm="auto" className="u-mr-4 u-mb-2 u-p-0">
                <LoanParam
                  caption="Кредитный лимит"
                  value={
                    // <Title size={4}>До {formatMoney(offer.creditLimit)}</Title>
                    <Title size={4}>
                      {creditorCardMap[offer.creditorAlias].creditLimit}
                    </Title>
                  }
                />
              </Col>
              <Col size="auto" className="u-mr-4 u-mb-2 u-p-0">
                <LoanParam
                  caption="Срок кредита"
                  value={
                    <Title size={4}>
                      {/* {getExpirationDuration(offer.creditorAlias)} */}
                      {creditorCardMap[offer.creditorAlias].expirationDuration}
                    </Title>
                  }
                />
              </Col>
              <Col size="auto" className="u-mb-2 u-p-0">
                <LoanParam
                  caption="Годовая ставка"
                  // value={<Title size={4}>От {offer.rate}%</Title>}
                  value={
                    <Title size={4}>
                      {creditorCardMap[offer.creditorAlias].annualRate}
                    </Title>
                  }
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="u-px-4-md">
        <Col md={6} lg={4} className="u-px-0-md">
          <Button
            onClick={() => onGetDetails(offer.creditorUrl)}
            maxWidth
            className="u-mb-2"
          >
            Выбрать предложение
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

// ApprovedCard.propTypes = {
//   onGetMoney: P.func.isRequired,
//   onGetSuggestionDetails: P.func.isRequired
// }

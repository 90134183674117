import * as R from 'ramda';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as Format from '../../../helpers/format_helpers';

import {
  Container,
  Row,
  Col,
  Title,
  Text,
  IconButton,
  Link,
} from '../../../base';
import { scrollUp } from '../../../helpers/dom_helpers';
import { Loading } from './loading';
import { LoadingDots } from '../../credit_request_list/loadingDots'; // ПЕРЕМЕСТИТЬ
import { ApplicationForm } from './application_form';
import { Actions } from '../../state';
import { ModalType } from '../../modals/modal_type';
import { steps } from './steps';
import { API } from '../../../api';
import { changePage } from '../../../metrics';

const debounce = require('lodash/debounce');

function ProgressBar({ value = 0, plus = 0 }) {
  return (
    <div className="progress-bar">
      {plus > 0 ? (
        <div
          className="progress-bar--plus"
          style={{ width: `${value + plus}%` }}
        />
      ) : null}
      {value > 0 ? (
        <div className="progress-bar--value" style={{ width: `${value}%` }} />
      ) : null}
    </div>
  );
}

const STEP = 2;

function StepTitle({ percent, plusPercent, stepName }) {
  const color = '#817AFF';

  return (
    <div>
      <Text
        bold
        center
        style={{ color }}
        sm="body-1"
        type="caption"
        className="u-mb-1"
      >
        Вероятность одобрения кредита
        {percent > 0 ? ` ${percent}%` : ''}
      </Text>
      <ProgressBar value={percent} plus={plusPercent} />
      {/* <Text sm="subtitle" center className="u-mt-2">
        Получите{' '}
        <Text bold inline sm="subtitle" style={{ color }}>
          +{plusPercent}%
        </Text>{' '}
        к{' '}
        <Text center inline bold sm="subtitle">
          вероятности одобрения займа
        </Text>
        , заполнив {stepName} шаг
      </Text> */}
    </div>
  );
}

const analyticPageMap = {
  1: 'formFirstStep',
  2: 'formSecondStep',
  3: 'formThirdStep',
};

export function Application() {
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  function componentDidUnmount() {
    dispatch(Actions.goToList());
    return dispatch(Actions.applicationUnmount());
  }
  useEffect(() => {
    scrollUp();
    dispatch(Actions.goToApplication());
    // if (!state.newLoanOptions) {
    //   dispatch(Actions.fetcCreditConditions());
    // }
    // if (!state.profile && state.creditRequest.status === 'needInfo') {
    //   dispatch(Actions.fetchProfile(state.creditRequest.id));
    // }
    return () => componentDidUnmount();
  }, []);

  useEffect(() => {
    if (STEP != null) {
      scrollUp();
      changePage(analyticPageMap[STEP]);
    }
  }, []);

  function onSubmitProfile(profile) {
    dispatch(Actions.sendAbRejected());
  }

  function onFieldChange(value, field) {}

  function onShowPassportChangeHelp() {
    dispatch(Actions.openModal(ModalType.PassportChangeHelp));
  }

  function isSubmitPending() {
    return (
      state.pendings.sendApplication ||
      (state.pendings.fetchCreditRequest && !state.creditRequest.onApplication)
    );
  }
  const moscowDate = moment().utcOffset(3);
  const agreementsData = {
    phone: Format.formatPhone(state.userPhone),
    date: moscowDate.format('DD.MM.YYYY'),
    time: moscowDate.format('HH:mm'),
  };
  function onShowAgreement(vendor, agreement) {
    dispatch(
      Actions.openModal(ModalType.Agreement, {
        vendor,
        agreement,
        data: agreementsData,
      }),
    );
  }

  function renderTitle() {
    const config = steps[STEP]([]);
    // Find fields with bonus in config and check the profile are filled these or not
    const percent = 50;

    return <StepTitle {...config.title} percent={percent} plusPercent={0} />;
  }

  function renderForm() {
    return (
      <ApplicationForm
        key={STEP} // Для перерисовки после смена шага
        stepNumber={STEP} // Для аналитики
        onFieldChange={onFieldChange}
        // Показываем loader на submit кнопке при отправке анкеты либо при загрузке заявки,
        // тк после отправки анкеты происходит загрузка заявки
        isSubmitPending={isSubmitPending()}
        // isSubmitPending={
        //   state.pendings.sendApplication || state.pendings.fetchCreditRequest
        // }
        onSubmit={onSubmitProfile}
        step={steps[STEP]([])}
        profile={{}}
        onShowPassportChangeHelp={onShowPassportChangeHelp}
        onShowAgreement={onShowAgreement}
      />
    );
  }

  // if (!state.profile || state.pendings.fetchProfile || !state.newLoanOptions) {
  //   return <Loading />;
  // }

  return (
    <>
      {/* {renderNotificationBar()} */}
      <Container>
        <Row className="u-mt-2 u-mt-4-sm u-mb-1 u-mb-2-sm">
          <Col offset={2} center>
            <Title size={3} sm={2} center>
              Заявка на кредит
            </Title>
          </Col>
          <Col size={2} start />
        </Row>
        {renderTitle()}
        {renderForm()}
      </Container>
    </>
  );
}

import { AgreementName, AgreementVendor } from '../domain';

export const Agreements = {
  [AgreementVendor.PayPS]: {
    [AgreementName.ServiceContract]: {
      path: 'PayPS/PayPSServiceContract',
      title: 'Пользовательское соглашение',
    },
  },

  [AgreementVendor.SmsFinance]: {
    [AgreementName.ServiceContract]: {
      path: 'SMSFinance/SMSFinanceServiceContract',
      title: 'Пользовательское соглашение',
    },
  },

  [AgreementVendor.DoZarplaty]: {
    [AgreementName.ProcessingPersonalData]: {
      path: 'DoZarplaty/DoZarplatyProcessDataAgreement',
      title: 'Согласие на обработку персональных данных',
    },
  },

  // [AgreementVendor.SlonFinance]: {
  //   [AgreementName.CreditorRulesAcceptance]: {
  //     path: 'SlonFinance/SlonFinanceAcceptRules',
  //     title: 'Правила предоставления потребительских займов'
  //   },
  //   [AgreementName.CommonConditions]: {
  //     path: 'SlonFinance/SlonFinanceCommonConditions',
  //     title: 'Общие условия договора потребительского микрозайма'
  //   }
  // },

  [AgreementVendor.OneClickMoney]: {
    [AgreementName.SendInfoTo3rdParty]: {
      path: 'OneClickMoney/OneClickMoneySendInfoTo3rdParty',
      title: 'Согласие на передачу персональных данных третьим лицам',
    },
    [AgreementName.Collector]: {
      path: 'OneClickMoney/OneClickMoneyCollector',
      title:
        'Согласие на осуществление взаимодействия с третьим лицом, направленного на возврат просроченной задолженности',
    },
  },

  [AgreementVendor.Finspin]: {
    [AgreementName.CreditInfoDisclosure]: {
      path: 'Prunto/PruntoCreditHistoryRequestAgreement',
      title: 'Согласие на получение кредитного отчета',
    },
    [AgreementName.ProcessingPersonalData]: {
      path: 'Prunto/PruntoProcessDataAgreement',
      title: 'Согласие на обработку персональных данных',
    },
    [AgreementName.PersonalSignUse]: {
      path: 'Prunto/PruntoUsePersonalSignAgreement',
      title:
        'Соглашение об использовании аналога собственноручной подписи (АСП)',
    },
    [AgreementName.InfoReceiving]: {
      path: 'Prunto/PruntoReceiveInfoAgreement',
      title: 'Согласие на получение информации',
    },
    [AgreementName.ProcessingPersonalDataPolicy]: {
      path: 'Prunto/PruntoProcessingPersonalDataPolicy',
      title: 'Политика ООО «ФИНСПИН» в отношении обработки персональных данных',
    },
    [AgreementName.FinspinFreeze]: {
      path: 'Prunto/PruntoFinspinFreeze',
      title: 'Спасибо за ваше обращение',
    },
  },
};

export const VendorDisplayStrings = {
  [AgreementVendor.PayPS]: 'ООО МФК «Займ Онлайн»',
  [AgreementVendor.SmsFinance]: 'ООО МФК «СМСФИНАНС»',
  [AgreementVendor.DoZarplaty]: 'ООО МФК «ДЗП-Центр»',
  [AgreementVendor.SlonFinance]: 'ООО МКК АН «БизнесИнвест»',
  [AgreementVendor.OneClickMoney]:
    'ООО МФК «Микрокредитная Компания Универсального Финансирования»',
};

import cn from 'classnames';
import React from 'react';
import P from 'prop-types';
import { IconButton } from './icon_button';

export function Alert(props) {
  const className = cn('alert', props.className, {
    [`is-${props.type}`]: props.type,
    'is-closable': props.closable,
    'is-fixed': props.fixed,
  });

  if (props.open) {
    return (
      <div className={className}>
        <div className="alert--body">
          <div className="alert--icon">
            <div className={`${props.type}-icon`} />
          </div>
          <div className="alert--content">{props.children}</div>
        </div>
        {props.closable && props.onClose ? (
          <div className="alert--close-btn">
            <IconButton name="close" size="sm" onClick={props.onClose} />
          </div>
        ) : null}
      </div>
    );
  }

  return null;
}

Alert.propTypes = {
  className: P.string,
  open: P.bool,
  children: P.node,
  type: P.oneOf(['success', 'warning', 'danger']),
  fixed: P.bool,
  closable: P.bool,
  onClose: P.func,
};

Alert.defaultProps = {
  className: '',
  open: false,
  children: '',
  type: 'success',
  fixed: false,
  closable: false,
  onClose: null,
};

import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import { getDataAttrs } from './utils';

export function Button(props) {
  const className = cn('btn', props.className, {
    [`is-${props.color}`]: true,
    [`is-${props.size}`]: true,
    'is-max-width': props.maxWidth,
    'is-pending': props.isPending,
  });

  const content = props.isPending ? 'Загрузка...' : props.children;

  return (
    <button
      className={className}
      disabled={props.disabled || props.isPending}
      onClick={props.onClick}
      type={props.type}
      {...getDataAttrs(props.dataAttrs)}
    >
      {content}
    </button>
  );
}

Button.propTypes = {
  className: P.string,
  /* eslint-disable react/forbid-prop-types */
  dataAttrs: P.object,
  /* eslint-enable */
  onClick: P.func,
  children: P.node,
  size: P.oneOf(['sm', 'md', 'lg']),
  color: P.oneOf(['primary', 'secondary']),
  disabled: P.bool,
  type: P.oneOf(['button', 'submit']),
  maxWidth: P.bool,
  isPending: P.bool,
};

Button.defaultProps = {
  className: null,
  dataAttrs: null,
  onClick: null,
  children: null,
  size: 'md',
  color: 'primary',
  disabled: false,
  type: 'button',
  maxWidth: false,
  isPending: false,
};

import * as R from 'ramda';

function flatAsyncHandler(type, handler) {
  return R.keys(handler).reduce((acc, t) => {
    if (!R.contains(t, ['pending', 'fulfilled', 'rejected'])) {
      throw new Error(`Unknown async handler for ${type}: ${t}`);
    }
    return R.assoc(`${type}_${t.toUpperCase()}`, handler[t], acc);
  }, {});
}

export function createReducer(initialState, handlers) {
  const prepared = R.keys(handlers).reduce((acc, type) => {
    if (type === undefined) {
      throw new Error('Action type is undefined in handlers:', handlers);
    }
    const handler = handlers[type];

    if (typeof handler === 'function') {
      return R.assoc(type, handlers[type], acc);
    }

    if (typeof handler === 'object') {
      return R.merge(flatAsyncHandler(type, handler), acc);
    }
    return acc;
  }, {});

  return (state = initialState, action) => {
    if (R.has(action.type, prepared)) {
      return prepared[action.type](state, action);
    }

    return state;
  };
}

/* eslint-disable react/no-multi-comp */
import * as R from 'ramda';
import cn from 'classnames';
import P from 'prop-types';
import React from 'react';
import { FormInput } from './form';
import { getDataAttrs } from './utils';
import { DataField } from './data_field';

class RadioButton extends React.PureComponent {
  static propTypes = {
    value: P.string.isRequired,
    caption: P.string.isRequired,
    disabled: P.bool,
    checked: P.bool,
    name: P.string,
    id: P.string,
    onChange: P.func,
    inline: P.bool,
    className: P.string,
    dataAttrs: P.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    disabled: false,
    checked: false,
    name: '',
    id: '',
    onChange: null,
    inline: false,
    className: '',
    dataAttrs: null,
  };

  handleChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };

  render() {
    const className = cn('radio-button', this.props.className, {
      'is-checked': this.props.checked,
      'is-disabled': this.props.disabled,
      'is-inline': this.props.inline,
    });

    return (
      <div className={className}>
        <input
          className="radio-button--input"
          onChange={this.handleChange}
          type="radio"
          checked={this.props.checked}
          name={this.props.name}
          id={this.props.id}
          {...getDataAttrs(this.props.dataAttrs)}
        />
        <div className="radio-button--label-container">
          <label className="radio-button--label" htmlFor={this.props.id}>
            <span className="radio-button--control">
              <span className="radio-button--check" />
            </span>
            <div className="radio-button--caption">{this.props.caption}</div>
          </label>
        </div>
      </div>
    );
  }
}

export class RawRadioButtonGroup extends React.PureComponent {
  static propTypes = {
    items: P.arrayOf(
      P.shape({
        value: P.string.isRequired,
        caption: P.string.isRequired,
        disabled: P.bool,
      }),
    ).isRequired,
    onChange: P.func,
    name: P.string,
    value: P.string,
    children: P.node,
    disabled: P.bool,
    inline: P.bool,
    className: P.string,
    label: P.string, // Show only in fixed mode
    fixed: P.bool,
  };

  static defaultProps = {
    onChange: null,
    name: '',
    value: '',
    children: null,
    disabled: false,
    inline: false,
    className: '',
    items: null,
    label: null,
    fixed: false,
  };

  static lastId = 0;

  constructor(props) {
    super(props);
    this.id = RawRadioButtonGroup.lastId;
    RawRadioButtonGroup.lastId += 1;
  }

  render() {
    const value = this.props.value || this.props.items[0].value;
    const className = cn('radio-button-group', this.props.className);

    if (this.props.fixed) {
      const activeButton = R.find((x) => x.value === value, this.props.items);

      return (
        <DataField label={this.props.label} value={activeButton.caption} />
      );
    }

    return (
      <div className={className}>
        {this.props.items.map((item) => (
          <RadioButton
            value={item.value}
            caption={item.caption}
            checked={item.value === value}
            disabled={
              R.isNil(item.disabled) ? this.props.disabled : item.disabled
            }
            id={`${this.id}rb:${this.props.name}:${item.value}`}
            key={item.value}
            name={this.props.name}
            onChange={this.props.onChange}
            inline={this.props.inline}
          />
        ))}
      </div>
    );
  }
}

export function RadioButtonGroup(props) {
  const { field, ...rest } = props;

  return (
    <FormInput field={field}>
      {({ setValue, getValue }) => (
        <RawRadioButtonGroup
          {...rest}
          name={field}
          value={getValue()}
          onChange={(val) => {
            setValue(val);
            if (props.onChange) {
              props.onChange(val, field);
            }
          }}
        />
      )}
    </FormInput>
  );
}

RadioButtonGroup.propTypes = R.merge(RawRadioButtonGroup.propTypes, {
  field: P.string.isRequired,
});

import * as R from 'ramda';

// We'll set this to a function that clears current timeout/interval.
// This is used in the exported `stop` method.
let stopTimer = () => {};

const defaultOptions = {
  maxIdleSeconds: 20 * 60,
  finalCountdownSeconds: 60,
  onFinalCountdownTick() {},
  onExpire() {},
};

// Used for the `reset` method which allows to start the timer again without specifying the options.
let lastSettings = defaultOptions;

function start(options) {
  lastSettings = R.merge(defaultOptions, options);
  const {
    maxIdleSeconds,
    finalCountdownSeconds,
    onFinalCountdownTick,
    onExpire,
  } = lastSettings;

  let lastActivityTime = new Date();

  function updateLastActivityTime() {
    lastActivityTime = new Date();
  }

  function mountActivityListeners() {
    document.body.addEventListener('click', updateLastActivityTime);
    document.body.addEventListener('keypress', updateLastActivityTime);
    window.addEventListener('wheel', updateLastActivityTime);
  }

  function unmountActivityListeners() {
    document.body.removeEventListener('click', updateLastActivityTime);
    document.body.removeEventListener('keypress', updateLastActivityTime);
    window.removeEventListener('wheel', updateLastActivityTime);
  }

  // Clear the previous timer if it exists.
  stopTimer();

  // Start ticking every second.
  const timerId = window.setInterval(() => {
    const idleSeconds = (new Date() - lastActivityTime) / 1000;

    // Expired.
    if (idleSeconds >= maxIdleSeconds) {
      onExpire();
      stopTimer();

      // Final countdown.
    } else if (idleSeconds > maxIdleSeconds - finalCountdownSeconds) {
      // Once the auto-logout modal is open, activity doesn't help.
      // User has to explicitly click "Cancel" to stop the timer.
      unmountActivityListeners();

      const secondsLeft = Math.ceil(maxIdleSeconds - idleSeconds);
      onFinalCountdownTick(secondsLeft);
    }
  }, 1000);

  stopTimer = () => {
    window.clearInterval(timerId);
    unmountActivityListeners();
  };

  mountActivityListeners();
}

function stop() {
  stopTimer();
}

function reset() {
  start(lastSettings);
}

export const IdleMonitor = { start, stop, reset };

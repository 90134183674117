import P from 'prop-types';
import React from 'react';
import { Link, Title, Text } from '../../../base/index';
import { AgreementName, Creditor } from '../../../domain';

export class Agreements extends React.Component {
  static propTypes = {
    onShowAgreement: P.func.isRequired,
    creditor: P.string.isRequired,
  };

  handleAgreementClick = (ev) => {
    if (this.props.onShowAgreement) {
      this.props.onShowAgreement(
        this.props.creditor,
        ev.target.getAttribute('data-value'),
      );
    }
  };

  renderLinks = () => {
    switch (this.props.creditor) {
      case Creditor.PayPS:
      case Creditor.SmsFinance:
      case Creditor.Kreditech:
        return (
          <Text type="body-2" className="u-mb-2">
            Нажимая на кнопку «Продолжить», вы соглашаетесь c{' '}
            <Link
              dataAttrs={{
                value: AgreementName.CreditorRulesAcceptance,
                stat: 'ev:click;ctx:Согласие "Пользов. соглашение"',
              }}
              onClick={this.handleAgreementClick}
            >
              Пользовательским соглашением
            </Link>
            ,{' '}
            <Link
              dataAttrs={{
                value: AgreementName.CreditInfoDisclosure,
                stat: 'ev:click;ctx:Согласие "Раскрытие кр. информации"',
              }}
              onClick={this.handleAgreementClick}
            >
              Соглашением на раскрытие кредитной информации
            </Link>
            ,{' '}
            <Link
              dataAttrs={{
                value: AgreementName.ProcessingPersonalData,
                stat: 'ev:click;ctx:Согласие "Обработка перс. данных"',
              }}
              onClick={this.handleAgreementClick}
            >
              Соглашением на обработку персональных данных
            </Link>{' '}
            и с{' '}
            <Link
              dataAttrs={{
                value: AgreementName.ServiceContract,
                stat:
                  'ev:click;ctx:Согласие "Правила предост. и обслуж. займов"',
              }}
              onClick={this.handleAgreementClick}
            >
              Правилами предоставления и обслуживания микрозаймов
            </Link>
            .
          </Text>
        );
      case Creditor.DoZarplaty:
        return (
          <Text type="body-2" className="u-mb-2">
            Нажимая на кнопку «Продолжить», вы соглашаетесь c{' '}
            <Link
              dataAttrs={{
                value: AgreementName.ProcessingPersonalData,
                stat: 'ev:click;ctx:Согласие "Пользов. соглашение"',
              }}
              onClick={this.handleAgreementClick}
            >
              Пользовательским соглашением
            </Link>
            .
          </Text>
        );
      // case Creditor.SlonFinance:
      //   return (
      //     <Text type='body-2' className='u-mb-2'>
      //       Нажимая на кнопку «Продолжить», вы соглашаетесь c{' '}
      //       <Link
      //         dataAttrs={{
      //           value: AgreementName.CreditorRulesAcceptance,
      //           stat: 'ev:click;ctx:Согласие "Правила предоставления микрозайма"'
      //         }}
      //         onClick={this.handleAgreementClick}
      //       >
      //         Правилами предоставления микрозайма
      //       </Link>,{' '}
      //       <Link
      //         dataAttrs={{
      //           value: AgreementName.CommonConditions,
      //           stat: 'ev:click;ctx:Согласие "Общие условия"'
      //         }}
      //         onClick={this.handleAgreementClick}
      //       >
      //         Общими условиями
      //       </Link>{' '} и с{' '}
      //       <Link
      //         dataAttrs={{
      //           value: AgreementName.LoanRequest,
      //           stat: 'ev:click;ctx:Согласие "Заявление о предоставлении микрозайма"'
      //         }}
      //         onClick={this.handleAgreementClick}
      //       >
      //         отправкой Заявления о предоставлении микрозайма
      //       </Link>.
      //     </Text>
      //   )
      default:
        throw new Error('Unknown creditor in Agreements!');
    }
  };

  render() {
    return (
      <div>
        {this.renderLinks()}
        <Text type="body-2">
          А также подтверждаете, что не занимаете государственной должности в
          РФ, что не являетесь иностранным должностным лицом, что
          выгодоприобретатель отсутствует, что бенефициарный владелец
          отсутствует.
        </Text>
      </div>
    );
  }
}

export function FullFieldsBlockWarning(props) {
  return (
    <div className="u-mt-4">
      <Title size={4} className="u-mb-1">
        Обратите внимание!
      </Title>
      <Text>
        Вы уже указывали личные данные. Проверьте, что все заполнено верно.
        <br />В случае, если ваши паспортные данные, указанные в анкете,
        поменялись{' - '}
        ознакомьтесь с{' '}
        <Link onClick={props.onShowPassportChangeHelp}>
          инструкцией по смене паспортных данных
        </Link>
        .
      </Text>
    </div>
  );
}

FullFieldsBlockWarning.propTypes = {
  onShowPassportChangeHelp: P.func,
};

import * as R from 'ramda';
import P from 'prop-types';
import cn from 'classnames';
import React from 'react';
import { getDataAttrs } from './utils';

function addTypographyClassNames(props) {
  return {
    'is-align-center': props.center,
    'is-align-center-sm': props.centerSm,
    'is-align-center-md': props.centerMd,
    'is-align-right': props.right,
    'is-align-right-sm': props.rightSm,
    'is-align-right-md': props.rightMd,
    'is-align-left': props.left,
    'is-align-left-sm': props.leftSm,
    'is-align-left-md': props.leftMd,
    'is-no-wrap': props.noWrap,
    'is-capitalize': props.capitalize,
    [`is-${props.color}`]: !R.isNil(props.color),
  };
}

const TypographyProps = {
  className: P.string,
  children: P.node,
  color: P.oneOf(['secondary', 'danger', 'inverse', 'secondary-inverse']),
  noWrap: P.bool,
  center: P.bool,
  centerSm: P.bool,
  centerMd: P.bool,
  right: P.bool,
  rightSm: P.bool,
  rightMd: P.bool,
  left: P.bool,
  leftSm: P.bool,
  leftMd: P.bool,
  inline: P.oneOfType([P.bool, P.oneOf(['block'])]),
  style: P.object,
};

const defaultTypographyProps = {
  capitalize: false,
  className: '',
  dataAttrs: null,
  color: null,
  noWrap: false,
  center: false,
  centerSm: false,
  centerMd: false,
  right: false,
  rightSm: false,
  rightMd: false,
  left: false,
  leftSm: false,
  leftMd: false,
  inline: false,
};

export function Title(props) {
  const className = cn(addTypographyClassNames(props), props.className, {
    [`title-${props.size}`]: true,
    [`title-${props.md}-md`]: !R.isNil(props.md),
    [`title-${props.sm}-sm`]: !R.isNil(props.sm),
    'is-regular': props.regular,
    'is-inline': props.inline === true,
    'is-inline-block': props.inline === 'block',
  });

  return (
    <div
      className={className}
      {...getDataAttrs(props.dataAttrs)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

const titleSize = R.range(1, 6);

Title.propTypes = R.merge(TypographyProps, {
  size: P.oneOf(titleSize).isRequired,
  sm: P.oneOf(titleSize),
  md: P.oneOf(titleSize),
  regular: P.bool,
  dataAttrs: P.object,
});

Title.defaultProps = R.merge(defaultTypographyProps, {
  sm: null,
  md: null,
  regular: false,
});

export function Text(props) {
  const className = cn(props.className, addTypographyClassNames(props), {
    [`${props.type}`]: true,
    [`${props.sm}-sm`]: !R.isNil(props.sm),
    [`${props.md}-md`]: !R.isNil(props.md),
    'is-bold': props.bold,
    'is-inline': props.inline === true,
    'is-inline-block': props.inline === 'block',
  });

  return (
    <div
      className={className}
      {...getDataAttrs(props.dataAttrs)}
      style={props.style}
    >
      {props.children}
    </div>
  );
}

const textTypeProp = P.oneOf(['body-1', 'body-2', 'subtitle', 'caption']);

Text.propTypes = R.merge(TypographyProps, {
  type: textTypeProp,
  sm: textTypeProp,
  md: textTypeProp,
  bold: P.bool,
});

Text.defaultProps = R.merge(defaultTypographyProps, {
  type: 'body-1',
  sm: null,
  md: null,
  bold: false,
});

import React from 'react';
import P from 'prop-types';
import {
  Col,
  Container,
  Icon,
  Row,
  Title,
  Text,
  IconButton,
} from '../../../base/index';

export const Loading = (props) => (
  <Container className="u-max-height">
    <Row className="u-mt-2 u-mt-4-sm u-mb-4 u-mb-8-sm">
      <Col offset={2} center>
        <Title size={3} sm={2} center>
          Заявка на кредит
        </Title>
      </Col>
      <Col size={2} start>
        {/* <IconButton
          name="close"
          onClick={props.onClose}
          className="u-align-right"
        /> */}
      </Col>
    </Row>

    <Row className="u-mb-4">
      <Col>
        <Text type="subtitle" center bold>
          <Icon name="spinner-primary-bg-sm" className="btn--pending-icon" />
          <span className="u-ml-1">Загрузка...</span>
        </Text>
      </Col>
    </Row>
  </Container>
);

Loading.propTypes = {
  onClose: P.func.isRequired,
};
